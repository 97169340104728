import ErrorHandler from './ErrorHandler';
import Panel from './extended/Panel';
import i18n from 'i18next';

interface GlobalFilterProps {
  componentName: string;
}
const BaseConfig = (props: GlobalFilterProps) => {
  try {
    return (
      <>
        <Panel header={i18n.t('Layout.EditProperties')}>{props.componentName}</Panel>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};
export default BaseConfig;
