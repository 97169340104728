import i18n from 'i18next';
import { TieredMenu } from 'primereact/tieredmenu';
import React, { Suspense, useRef } from 'react';
import { AppContext, appData, IState } from '../contexts/app-context';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import Authenticate from './Authenticate';
import minifyAddr from './minifyAddr';
import goToExternal from './goToExternal';
import RoundComponent from './basic/RoundComponent';
import { Chip } from 'primereact/chip';
import { Fieldset } from 'primereact/fieldset';
import { AuthContext, IAuthState } from '../contexts/AuthContext';

function Header() {
  const menu = useRef(null);
  let navigate = useNavigate();
  function setTheme(item) {
    localStorage.setItem('CHOSEN_THEME', item);
    console.log('localStorage', localStorage);
    window.location.reload();
  }
  function setLanguage(appData, language) {
    appData.language = language;
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
    if (appData.setAppData) {
      appData.setAppData({ ...appData, language: language });
    }
  }
  function routeTo(path) {
    navigate(path);
  }
  function getItems(appData: IState) {
    return [
      {
        label: i18n.t('Nav.VoteCoin'),
        items: [
          {
            label: i18n.t('Nav.Home'),
            command: () => {
              routeTo('/');
            },
          },
          {
            label: i18n.t('Nav.About'),
            command: () => {
              routeTo('/about');
            },
          },
        ],
      },
      {
        label: 'Style',
        icon: 'pi pi-fw pi-pencil',
        items: [
          {
            label: 'Rhea',
            command: () => {
              setTheme('rhea');
            },
          },
          {
            label: 'MDC dark indigo',
            command: () => {
              setTheme('mdc-dark-indigo');
            },
          },
          {
            label: 'MDC light indigo',
            command: () => {
              setTheme('mdc-light-indigo');
            },
          },
          {
            label: 'Lara light indigo',
            command: () => {
              setTheme('lara-light-indigo');
            },
          },
        ],
      },
      {
        label: 'Layout editor',
        icon: 'pi pi-fw pi-pencil',
        items: [
          {
            label: 'Toggle Drag & Drop',
            command: () => {
              appData.setAppData({ ...appData, editingLayout: !appData.editingLayout });
            },
          },
          {
            label: 'Toggle component properties',
            command: () => {
              appData.setAppData({ ...appData, editingComponents: !appData.editingComponents });
            },
          },
          {
            label: 'Toggle expert mode',
            command: () => {
              appData.setAppData({ ...appData, editingExpertMode: !appData.editingExpertMode });
            },
          },
          {
            label: 'Reset layout',
            command: () => {
              appData.setAppData({ ...appData, resetLayout: true });
            },
          },
          {
            label: 'Finish edit mode',
            command: () => {
              appData.setAppData({ ...appData, editingLayout: false, editingComponents: false });
            },
          },
          {
            separator: true,
          },
          {
            label: 'Add new component',
            items: [
              {
                label: 'Global Filter',
                command: () => {
                  appData.setAppData({ ...appData, layoutAddNew: 'GlobalFilter' });
                },
              },
              {
                label: 'Question List',
                command: () => {
                  appData.setAppData({ ...appData, layoutAddNew: 'QuestionList' });
                },
              },
              {
                label: 'Token List',
                command: () => {
                  appData.setAppData({ ...appData, layoutAddNew: 'TokenList' });
                },
              },
              {
                label: 'Place order',
                command: () => {
                  appData.setAppData({ ...appData, layoutAddNew: 'PlaceOrder' });
                },
              },
              {
                label: 'Trades',
                command: () => {
                  appData.setAppData({ ...appData, layoutAddNew: 'Trades' });
                },
              },
            ],
          },
        ],
      },
      {
        label: 'Language',
        items: [
          {
            label: 'English',
            command: () => {
              setLanguage(appData, 'en');
            },
          },
          {
            label: 'Slovak',
            command: () => {
              setLanguage(appData, 'sk');
            },
          },
        ],
      },
    ];
  }
  const logout = (authContext: IAuthState) => {
    authContext.setAuthContext({ ...authContext, authAddress: null, authToken: null, authTx: null });
  };
  const switchEnv = (appData: IState) => {
    const other = appData.env === 'mainnet-v1.0' ? 'testnet-v1.0' : 'mainnet-v1.0';
    appData.setAppData({ ...appData, env: other });
    navigate('/');
  };
  const getEnvSeverity = (appData: IState): string => {
    return appData.env === 'mainnet-v1.0'
      ? 'bg-teal-200 border-round p-1 clickable align-content-center align-items-center'
      : 'bg-yellow-400 border-round p-1 clickable align-content-center align-items-center';
  };
  return (
    <Suspense fallback="loading">
      <AppContext.Consumer>
        {appData => (
          <AuthContext.Consumer>
            {authContext => (
              <>
                <div className="flex flex-column  flex-grow-1">
                  {appData.isDark ? (
                    <a className="flex flex-column mb-2 p-2 p-button-info" href="/">
                      <img src="/logom5Dark.png" className="p-2" />
                    </a>
                  ) : (
                    <a className="flex flex-column mb-2 p-2 p-button-info" href="/">
                      <img src="/logom5.png" className="p-2" />
                    </a>
                  )}

                  <div className="m-3"></div>

                  {authContext.authAddress ? (
                    <>
                      <Button className="mb-2 p-2 p-button-info" label="My communities" icon="pi pi-users" />
                      <Button className="mb-2 p-2 p-button-info" label="Explore" icon="pi pi-search" onClick={() => routeTo('/')} />
                      <Button className="mb-2 p-2 p-button-info" label="Governors" icon="pi pi-star" onClick={() => routeTo('/governors')} />
                      <Button className="mb-2 p-2 p-button-info" label="Settings" icon="pi pi-cog" onClick={() => routeTo('/settings')} />
                      <Button className="mb-2 p-2 p-button-info" label="Logout" icon="pi pi-lock" onClick={() => logout(authContext)} />
                      <div className="flex justify-content-center">
                        <i className="pi pi-user"></i> &nbsp; {minifyAddr(authContext.authAddress)}
                      </div>
                    </>
                  ) : (
                    <>
                      <Button className="mb-2 p-2 p-button-info" label="Explore" icon="pi pi-users" onClick={() => routeTo('/')} />
                      <Button className="mb-2 p-2 p-button-info" label="Governors" icon="pi pi-star" onClick={() => routeTo('/governors')} />
                      <Authenticate realm="Vote Coin App" />
                    </>
                  )}
                  <Button className="flex flex-row m-2 p-0 p-button-link" label="Advanced" onClick={event => menu.current.toggle(event)} />

                  <div className="flex flex-grow-1"></div>
                  {!authContext.authAddress && (
                    <Fieldset legend="Our values" className="p-1 m-0">
                      <Chip label="Auditable results" className="text-xs my-1" />
                      <Chip label="Cost effeciency" className="text-xs my-1" />
                      <Chip label="Knowledge based wisdom" className="text-xs my-1" />
                    </Fieldset>
                  )}
                  <p className={getEnvSeverity(appData)} onClick={() => switchEnv(appData)}>
                    Env: {appData.env}
                    <RoundComponent env={appData.env}></RoundComponent>
                  </p>
                  <Button className="mb-2 p-2 p-button-primary p-button-info" label="List new onchain community" icon="pi pi-plus-circle" onClick={() => routeTo('/new-dao')} />
                  <Button className="mb-2 p-2 p-button-primary p-button-info" label="Become governor" icon="pi pi-user-plus" />
                  <Button
                    className="flex flex-row m-2 p-0 p-button-link"
                    label="Open source apps"
                    onClick={() => goToExternal('https://www.vote-coin.com/solutions-and-tutorials/open-source-solutions')}
                  />

                  <Button className="flex flex-row m-2 p-0 p-button-link" label="Documentation" onClick={() => goToExternal('https://www.vote-coin.com/')} />
                  <Button className="flex flex-row m-2 p-0 p-button-link" label="FAQ" onClick={() => routeTo('/faq')} />

                  <div className="flex m-2  justify-content-center">
                    <a target="_blank" className="m-2 p-0 p-button-link" href="https://discord.gg/mPW36G6xqn">
                      <i className="m-2 pi pi-discord"></i>
                    </a>
                    <a target="_blank" className="m-2 p-0 p-button-link" href="https://twitter.com/TheVoteCoin">
                      <i className="m-2 pi pi-twitter"></i>
                    </a>
                    <a target="_blank" className="m-2 p-0 p-button-link" href="https://www.facebook.com/TheVoteCoin">
                      <i className="m-2 pi pi-facebook"></i>
                    </a>
                  </div>
                  <TieredMenu className="m-2" model={getItems(appData)} popup ref={menu} />
                </div>
              </>
            )}
          </AuthContext.Consumer>
        )}
      </AppContext.Consumer>
    </Suspense>
  );
}
export default Header;
