import E404 from '../components/basic/E404';
import GlobalFilter from '../components/configurable/GlobalFilter';
import QuestionList from '../components/configurable/QuestionList';
import TokenList from '../components/configurable/TokenList';
import Panel from '../components/extended/Panel';
import Header from '../components/Header';

export default function Page404() {
  return (
    <>
      <div className="flex flex flex-row">
        <div className=" flex-column flex min-h-screen p-3" style={{ width: '200px' }}>
          <Header />
        </div>
        <div className="flex flex-column flex-grow-1">
          <GlobalFilter></GlobalFilter>
          <div className="flex  flex-grow-1 flex-column card-container m-0 mb-2 mr-2 p-0">
            <Panel header="404 - Not found">
              <E404></E404>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
}
