import i18n from 'i18next';
import { AppContext, IState } from '../../contexts/app-context';
import BaseConfig from '../BaseConfig';
import Panel from '../extended/Panel';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { Token } from '../../generated/graphql';
import TokenComponent from '../basic/TokenComponent';
import { useContext } from 'react';
import { ScrollPanel } from '../extended/ScrollPanel';

export default function FullTextTokenList() {
  const appData = useContext(AppContext);

  const query = gql`
    subscription token($env: String!, $search: String!) {
      token(where: { env: { _eq: $env }, name: { _ilike: $search } }, order_by: { created_at_round: desc }, limit: 10) {
        id
        name
        asa
      }
    }
  `;
  const { data, loading, error } = useSubscription(query, {
    variables: {
      env: appData.env,
      search: `%${appData.globalFilter}%`,
    },
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return <div>Error... {error}</div>;
  }
  try {
    const tokens = (data.token as Array<any>).map(q => <TokenComponent key={q.id} token={q}></TokenComponent>); /**/
    return (
      <div className="flex flex-column  flex-grow-1">
        <Panel header="Filtered list of organizations" className="flex flex-column m-2 ml-0 mt-0 p-0 flex-grow-1">
          <div className="m-2">Filter: {appData.globalFilter}</div>
          {tokens.length > 0 ? (
            <ScrollPanel style={{ width: '100%', height: '100%' }} className="flex flex-grow-1 h-10rem ml-2 pr-2">
              {tokens}
            </ScrollPanel>
          ) : (
            <>No reulsts found</>
          )}
        </Panel>
      </div>
    );
  } catch (e) {
    return <div>Error... {e}</div>;
  }
}
