import i18n from 'i18next';
import { AppContext, IState } from '../../contexts/app-context';
import BaseConfig from '../BaseConfig';
import Panel from '../extended/Panel';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { Question } from '../../generated/graphql';
import TokenComponent from '../basic/TokenComponent';
import { useContext } from 'react';
import { ScrollPanel } from '../extended/ScrollPanel';
interface BaseTokenListParams {
  manager?: string;
}

export default function BaseTokenList(params: BaseTokenListParams) {
  const { manager } = params;
  const appData = useContext(AppContext);

  const query = gql`
    subscription token($env: String!) {
      token(where: { env: { _eq: $env } }, order_by: { created_at_round: desc }, limit: 10) {
        id
        name
        asa
      }
    }
  `;
  const { data, loading, error } = useSubscription(query, {
    variables: {
      env: appData.env,
    },
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return (
      <div>
        Error...
        {error.message ? error.message : JSON.stringify(error)}
      </div>
    );
  }
  try {
    const tokens = (data.token as Array<any>).map(q => <TokenComponent key={q.id} token={q}></TokenComponent>); /**/
    return (
      <>
        <Panel header="Organizations" className="flex flex-column m-2 ml-0 mt-0 p-0 flex-grow-1">
          {tokens.length > 0 ? (
            <ScrollPanel style={{ width: '100%', height: '100%' }} className="flex flex-grow-1 h-10rem ml-2 pr-2">
              <div className="card-container flex flex-grow-0 flex-grow-0 gap-3 justify-content-between ">{tokens}</div>
            </ScrollPanel>
          ) : (
            <>No reulsts found</>
          )}
        </Panel>
      </>
    );
  } catch (e) {
    return (
      <div>
        Error...
        {e.message ? e.message : JSON.stringify(e)}
      </div>
    );
  }
}
