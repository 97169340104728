import { Question, Question_Min_Fields } from '../../generated/graphql';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import minifyAddr from '../minifyAddr';
import goToExternal from '../goToExternal';
import { useContext, useRef, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import TokenBaseInfo from './TokenBaseInfo';
import { useNavigate } from 'react-router-dom';
import getQuestionById from '../../scripts/graphql/getQuestionById';
import { ProgressSpinner } from 'primereact/progressspinner';
import Panel from '../extended/Panel';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import algosdk from 'algosdk';

interface QuestionProps {
  tokenId: number;
}
interface IFormData {
  title: string;
  text: string;
  category: string;
  url: string;
  rounds: number;
  encrypted: boolean;
  encryptionKey: string;
  mnemonics: string;
  options: IOptions;
}

interface IOptions {
  [key: string]: string;
}

export default function AskQuestion(props: QuestionProps) {
  const navigate = useNavigate();
  const { tokenId } = props;
  const appData = useContext(AppContext);
  const toast = useRef(null);

  const [question, setQuestion] = useState<Question_Min_Fields>(null);
  const [formData, setFormData] = useState<IFormData>();
  const [options, setOptions] = useState<IOptions>({ '1': '', '2': '' });
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const initialValues: IFormData = {
    title: '',
    text: '',
    category: 'community',
    url: '',
    rounds: 20000,
    encrypted: false,
    encryptionKey: '',
    mnemonics: '',
    options: {},
  };
  const formik = useFormik({
    initialValues: initialValues,
    validate: data => {
      let errors: any = {};

      if (!data.title) {
        errors.title = 'Question title is required.';
      }

      if (!data.text) {
        errors.text = 'Question text is required.';
      }
      if (!data.category) {
        errors.category = 'Question category is required.';
      }
      const items = Object.values(options).filter(o => !!o);
      if (items.length == 0) {
        errors.options = 'Please provide at least one option.';
      }

      if (data.rounds <= 0) {
        errors.rounds = 'Number of rounds must be positive number.';
      }

      return errors;
    },
    onSubmit: data => {
      setFormData(data);
      setShowMessage(true);

      formik.resetForm();
    },
  });
  const isFormFieldValid = name => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = name => {
    return (
      isFormFieldValid(name) && (
        <div className="my-2">
          <small className="p-error">{formik.errors[name]}</small>
        </div>
      )
    );
  };
  const cancelOption = (e: any, k: string) => {
    e.preventDefault();
    const newOptions = { ...options };
    delete newOptions[k];
    setOptions(newOptions);
    formik.handleChange(e);
  };
  const updateOption = (e: any, k: string) => {
    const newOptions = { ...options };
    newOptions[k] = e.target.value;
    setOptions(newOptions);
    formik.handleChange(e);
  };
  const addNewOption = (e: any) => {
    e.preventDefault();
    let max = 0;
    for (let index in options) {
      try {
        const i = parseInt(index);
        if (max < i) max = i;
      } catch {} // index should always be number
    }
    const newOptions = { ...options };
    newOptions[max + 1] = '';
    setOptions(newOptions);
    formik.handleChange(e);
  };
  const newMnemonics = (e: any) => {
    e.preventDefault();
    const newData = { ...formik.values };
    var keys = algosdk.generateAccount();
    newData.encryptionKey = keys.addr;
    newData.mnemonics = algosdk.secretKeyToMnemonic(keys.sk);
    formik.values.encryptionKey = keys.addr;
    formik.values.mnemonics = newData.mnemonics;
    setFormData(newData);
    formik.handleChange(e);
  };

  const copyMnemonics = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(formik.values.mnemonics);
    toast.current.show({ severity: 'success', summary: 'Copy', detail: 'Mnemonics copied to clipboard', life: 3000 });
  };

  const updateItem = (e: any) => {
    let code = 'avote-question/v2:j';
    const question = {
      t: formik.values.title,
      q: formik.values.text.trim(),
      e: formik.values.encryptionKey,
      duration: formik.values.rounds,
      category: formik.values.category,
      url: formik.values.url,
      o: options,
    };
    code = code + JSON.stringify(question);
    setCode(code);
    formik.handleChange(e);
  };

  return (
    <>
      <Toast ref={toast} />
      <Panel>
        <div className="m-2">
          <h1>Ask question</h1>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="flex flex-column">
              <div className="flex flex-row">
                <Fieldset legend="Basic information" className="col-6">
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="title" name="title" value={formik.values.title} onChange={updateItem} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('title') })} />
                      <label htmlFor="title" className={classNames({ 'p-error': isFormFieldValid('title') })}>
                        Title
                      </label>
                    </span>
                    {getFormErrorMessage('title')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <InputTextarea
                        rows={8}
                        id="text"
                        name="text"
                        value={formik.values.text}
                        onChange={formik.handleChange}
                        autoFocus
                        className={classNames({ 'p-invalid': isFormFieldValid('text') })}
                      />
                      <label htmlFor="text" className={classNames({ 'p-error': isFormFieldValid('text') })}>
                        Text
                      </label>
                    </span>
                    {getFormErrorMessage('text')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="url" name="url" value={formik.values.url} onChange={updateItem} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('url') })} />
                      <label htmlFor="url" className={classNames({ 'p-error': isFormFieldValid('url') })}>
                        URL
                      </label>
                    </span>
                    {getFormErrorMessage('url')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="rounds" name="rounds" value={formik.values.rounds} onChange={updateItem} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('rounds') })} />
                      <label htmlFor="rounds" className={classNames({ 'p-error': isFormFieldValid('rounds') })}>
                        Number of blocks since now until the question will be closed
                      </label>
                    </span>
                    {getFormErrorMessage('rounds')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <InputText
                        id="category"
                        name="category"
                        value={formik.values.category}
                        onChange={formik.handleChange}
                        autoFocus
                        className={classNames({ 'p-invalid': isFormFieldValid('category') })}
                      />
                      <label htmlFor="category" className={classNames({ 'p-error': isFormFieldValid('category') })}>
                        Category
                      </label>
                    </span>
                    {getFormErrorMessage('category')}
                  </div>
                </Fieldset>
                <div className="col-6 flex flex-column m-0 p-0">
                  <Fieldset legend="Options">
                    {Object.keys(options).map(k => (
                      <>
                        <div className="field">
                          <div className="p-inputgroup">
                            <span className="p-float-label">
                              <InputText value={options[k]} onChange={e => updateOption(e, k)} autoFocus></InputText>
                              <label htmlFor="rounds" className={classNames({ 'p-error': isFormFieldValid('options') })}>
                                Option {k}
                              </label>
                            </span>
                            <Button icon="pi pi-times" onClick={e => cancelOption(e, k)} />
                          </div>
                        </div>
                      </>
                    ))}
                    {getFormErrorMessage('options')}
                    <Button onClick={e => addNewOption(e)}>Add new option</Button>
                  </Fieldset>
                  <Fieldset legend="Encryption">
                    <div className="field-checkbox">
                      <Checkbox inputId="encrypted" name="encrypted" checked={formik.values.encrypted} onChange={updateItem} className={classNames({ 'p-invalid': isFormFieldValid('encrypted') })} />
                      <label htmlFor="encrypted" className={classNames({ 'p-error': isFormFieldValid('encrypted') })}>
                        Encryption enabled
                      </label>
                    </div>
                    {formik.values.encrypted && (
                      <div className="field">
                        <div className="p-inputgroup">
                          <span className="p-float-label">
                            <InputText
                              id="encryptionKey"
                              name="encryptionKey"
                              value={formik.values.encryptionKey}
                              onChange={updateItem}
                              autoFocus
                              className={classNames({ 'p-invalid': isFormFieldValid('encryptionKey') })}
                            />
                            <label htmlFor="encryptionKey" className={classNames({ 'p-error': isFormFieldValid('encryptionKey') })}>
                              Encryption encryptionKey
                            </label>
                          </span>
                          <Button icon="pi pi-plus" onClick={e => newMnemonics(e)} />
                        </div>
                        {getFormErrorMessage('encryptionKey')}
                      </div>
                    )}

                    {formik.values.encrypted && formik.values.mnemonics && (
                      <div className="field">
                        <div className="p-inputgroup">
                          <span className="p-float-label">
                            <InputText id="mnemonics" name="mnemonics" value={formik.values.mnemonics} onChange={updateItem} className={classNames({ 'p-invalid': isFormFieldValid('mnemonics') })} />
                            <label htmlFor="mnemonics" className={classNames({ 'p-error': isFormFieldValid('mnemonics') })}>
                              Encryption mnemonics
                            </label>
                          </span>
                          <Button icon="pi pi-copy" onClick={e => copyMnemonics(e)} />
                        </div>
                        {getFormErrorMessage('mnemonics')}
                      </div>
                    )}
                  </Fieldset>
                </div>
              </div>
              <div className="my-2 p-0">
                <Button>Submit question</Button>
              </div>
            </div>
          </form>
        </div>
      </Panel>
    </>
  ); /**/
}
