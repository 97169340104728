import * as React from 'react';
import { useMountEffect } from 'primereact/hooks';
import { Ripple } from 'primereact/ripple';
import { classNames, IconUtils, ObjectUtils, UniqueComponentId, IconType } from 'primereact/utils';

import { CSSTransition, CSSTransitionProps } from 'primereact/csstransition';

type PanelHeaderTemplateType = React.ReactNode | ((options: PanelHeaderTemplateOptions) => React.ReactNode);

type PanelIconsTemplateType = React.ReactNode | ((props: PanelProps) => React.ReactNode);

interface PanelHeaderTemplateOptions {
  className: string;
  titleClassName: string;
  iconsClassName: string;
  togglerClassName: string;
  togglerIconClassName: string;
  onTogglerClick(event: React.MouseEvent<HTMLElement>): void;
  titleElement: JSX.Element;
  iconsElement: JSX.Element;
  togglerElement: JSX.Element;
  element: JSX.Element;
  props: PanelProps;
  collapsed: boolean;
}

interface PanelToggleParams {
  originalEvent: React.MouseEvent<HTMLElement>;
  value: boolean;
}

export interface PanelProps {
  id?: string;
  header?: React.ReactNode;
  headerTemplate?: PanelHeaderTemplateType;
  toggleable?: boolean;
  style?: object;
  className?: string;
  collapsed?: boolean;
  expandIcon?: IconType<PanelProps>;
  collapseIcon?: IconType<PanelProps>;
  icons?: PanelIconsTemplateType;
  transitionOptions?: CSSTransitionProps;
  onExpand?(event: React.SyntheticEvent): void;
  onCollapse?(event: React.SyntheticEvent): void;
  onToggle?(e: PanelToggleParams): void;
  children?: React.ReactNode;
}
export const Panel = React.forwardRef((props: PanelProps, ref) => {
  const [idState, setIdState] = React.useState(props.id);
  const [collapsedState, setCollapsedState] = React.useState(props.collapsed);
  const elementRef = React.useRef(ref);
  const contentRef = React.useRef(null);
  const collapsed = props.toggleable ? (props.onToggle ? props.collapsed : collapsedState) : false;
  const headerId = idState + '_header';
  const contentId = idState + '_content';

  const toggle = event => {
    if (props.toggleable) {
      collapsed ? expand(event) : collapse(event);

      if (props.onToggle) {
        props.onToggle({
          originalEvent: event,
          value: !collapsed,
        });
      }
    }

    event.preventDefault();
  };

  const expand = event => {
    if (!props.onToggle) {
      setCollapsedState(false);
    }

    props.onExpand && props.onExpand(event);
  };

  const collapse = event => {
    if (!props.onToggle) {
      setCollapsedState(true);
    }

    props.onCollapse && props.onCollapse(event);
  };

  React.useEffect(() => {
    ObjectUtils.combinedRefs(elementRef, ref);
  }, [elementRef, ref]);

  useMountEffect(() => {
    if (!idState) {
      setIdState(UniqueComponentId());
    }
  });

  const createToggleIcon = () => {
    if (props.toggleable) {
      const buttonId = idState + '_label';
      const toggleIcon = collapsed ? props.expandIcon : props.collapseIcon;

      return (
        <button className="p-panel-header-icon p-panel-toggler p-link" onClick={toggle} id={buttonId} aria-controls={contentId} aria-expanded={!collapsed} role="tab">
          {IconUtils.getJSXIcon(toggleIcon, undefined, { props, collapsed })}
          <Ripple />
        </button>
      );
    }

    return null;
  };

  const createHeader = () => {
    const header = ObjectUtils.getJSXElement(props.header, props);
    const icons = ObjectUtils.getJSXElement(props.icons, props);
    const togglerElement = createToggleIcon();
    const titleElement = (
      <span className="p-panel-title" id={headerId}>
        {header}
      </span>
    );
    const iconsElement = (
      <div className="p-panel-icons">
        {icons}
        {togglerElement}
      </div>
    );
    const content = (
      <div className="p-panel-header">
        {titleElement}
        {iconsElement}
      </div>
    );

    if (props.headerTemplate) {
      const defaultContentOptions = {
        className: 'p-panel-header',
        titleClassName: 'p-panel-title',
        iconsClassName: 'p-panel-icons',
        togglerClassName: 'p-panel-header-icon p-panel-toggler p-link',
        togglerIconClassName: collapsed ? props.expandIcon : props.collapseIcon,
        onTogglerClick: toggle,
        titleElement,
        iconsElement,
        togglerElement,
        element: content,
        props,
        collapsed,
      };

      return ObjectUtils.getJSXElement(props.headerTemplate, defaultContentOptions);
    } else if (props.header || props.toggleable) {
      return content;
    }

    return null;
  };

  const createContent = () => {
    return (
      <CSSTransition
        nodeRef={contentRef}
        classNames="p-toggleable-content flex-grow-1 flex flex-column"
        timeout={{ enter: 1000, exit: 450 }}
        in={!collapsed}
        unmountOnExit
        options={props.transitionOptions}
      >
        <div ref={contentRef} className="p-toggleable-content flex-grow-1 flex flex-column" aria-hidden={collapsed} role="region" id={contentId} aria-labelledby={headerId}>
          <div className="p-panel-content flex-grow-1 flex flex-column p-0">{props.children}</div>
        </div>
      </CSSTransition>
    );
  };

  const otherProps = ObjectUtils.findDiffKeys(props, Panel.defaultProps);
  const className = classNames(
    'p-panel p-component flex flex-column flex-grow-1',
    {
      'p-panel-toggleable': props.toggleable,
    },
    props.className
  );
  const header = createHeader();
  const content = createContent();

  return (
    <div id={props.id} className={className} style={props.style} {...otherProps}>
      {header}
      {content}
    </div>
  );
});
export default Panel;
