import { Trans } from 'react-i18next';

const ErrorHandler = (error: any) => {
  return (
    <div role="alert">
      <p>
        <Trans i18nKey="Common.ErrorOccured">An error occurred</Trans>:
      </p>
      <pre>{error.message}</pre>
    </div>
  );
};
export default ErrorHandler;
