import Header from '../components/Header';
import BoundedLayout from '../components/GridLayout';
import GlobalFilter from '../components/configurable/GlobalFilter';
import QuestionList from '../components/configurable/QuestionList';
import TokenList from '../components/configurable/TokenList';
import QuestionDetail from '../components/basic/QuestionDetail';
import { useParams } from 'react-router-dom';
import TokenInfo from '../components/configurable/TokenInfo';
import AskQuestion from '../components/basic/AskQuestion';

export default function QuestionPage() {
  const { id } = useParams();
  const tokenId = parseInt(id);
  return (
    <>
      <div className="flex flex flex-row">
        <div className=" flex-column flex min-h-screen p-3" style={{ width: '200px' }}>
          <Header />
        </div>
        <div className="flex flex-column flex-grow-1">
          <div className="m-2">
            <TokenInfo tokenId={tokenId}></TokenInfo>
          </div>
          <div className="m-2">
            <AskQuestion tokenId={tokenId}></AskQuestion>
          </div>
        </div>
      </div>
    </>
  );
}
