import { AppContext, IState } from '../../contexts/app-context';
import { useContext } from 'react';
import BaseTokenList from '../basic/BaseTokenList';
import FullTextTokenList from '../basic/FullTextTokenList';
import { ScrollPanel } from '../extended/ScrollPanel';
import BaseConfig from '../BaseConfig';
import { gql, useSubscription } from '@apollo/client';
import Panel from '../extended/Panel';
import TokenComponent from '../basic/TokenComponent';
import { Token } from '../../generated/graphql';
import TokenEvents from '../basic/TokenEvents';
import TokenQuestions from '../basic/TokenQuestions';
import TokenDelegations from '../basic/TokenDelegations';
import { Button } from 'primereact/button';
import TokenVotes from '../basic/TokenVotes';
import { useLocation, useNavigate } from 'react-router-dom';
interface TokenInfoProps {
  tokenId: number;
}
const query = gql`
  subscription token($env: String!, $asa: bigint!) {
    token(where: { asa: { _eq: $asa }, env: { _eq: $env } }, order_by: { created_at_round: desc }, limit: 1) {
      id
      name
      asa
      url
      total
      decimals
      unit
    }
  }
`;
export default function TokenInfo(props: TokenInfoProps) {
  const { tokenId } = props;
  let navigate = useNavigate();
  let location = useLocation();

  const appData = useContext(AppContext);
  const { data, loading, error } = useSubscription(query, {
    variables: {
      env: appData.env,
      asa: tokenId,
    },
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return (
      <div>
        Error...
        {error.message ? error.message : JSON.stringify(error)}
      </div>
    );
  }
  try {
    const token = data.token[0] as Token;

    const info = (
      <>
        <Panel>
          <div className="border-round-md flex flex-row  border-secondary border-1 mr-2 mb-2 p-1 p-card">
            <div className="flex flex-column justify-content-center border-right-1 border-300 p-2">
              <img src={`https://api.vote-coin.com/Space/${appData.env.replace('-v1.0', '')}/${token.asa}/Icon.svg`} height="100px" className=" m-0 mt-1" />
              <h3 className="flex-align-center text-center m-2">{token.name}</h3>
              <div className="flex flex-row card-container flex-wrap justify-content-between">
                <TokenEvents showText={false} token={token.id} className="border-round border-1 p-1 m-1 justify-content-center" />
                <TokenQuestions showText={false} token={token.id} className="border-round border-1 p-1 m-1 flex flex-grow-1 justify-content-center" />
                <TokenDelegations showText={false} token={token.id} className="border-round border-1 p-1 m-1 flex flex-grow-1 justify-content-center" />
              </div>
            </div>
            <div className="flex flex-column  p-2 flex-grow-1">
              <h3>About {token.name}</h3>
            </div>
            <div className="flex flex-column  p-2 border-left-1 border-300 p-2 justify-content-center">
              <TokenEvents showText={true} token={token.id} className="p-0 m-0 flex flex-grow-1 " />
              <TokenQuestions showText={true} token={token.id} className="p-0 m-0 flex flex-grow-1 " />
              <TokenVotes showText={true} token={token.id} className="p-0 m-0 flex flex-grow-1 " />
              <TokenDelegations showText={true} token={token.id} className="p-0 m-0 flex flex-grow-1 " />
            </div>
            <div className="flex flex-column  p-2 border-left-1 border-300 p-2 justify-content-center">
              <div>Total: {new Intl.NumberFormat(undefined, { maximumSignificantDigits: token.decimals }).format(token.total / Math.pow(10, token.decimals))}</div>
              <div>Decimals: {token.decimals}</div>
              <div>Unit: {token.unit}</div>
              <div>URL: {token.url}</div>
            </div>
            <div className="flex flex-column  p-2 border-left-1 border-300 p-2 justify-content-center">
              <Button className="m-1 p-1 text-center justify-content-center">Delegate</Button>
              <Button className="m-1 p-1 text-center justify-content-center" onClick={() => navigate(`/ask/${tokenId}`)} disabled={location.pathname == `/ask/${tokenId}`}>
                Ask question
              </Button>
              <Button className="m-1 p-1 text-center justify-content-center">TL management</Button>
              <Button className="m-1 p-1 text-center justify-content-center">ETL management</Button>
              <Button className="m-1 p-1 text-center justify-content-center">List governors</Button>
            </div>
          </div>
        </Panel>
      </>
    );
    return <>{appData.editingComponents ? <BaseConfig componentName="TokenInfo" /> : info} </>;
  } catch (e) {
    return (
      <div>
        Error...
        {e.message ? e.message : JSON.stringify(e)}
      </div>
    );
  }
}
