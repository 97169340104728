import { Question } from '../../generated/graphql';
import { Badge, BadgeSeverityType } from 'primereact/badge';
import moment from 'moment';
import minifyAddr from '../minifyAddr';
import goToExternal from '../goToExternal';
import { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import TokenBaseInfo from './TokenBaseInfo';
import { useNavigate } from 'react-router-dom';
import Panel from '../extended/Panel';
interface QuestionProps {
  question: any;
  showToken: boolean;
}

const format2digits = (num: number) => {
  return ('0' + Math.abs(num)).slice(-2);
};

const calculateVotingSessionEnd = (roundStart: number, currentRound: number, roundEnd: number, timeStart: string) => {
  if (currentRound > roundEnd) return 'Closed';
  const fromStart = currentRound - roundStart;
  const toEnd = roundEnd - currentRound;
  const fromStartTicks = moment().diff(moment(timeStart)).valueOf();
  return (fromStartTicks / fromStart) * toEnd;
};

const votingSessionEndTimeFormatted = (roundStart: number, currentRound: number, roundEnd: number, timeStart: string) => {
  const endTicks = calculateVotingSessionEnd(roundStart, currentRound, roundEnd, timeStart);
  return moment().add(moment.duration(endTicks)).format('dddd, MMMM Do YYYY, h:mm:ss a');
};
const votingSessionEndFormatted = (roundStart: number, currentRound: number, roundEnd: number, timeStart: string) => {
  const endTicks = calculateVotingSessionEnd(roundStart, currentRound, roundEnd, timeStart);
  const duration = moment.duration(endTicks);
  return formatDuration(duration);
};
const formatDuration = (duration: moment.Duration) => {
  let ret = '';
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (days > 1) {
    ret += `${days} days`;
  }
  if (days === 1) {
    ret += `${days} day`;
  }
  if (hours > 1 || hours === 0) {
    if (ret) ret += ' ';
    ret += `${hours} hours`;
  }
  if (hours === 1) {
    if (ret) ret += ' ';
    ret += `${hours} hour`;
  }
  if (minutes > 1 || minutes === 0) {
    if (ret) ret += ' ';
    ret += `${minutes} minutes`;
  }
  if (minutes === 1) {
    if (ret) ret += ' ';
    ret += `${minutes} minute`;
  }
  if (seconds > 1 || seconds === 0) {
    if (ret) ret += ' ';
    ret += `${seconds} seconds`;
  }
  if (seconds === 1) {
    if (ret) ret += ' ';
    ret += `${seconds} seconds`;
  }
  return ret;
};
interface IStatus {
  status: string;
  severity: BadgeSeverityType;
}
const getStatus = (currentRound: number, roundEnd: number): IStatus => {
  if (currentRound > roundEnd) {
    return { status: 'Closed', severity: 'danger' };
  }
  return { status: 'Open', severity: 'info' };
};

export default function QuestionComponent(props: QuestionProps) {
  let navigate = useNavigate();
  const appData = useContext(AppContext);
  const status = getStatus(appData.round, props.question.ending_round);
  return (
    <Panel>
      <div className="border-round-md border-secondary border-1 my-2 p-2 flex flex-row ">
        {props.showToken && (
          <div className="flex flex-row border-right-1 p-2 border-500" style={{ minWidth: '150px' }}>
            <TokenBaseInfo id={props.question.token}></TokenBaseInfo>
          </div>
        )}
        <div className="flex flex-column flex-grow-1 p-2">
          <div className="flex flex-row  flex-wrap card-container">
            <span className="text-lg font-bold my-2 clickable" onClick={() => navigate(`/question/${props.question.tx}`)}>
              {props.question.title}
            </span>
            <span className="flex flex-grow-1"></span>
            <Badge className="m-1" value={props.question.category} severity="success"></Badge>
            <Badge className="m-1" value={status.status} severity={status.severity}></Badge>
          </div>
          <p className="text-sm opacity-50">{moment(props.question.open_from_time).format('dddd, MMMM Do YYYY, h:mm:ss a')}</p>
          <p className="text-sm clickable" onClick={() => navigate(`/question/${props.question.tx}`)}>
            {props.question.text}
          </p>
          <div className="flex flex-row  flex-wrap card-container">
            {status.severity === 'danger' ? (
              <span className="text-sm opacity-50">
                <i className="pi pi-clock"></i> Voting session ended at:{' '}
                {votingSessionEndTimeFormatted(props.question.open_from_round, appData.round, props.question.ending_round, props.question.open_from_time)}
              </span>
            ) : (
              <span className="text-sm">
                <i className="pi pi-clock"></i> Estimated end at:{' '}
                <span title={votingSessionEndTimeFormatted(props.question.open_from_round, appData.round, props.question.ending_round, props.question.open_from_time)}>
                  {votingSessionEndFormatted(props.question.open_from_round, appData.round, props.question.ending_round, props.question.open_from_time)}
                </span>
              </span>
            )}

            <span className="flex flex-grow-1"></span>
            <span className="text-sm opacity-50 clickable" onClick={() => goToExternal(`https://algoexplorer.io/tx/${props.question.tx}`)}>
              {minifyAddr(props.question.questioner)}
            </span>
          </div>
        </div>
      </div>
    </Panel>
  ); /**/
}
