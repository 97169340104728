import { AppContext, IState } from '../../contexts/app-context';
import { useContext } from 'react';
import FullTextQuestionList from '../basic/FullTextQuestionList';
import { ScrollPanel } from '../extended/ScrollPanel';
import BaseConfig from '../BaseConfig';

interface QuestionListParams {
  token?: number;
}

export default function QuestionList(params: QuestionListParams) {
  const appData = useContext(AppContext);
  return (
    <>{appData.editingComponents ? <BaseConfig componentName="QuestionList" /> : <FullTextQuestionList search={appData.globalFilter && appData.globalFilter.length >= 3} token={params.token} />}</>
  );
}
