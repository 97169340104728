import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import Panel from '../extended/Panel';
import DaoForm from '../form/DaoForm';
import BaseTokenList from './BaseTokenList';

export default function EditDaoForm() {
  const authContext = useContext(AuthContext);

  return (
    <Panel className="my-2 mr-2">
      <div className="flex flex-column m-2 flex-grow-1">
        <h1>Manage Onchain Community</h1>
        <div className="flex flex-row m-2 flex-grow-1">
          <DaoForm></DaoForm>
          <Panel className="col-6" header="Manage existing onchain community">
            <div className="flex flex-column m-2 flex-grow-1"></div>
          </Panel>
        </div>
      </div>
    </Panel>
  );
}
