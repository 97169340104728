import { Question, Question_Min_Fields } from '../../generated/graphql';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import minifyAddr from '../minifyAddr';
import goToExternal from '../goToExternal';
import { useContext, useRef, useState } from 'react';
import { AppContext } from '../../contexts/app-context';
import { useNavigate } from 'react-router-dom';
import getQuestionById from '../../scripts/graphql/getQuestionById';
import { ProgressSpinner } from 'primereact/progressspinner';
import Panel from '../extended/Panel';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import algosdk from 'algosdk';
import { v4 as uuidv4 } from 'uuid';

interface IFormData {
  name: string;
  description: string;
  url: string;
  iconUrl: string;
  addresses: IETLToken2Weight;
  tokens: IETLToken2Weight;
}

interface IOptions {
  [key: string]: string;
}

interface IETLToken {
  token: string;
  weight: number;
}
interface IETLTokenObj {
  [key: string]: IETLToken;
}
interface IETLToken2Weight {
  [key: string]: number;
}

interface IETLAddress {
  address: string;
  weight: number;
}
interface IETLAddressObj {
  [key: string]: IETLAddress;
}
interface IETLAddress2Weight {
  [key: string]: number;
}

export default function DaoForm() {
  const navigate = useNavigate();
  const appData = useContext(AppContext);
  const toast = useRef(null);

  const [question, setQuestion] = useState<Question_Min_Fields>(null);
  const [formData, setFormData] = useState<IFormData>();
  const [options, setOptions] = useState<IOptions>({ '1': '', '2': '' });
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const defaultEtlTokenList: IETLTokenObj = {};
  defaultEtlTokenList[uuidv4()] = { token: '', weight: 1 };
  const [etlTokens, setETLTokens] = useState<IETLTokenObj>(defaultEtlTokenList);
  const [etlAddresses, setETLAddresses] = useState<IETLAddressObj>({});
  const [daoRegistrationMessage, setDaoRegistrationMessage] = useState<string>('');
  const initialValues: IFormData = {
    name: '',
    description: '',
    url: '',
    iconUrl: '',
    addresses: {},
    tokens: {},
  };
  const formik = useFormik({
    initialValues: initialValues,
    validate: data => {
      let errors: any = {};

      if (!data.name) {
        errors.title = 'Please provide name for your community.';
      }

      if (!data.description) {
        errors.description = 'Please describe your community.';
      }
      const items = Object.values(options).filter(o => !!o);
      if (items.length == 0) {
        errors.options = 'Please provide at least one option.';
      }

      return errors;
    },
    onSubmit: data => {
      setFormData(data);
      setShowMessage(true);

      formik.resetForm();
    },
  });
  const isFormFieldValid = name => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = name => {
    return (
      isFormFieldValid(name) && (
        <div className="my-2">
          <small className="p-error">{formik.errors[name]}</small>
        </div>
      )
    );
  };
  const cancelOption = (e: any, k: string) => {
    e.preventDefault();
    const newOptions = { ...options };
    delete newOptions[k];
    setOptions(newOptions);
    formik.handleChange(e);
  };
  const updateOption = (e: any, k: string) => {
    const newOptions = { ...options };
    newOptions[k] = e.target.value;
    setOptions(newOptions);
    formik.handleChange(e);
  };
  const addNewOption = (e: any) => {
    e.preventDefault();
    let max = 0;
    for (let index in options) {
      try {
        const i = parseInt(index);
        if (max < i) max = i;
      } catch {} // index should always be number
    }
    const newOptions = { ...options };
    newOptions[max + 1] = '';
    setOptions(newOptions);
    formik.handleChange(e);
  };

  const updateItem = (e: any) => {
    formik.handleChange(e);
    formUpdated();
  };

  const addETGToken = (e: any) => {
    e.preventDefault();
    const newTokens = { ...etlTokens };
    newTokens[uuidv4()] = { token: '', weight: 1 };
    setETLTokens(newTokens);
    console.log('newTokens', newTokens);
    formUpdated();
  };
  const addETGAddress = (e: any) => {
    e.preventDefault();
    const newAddresses = { ...etlAddresses };
    newAddresses[uuidv4()] = { address: '', weight: 1 };
    setETLAddresses(newAddresses);
    formUpdated();
  };
  const updateToken = (id: string, e: any) => {
    e.preventDefault();
    const newTokens = { ...etlTokens };
    newTokens[id].token = e.target.value;
    setETLTokens(newTokens);
    formUpdated();
  };
  const updateTokenWeight = (id: string, e: any) => {
    e.preventDefault();
    const newTokens = { ...etlTokens };
    newTokens[id].weight = e.target.value;
    setETLTokens(newTokens);
    formUpdated();
  };
  const cancelToken = (id: string, e: any) => {
    e.preventDefault();
    const newTokens = { ...etlTokens };
    delete newTokens[id];
    setETLTokens(newTokens);
    formUpdated();
  };

  const updateAddress = (id: string, e: any) => {
    e.preventDefault();
    const newAddresses = { ...etlAddresses };
    newAddresses[id].address = e.target.value;
    setETLAddresses(newAddresses);
    formUpdated();
  };
  const updateAddressWeight = (id: string, e: any) => {
    e.preventDefault();
    const newAddresses = { ...etlAddresses };
    newAddresses[id].weight = e.target.value;
    setETLAddresses(newAddresses);
    formUpdated();
  };
  const cancelAddress = (id: string, e: any) => {
    e.preventDefault();
    const newAddresses = { ...etlAddresses };
    delete newAddresses[id];
    setETLAddresses(newAddresses);
    formUpdated();
  };
  const formUpdated = () => {
    const msg: IFormData = { ...formik.values };
    msg.addresses = {};
    msg.tokens = {};
    for (let address of Object.values(etlAddresses)) {
      msg.addresses[address.address] = address.weight;
    }
    for (let token of Object.values(etlTokens)) {
      msg.tokens[token.token] = token.weight;
    }
    setDaoRegistrationMessage('avote-oc/v1:j' + JSON.stringify(msg));
  };
  const register = () => {
    /// TODO .. submit the message to the network
  };
  return (
    <>
      <Toast ref={toast} />
      <Panel>
        <div className="m-2">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="flex flex-column">
              <div className="flex flex-row">
                <Fieldset legend="Register new Onchain Community" className="col-6">
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="name" name="name" value={formik.values.name} onChange={updateItem} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                      <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                        Onchain community name
                      </label>
                    </span>
                    {getFormErrorMessage('name')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <InputTextarea
                        rows={8}
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onChange={updateItem}
                        autoFocus
                        className={classNames({ 'p-invalid': isFormFieldValid('description') })}
                      />
                      <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>
                        Description
                      </label>
                    </span>
                    {getFormErrorMessage('description')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="url" name="url" value={formik.values.url} onChange={updateItem} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('url') })} />
                      <label htmlFor="url" className={classNames({ 'p-error': isFormFieldValid('url') })}>
                        Project web URL
                      </label>
                    </span>
                    {getFormErrorMessage('iconUrl')}
                  </div>
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="iconUrl" name="iconUrl" value={formik.values.iconUrl} onChange={updateItem} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('iconUrl') })} />
                      <label htmlFor="url" className={classNames({ 'p-error': isFormFieldValid('iconUrl') })}>
                        Icon URL
                      </label>
                    </span>
                    {getFormErrorMessage('url')}
                  </div>
                </Fieldset>
                <Fieldset legend="Eligible token list">
                  <p className="mb-2 mx-0">
                    Eligible token list (ETL) is list of eligible tokens which may vote for the community. NFT communities usually want to define creator addresses and standard community usually wants
                    to define single token. ETL and community registration is subject of change with approval with voting decision from the community's current ETL group.
                  </p>
                  <div className="flex flex-row">
                    <Button className="mr-1" onClick={e => addETGToken(e)}>
                      Add new token
                    </Button>
                    <Button className="ml-1" onClick={e => addETGAddress(e)}>
                      Add new creator's address
                    </Button>
                  </div>
                  {Object.keys(etlTokens).map(etlTokenId => (
                    <div className="flex flex-row" key={etlTokenId}>
                      <span className="p-float-label my-1">
                        <InputText value={etlTokens[etlTokenId].token} onChange={e => updateToken(etlTokenId, e)} autoFocus />
                        <label htmlFor="name">Token</label>
                      </span>
                      <span className="p-float-label m-1">
                        <InputText value={etlTokens[etlTokenId].weight} onChange={e => updateTokenWeight(etlTokenId, e)} autoFocus />
                        <label htmlFor="name">Weight</label>
                      </span>
                      <div className="my-1">
                        <Button icon="pi pi-times" onClick={e => cancelToken(etlTokenId, e)} />
                      </div>
                    </div>
                  ))}
                  {Object.keys(etlAddresses).map(etlAddressId => (
                    <div className="flex flex-row" key={etlAddressId}>
                      <span className="p-float-label m-1">
                        <InputText value={etlAddresses[etlAddressId].address} onChange={e => updateAddress(etlAddressId, e)} autoFocus />
                        <label htmlFor="name">Creator's address</label>
                      </span>
                      <span className="p-float-label m-1">
                        <InputText value={etlAddresses[etlAddressId].weight} onChange={e => updateAddressWeight(etlAddressId, e)} autoFocus />
                        <label htmlFor="name">Weight</label>
                      </span>
                      <div className="m-1">
                        <Button icon="pi pi-times" onClick={e => cancelAddress(etlAddressId, e)} />
                      </div>
                    </div>
                  ))}
                </Fieldset>
              </div>
              <div className="my-2 p-0">
                <code className="my-2">{daoRegistrationMessage}</code>
                <Button className="my-2" onClick={() => register()}>
                  Register new Onchain Community
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Panel>
    </>
  ); /**/
}
