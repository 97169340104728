import i18n from 'i18next';
import { AppContext, IState } from '../../contexts/app-context';
import BaseConfig from '../BaseConfig';
import Panel from '../extended/Panel';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { Question } from '../../generated/graphql';
import QuestionComponent from '../basic/QuestionComponent';
import { useContext } from 'react';
import { ScrollPanel } from '../extended/ScrollPanel';
interface QuestionListParams {
  token?: number;
  search: boolean;
}
const GET_QUESTIONS_FULLTEXT = gql`
  subscription question($env: String!, $search: String!) {
    question(where: { env: { _eq: $env }, text: { _ilike: $search } }, order_by: { open_from_round: desc }, limit: 10) {
      id
      tx
      category
      title
      text
      open_from_time
      open_from_round
      ending_round
      questioner
      token
    }
  }
`;
const GET_QUESTIONS_FULLTEXT_WITH_TOKEN = gql`
  subscription question($env: String!, $search: String!, $asa: bigint!) {
    question(where: { asa: { _eq: $asa }, env: { _eq: $env }, text: { _ilike: $search } }, order_by: { open_from_round: desc }, limit: 10) {
      id
      tx
      category
      title
      text
      open_from_time
      open_from_round
      ending_round
      questioner
      token
    }
  }
`;
const GET_QUESTIONS_BASE_WITH_TOKEN = gql`
  subscription question($env: String!, $asa: bigint!) {
    question(where: { asa: { _eq: $asa }, env: { _eq: $env } }, order_by: { open_from_round: desc }, limit: 10) {
      id
      tx
      category
      title
      text
      open_from_time
      open_from_round
      ending_round
      questioner
      token
    }
  }
`;
const GET_QUESTIONS_BASE = gql`
  subscription question($env: String!) {
    question(where: { env: { _eq: $env } }, order_by: { open_from_round: desc }, limit: 10) {
      id
      tx
      category
      title
      text
      open_from_time
      open_from_round
      ending_round
      questioner
      token
    }
  }
`;
export default function FullTextQuestionList(params: QuestionListParams) {
  const appData = useContext(AppContext);
  let query;
  const queryVariables: any = {
    env: appData.env,
  };
  if (params.search && params.token !== undefined) {
    query = GET_QUESTIONS_FULLTEXT_WITH_TOKEN;
    queryVariables.search = `%${appData.globalFilter}%`;
    queryVariables.asa = params.token;
  } else if (params.search && !params.token) {
    query = GET_QUESTIONS_FULLTEXT;
    queryVariables.search = `%${appData.globalFilter}%`;
  } else if (params.token !== undefined) {
    query = GET_QUESTIONS_BASE_WITH_TOKEN;
    queryVariables.asa = params.token;
  } else {
    query = GET_QUESTIONS_BASE;
  }
  const { data, loading, error } = useSubscription(query, {
    variables: queryVariables,
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return (
      <div>
        Error...
        {error.message ? error.message : JSON.stringify(error)}
      </div>
    );
  }
  try {
    const questions = (data.question as Array<any>).map(q => <QuestionComponent showToken={params.token === undefined} key={q.id} question={q}></QuestionComponent>);
    const headerText = params.search ? 'Filtered list of decisions' : 'Decisions list';
    return (
      <>
        <Panel header={headerText} className="flex flex-column m-0 mb-2 mr-2 p-0 flex-grow-1">
          <div className="m-2 flex flex-column flex-grow-1">
            {params.search && <div>Filter: {appData.globalFilter}</div>}
            {questions.length > 0 ? (
              <ScrollPanel style={{ width: '100%', height: '100%' }} className="flex flex-grow-1 h-10rem ml-2 pr-2">
                {questions}
              </ScrollPanel>
            ) : (
              <>No reulsts found</>
            )}
          </div>
        </Panel>
      </>
    ); /**/
  } catch (e) {
    return (
      <div>
        Error...
        {e.message ? e.message : JSON.stringify(e)}
      </div>
    );
  } /**/
}
