import { Question } from '../../generated/graphql';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import minifyAddr from '../minifyAddr';
import goToExternal from '../goToExternal';
import { AppContext, IState } from '../../contexts/app-context';
import { gql, useSubscription } from '@apollo/client';
import { Indexer } from 'algosdk';
import { useContext, useEffect } from 'react';
interface RoundComponentProps {
  env: String;
}
const query = gql`
  subscription indexer($instance: String!) {
    indexer(where: { instance: { _eq: $instance } }, limit: 1) {
      round
      updated_at
    }
  }
`;
export default function RoundComponent(props: RoundComponentProps) {
  const appData = useContext(AppContext);

  const { data, loading, error } = useSubscription(query, {
    variables: {
      instance: props.env,
    },
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return <div>Error... {error}</div>;
  }

  const value = data.indexer[0].round;
  if (value != appData.round) {
    appData.setAppData({ ...appData, round: value });
  }
  return (
    <div className="">
      <i className="pi pi-box"></i> {value}
    </div>
  );
}
