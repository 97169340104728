import { Question } from '../../generated/graphql';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import minifyAddr from '../minifyAddr';
import goToExternal from '../goToExternal';
import { AppContext, IState } from '../../contexts/app-context';
import { gql, useQuery } from '@apollo/client';
import { Indexer } from 'algosdk';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
interface TokenBaseInfoProps {
  id: number;
}
const query = gql`
  query token($id: Int!) {
    token(where: { id: { _eq: $id } }, limit: 1) {
      name
      asa
      updated_at
    }
  }
`;
export default function TokenBaseInfo(props: TokenBaseInfoProps) {
  let navigate = useNavigate();
  const appData = useContext(AppContext);

  const { loading, error, data } = useQuery(query, {
    variables: {
      id: props.id,
    },
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return <div>Error... {error}</div>;
  }

  const value = data.token[0];
  return (
    <div className="flex flex-column text-center justify-content-center align-items-center clickable flex-grow-1" onClick={() => navigate(`/asset/${value.asa}`)}>
      <b className="m-1">{value.name}</b>
      <img src={`https://api.vote-coin.com/Space/${appData.env.replace('-v1.0', '')}/${value.asa}/Icon.svg`} height="100px" className="mt-1" />
    </div>
  );
}
