import * as React from 'react';
import { useMountEffect, useUnmountEffect } from 'primereact/hooks';
import { classNames, DomHandler, ObjectUtils } from 'primereact/utils';

export interface ScrollPanelProps {
  id?: string;
  style?: object;
  className?: string;
  children?: React.ReactNode;
}

export const ScrollPanel = React.forwardRef((props: ScrollPanelProps, ref) => {
  const containerRef = React.useRef(null);
  const contentRef = React.useRef(null);
  const isXBarClicked = React.useRef(false);
  const isYBarClicked = React.useRef(false);
  const lastPageX = React.useRef(null);
  const lastPageY = React.useRef(null);
  const scrollXRatio = React.useRef(null);
  const scrollYRatio = React.useRef(null);
  const frame = React.useRef(null);
  const initialized = React.useRef(false);

  const calculateContainerHeight = () => {
    const containerStyles = getComputedStyle(containerRef.current);
    const pureContainerHeight = DomHandler.getHeight(containerRef.current);

    if (containerStyles['max-height'] !== 'none' && pureContainerHeight === 0) {
      if (contentRef.current.offsetHeight > parseInt(containerStyles['max-height'], 10)) {
        containerRef.current.style.height = containerStyles['max-height'];
      } else {
        containerRef.current.style.height =
          contentRef.current.offsetHeight +
          parseFloat(containerStyles.paddingTop) +
          parseFloat(containerStyles.paddingBottom) +
          parseFloat(containerStyles.borderTopWidth) +
          parseFloat(containerStyles.borderBottomWidth) +
          'px';
      }
    }
  };

  const moveBar = () => {
    // horizontal scroll
    const totalWidth = contentRef.current.scrollWidth;
    const ownWidth = contentRef.current.clientWidth;
    const bottom = containerRef.current.clientHeight * -1;
    scrollXRatio.current = ownWidth / totalWidth;

    // vertical scroll
    const totalHeight = contentRef.current.scrollHeight;
    const ownHeight = contentRef.current.clientHeight;
    const right = containerRef.current.clientWidth * -1;
    scrollYRatio.current = ownHeight / totalHeight;
  };

  const onYBarMouseDown = event => {
    isYBarClicked.current = true;
    lastPageY.current = event.pageY;
    DomHandler.addClass(document.body, 'p-scrollpanel-grabbed');

    document.addEventListener('mousemove', onDocumentMouseMove);
    document.addEventListener('mouseup', onDocumentMouseUp);
    event.preventDefault();
  };

  const onXBarMouseDown = event => {
    isXBarClicked.current = true;
    lastPageX.current = event.pageX;
    DomHandler.addClass(document.body, 'p-scrollpanel-grabbed');

    document.addEventListener('mousemove', onDocumentMouseMove);
    document.addEventListener('mouseup', onDocumentMouseUp);
    event.preventDefault();
  };

  const onDocumentMouseMove = event => {
    if (isXBarClicked) {
      onMouseMoveForXBar(event);
    } else if (isYBarClicked) {
      onMouseMoveForYBar(event);
    } else {
      onMouseMoveForXBar(event);
      onMouseMoveForYBar(event);
    }
  };

  const onMouseMoveForXBar = event => {
    const deltaX = event.pageX - lastPageX.current;
    lastPageX.current = event.pageX;

    frame.current = window.requestAnimationFrame(() => {
      contentRef.current.scrollLeft += deltaX / scrollXRatio.current;
    });
  };

  const onMouseMoveForYBar = event => {
    const deltaY = event.pageY - lastPageY.current;
    lastPageY.current = event.pageY;

    frame.current = window.requestAnimationFrame(() => {
      contentRef.current.scrollTop += deltaY / scrollYRatio.current;
    });
  };

  const onDocumentMouseUp = event => {
    DomHandler.removeClass(document.body, 'p-scrollpanel-grabbed');

    document.removeEventListener('mousemove', onDocumentMouseMove);
    document.removeEventListener('mouseup', onDocumentMouseUp);
    isXBarClicked.current = false;
    isYBarClicked.current = false;
  };

  const refresh = () => {
    moveBar();
  };

  useMountEffect(() => {
    moveBar();
    window.addEventListener('resize', moveBar);
    calculateContainerHeight();
    initialized.current = true;
  });

  useUnmountEffect(() => {
    if (initialized.current) {
      window.removeEventListener('resize', moveBar);
    }

    if (frame.current) {
      window.cancelAnimationFrame(frame.current);
    }
  });

  React.useImperativeHandle(ref, () => ({
    refresh,
  }));

  const otherProps = ObjectUtils.findDiffKeys(props, ScrollPanel.defaultProps);
  const className = classNames('p-scrollpanel p-component', props.className);

  return (
    <div ref={containerRef} id={props.id} className={className} style={props.style} {...otherProps}>
      <div className="p-scrollpanel-wrapper flex flex-grow-1 p-0 m-0">
        <div ref={contentRef} className="p-scrollpanel-content flex flex-grow-1 flex-column p-0  m-0" onScroll={moveBar} onMouseEnter={moveBar}>
          {props.children}
        </div>
      </div>
    </div>
  );
});
