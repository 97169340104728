import { AppContext, IState } from '../../contexts/app-context';
import { useContext } from 'react';
import BaseTokenList from '../basic/BaseTokenList';
import FullTextTokenList from '../basic/FullTextTokenList';
import { ScrollPanel } from '../extended/ScrollPanel';
import BaseConfig from '../BaseConfig';

export default function TokenList() {
  const appData = useContext(AppContext);
  return <>{appData.editingComponents ? <BaseConfig componentName="TokenList" /> : appData.globalFilter && appData.globalFilter.length >= 3 ? <FullTextTokenList /> : <BaseTokenList />}</>;
}
