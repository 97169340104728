import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import getApolloClient from '../../components/getApolloClient';
import { IState } from '../../contexts/app-context';
import { IAuthState } from '../../contexts/AuthContext';
import { Question_Min_Fields } from '../../generated/graphql';

const query = gql`
  query question($tx: String!, $env: String!) {
    question(where: { tx: { _eq: $tx }, env: { _eq: $env } }, limit: 1) {
      questioner
      title
      text
      category
      url
      asa
      env
      token
      encryption_address
      ending_round
      open_from_round
      open_from_time
      tx
      json
      created_at
      updated_at
    }
  }
`;

const getQuestionById = async (id: string, appData: IState, authContext: IAuthState): Promise<Question_Min_Fields> => {
  const client = getApolloClient(authContext);
  const res = await client.query({
    query: query,
    variables: {
      tx: id,
      env: appData.env,
    },
  });
  if (!res || !res.data || !res.data.question || res.data.question.length == 0) return {};
  return res.data.question[0] as Question_Min_Fields;
};
export default getQuestionById;
