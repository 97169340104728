/**
 * GQTY AUTO-GENERATED CODE: PLEASE DO NOT MODIFY MANUALLY
 */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  timestamptz: any;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface Int_comparison_exp {
  _eq?: InputMaybe<Scalars["Int"]>;
  _gt?: InputMaybe<Scalars["Int"]>;
  _gte?: InputMaybe<Scalars["Int"]>;
  _in?: InputMaybe<Array<Scalars["Int"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Int"]>;
  _lte?: InputMaybe<Scalars["Int"]>;
  _neq?: InputMaybe<Scalars["Int"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_comparison_exp {
  _eq?: InputMaybe<Scalars["String"]>;
  _gt?: InputMaybe<Scalars["String"]>;
  _gte?: InputMaybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]>;
  _in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]>;
  _lt?: InputMaybe<Scalars["String"]>;
  _lte?: InputMaybe<Scalars["String"]>;
  _neq?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]>;
  _nin?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]>;
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface bigint_comparison_exp {
  _eq?: InputMaybe<Scalars["bigint"]>;
  _gt?: InputMaybe<Scalars["bigint"]>;
  _gte?: InputMaybe<Scalars["bigint"]>;
  _in?: InputMaybe<Array<Scalars["bigint"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["bigint"]>;
  _lte?: InputMaybe<Scalars["bigint"]>;
  _neq?: InputMaybe<Scalars["bigint"]>;
  _nin?: InputMaybe<Array<Scalars["bigint"]>>;
}

/** Boolean expression to filter rows from the table "delegation". All fields are combined with a logical 'AND'. */
export interface delegation_bool_exp {
  _and?: InputMaybe<Array<delegation_bool_exp>>;
  _not?: InputMaybe<delegation_bool_exp>;
  _or?: InputMaybe<Array<delegation_bool_exp>>;
  account?: InputMaybe<String_comparison_exp>;
  asa?: InputMaybe<bigint_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  env?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  json?: InputMaybe<String_comparison_exp>;
  round?: InputMaybe<bigint_comparison_exp>;
  token?: InputMaybe<Int_comparison_exp>;
  tx?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
}

/** unique or primary key constraints on table "delegation" */
export enum delegation_constraint {
  /** unique or primary key constraint */
  delegation_pkey = "delegation_pkey",
  /** unique or primary key constraint */
  delegation_tx_key = "delegation_tx_key",
}

/** input type for incrementing numeric columns in table "delegation" */
export interface delegation_inc_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
}

/** input type for inserting data into table "delegation" */
export interface delegation_insert_input {
  account?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** on_conflict condition type for table "delegation" */
export interface delegation_on_conflict {
  constraint: delegation_constraint;
  update_columns?: Array<delegation_update_column>;
  where?: InputMaybe<delegation_bool_exp>;
}

/** Ordering options when selecting data from "delegation". */
export interface delegation_order_by {
  account?: InputMaybe<order_by>;
  asa?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  env?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  json?: InputMaybe<order_by>;
  round?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  tx?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
}

/** primary key columns input for table: delegation */
export interface delegation_pk_columns_input {
  id: Scalars["Int"];
}

/** select columns of table "delegation" */
export enum delegation_select_column {
  /** column name */
  account = "account",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "delegation" */
export interface delegation_set_input {
  account?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** update columns of table "delegation" */
export enum delegation_update_column {
  /** column name */
  account = "account",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

/** Boolean expression to filter rows from the table "etg". All fields are combined with a logical 'AND'. */
export interface etg_bool_exp {
  _and?: InputMaybe<Array<etg_bool_exp>>;
  _not?: InputMaybe<etg_bool_exp>;
  _or?: InputMaybe<Array<etg_bool_exp>>;
  account?: InputMaybe<String_comparison_exp>;
  asa?: InputMaybe<bigint_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  env?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  json?: InputMaybe<String_comparison_exp>;
  round?: InputMaybe<bigint_comparison_exp>;
  token?: InputMaybe<Int_comparison_exp>;
  tx?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
}

/** unique or primary key constraints on table "etg" */
export enum etg_constraint {
  /** unique or primary key constraint */
  etg_pkey = "etg_pkey",
  /** unique or primary key constraint */
  etg_tx_key = "etg_tx_key",
}

/** input type for incrementing numeric columns in table "etg" */
export interface etg_inc_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
}

/** input type for inserting data into table "etg" */
export interface etg_insert_input {
  account?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** on_conflict condition type for table "etg" */
export interface etg_on_conflict {
  constraint: etg_constraint;
  update_columns?: Array<etg_update_column>;
  where?: InputMaybe<etg_bool_exp>;
}

/** Ordering options when selecting data from "etg". */
export interface etg_order_by {
  account?: InputMaybe<order_by>;
  asa?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  env?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  json?: InputMaybe<order_by>;
  round?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  tx?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
}

/** primary key columns input for table: etg */
export interface etg_pk_columns_input {
  id: Scalars["Int"];
}

/** select columns of table "etg" */
export enum etg_select_column {
  /** column name */
  account = "account",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "etg" */
export interface etg_set_input {
  account?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** update columns of table "etg" */
export enum etg_update_column {
  /** column name */
  account = "account",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

/** Boolean expression to filter rows from the table "indexer". All fields are combined with a logical 'AND'. */
export interface indexer_bool_exp {
  _and?: InputMaybe<Array<indexer_bool_exp>>;
  _not?: InputMaybe<indexer_bool_exp>;
  _or?: InputMaybe<Array<indexer_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  instance?: InputMaybe<String_comparison_exp>;
  round?: InputMaybe<bigint_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
}

/** unique or primary key constraints on table "indexer" */
export enum indexer_constraint {
  /** unique or primary key constraint */
  indexer_instance_key = "indexer_instance_key",
  /** unique or primary key constraint */
  indexer_pkey = "indexer_pkey",
}

/** input type for incrementing numeric columns in table "indexer" */
export interface indexer_inc_input {
  id?: InputMaybe<Scalars["Int"]>;
  round?: InputMaybe<Scalars["bigint"]>;
}

/** input type for inserting data into table "indexer" */
export interface indexer_insert_input {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  instance?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** on_conflict condition type for table "indexer" */
export interface indexer_on_conflict {
  constraint: indexer_constraint;
  update_columns?: Array<indexer_update_column>;
  where?: InputMaybe<indexer_bool_exp>;
}

/** Ordering options when selecting data from "indexer". */
export interface indexer_order_by {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  instance?: InputMaybe<order_by>;
  round?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
}

/** primary key columns input for table: indexer */
export interface indexer_pk_columns_input {
  id: Scalars["Int"];
}

/** select columns of table "indexer" */
export enum indexer_select_column {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  instance = "instance",
  /** column name */
  round = "round",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "indexer" */
export interface indexer_set_input {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  instance?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** update columns of table "indexer" */
export enum indexer_update_column {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  instance = "instance",
  /** column name */
  round = "round",
  /** column name */
  updated_at = "updated_at",
}

/** column ordering options */
export enum order_by {
  /** in ascending order, nulls last */
  asc = "asc",
  /** in ascending order, nulls first */
  asc_nulls_first = "asc_nulls_first",
  /** in ascending order, nulls last */
  asc_nulls_last = "asc_nulls_last",
  /** in descending order, nulls first */
  desc = "desc",
  /** in descending order, nulls first */
  desc_nulls_first = "desc_nulls_first",
  /** in descending order, nulls last */
  desc_nulls_last = "desc_nulls_last",
}

/** Boolean expression to filter rows from the table "question". All fields are combined with a logical 'AND'. */
export interface question_bool_exp {
  _and?: InputMaybe<Array<question_bool_exp>>;
  _not?: InputMaybe<question_bool_exp>;
  _or?: InputMaybe<Array<question_bool_exp>>;
  asa?: InputMaybe<bigint_comparison_exp>;
  category?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  encryption_address?: InputMaybe<String_comparison_exp>;
  ending_round?: InputMaybe<bigint_comparison_exp>;
  env?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  json?: InputMaybe<String_comparison_exp>;
  open_from_round?: InputMaybe<bigint_comparison_exp>;
  open_from_time?: InputMaybe<timestamptz_comparison_exp>;
  questioner?: InputMaybe<String_comparison_exp>;
  text?: InputMaybe<String_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  token?: InputMaybe<Int_comparison_exp>;
  tx?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
}

/** unique or primary key constraints on table "question" */
export enum question_constraint {
  /** unique or primary key constraint */
  question_pkey = "question_pkey",
  /** unique or primary key constraint */
  question_tx_key = "question_tx_key",
}

/** input type for incrementing numeric columns in table "question" */
export interface question_inc_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  ending_round?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
  open_from_round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
}

/** input type for inserting data into table "question" */
export interface question_insert_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  category?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  encryption_address?: InputMaybe<Scalars["String"]>;
  ending_round?: InputMaybe<Scalars["bigint"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  open_from_round?: InputMaybe<Scalars["bigint"]>;
  open_from_time?: InputMaybe<Scalars["timestamptz"]>;
  questioner?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  url?: InputMaybe<Scalars["String"]>;
}

/** on_conflict condition type for table "question" */
export interface question_on_conflict {
  constraint: question_constraint;
  update_columns?: Array<question_update_column>;
  where?: InputMaybe<question_bool_exp>;
}

/** Ordering options when selecting data from "question". */
export interface question_order_by {
  asa?: InputMaybe<order_by>;
  category?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  encryption_address?: InputMaybe<order_by>;
  ending_round?: InputMaybe<order_by>;
  env?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  json?: InputMaybe<order_by>;
  open_from_round?: InputMaybe<order_by>;
  open_from_time?: InputMaybe<order_by>;
  questioner?: InputMaybe<order_by>;
  text?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  tx?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
}

/** primary key columns input for table: question */
export interface question_pk_columns_input {
  id: Scalars["Int"];
}

/** select columns of table "question" */
export enum question_select_column {
  /** column name */
  asa = "asa",
  /** column name */
  category = "category",
  /** column name */
  created_at = "created_at",
  /** column name */
  encryption_address = "encryption_address",
  /** column name */
  ending_round = "ending_round",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  open_from_round = "open_from_round",
  /** column name */
  open_from_time = "open_from_time",
  /** column name */
  questioner = "questioner",
  /** column name */
  text = "text",
  /** column name */
  title = "title",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  url = "url",
}

/** input type for updating data in table "question" */
export interface question_set_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  category?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  encryption_address?: InputMaybe<Scalars["String"]>;
  ending_round?: InputMaybe<Scalars["bigint"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  open_from_round?: InputMaybe<Scalars["bigint"]>;
  open_from_time?: InputMaybe<Scalars["timestamptz"]>;
  questioner?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  url?: InputMaybe<Scalars["String"]>;
}

/** update columns of table "question" */
export enum question_update_column {
  /** column name */
  asa = "asa",
  /** column name */
  category = "category",
  /** column name */
  created_at = "created_at",
  /** column name */
  encryption_address = "encryption_address",
  /** column name */
  ending_round = "ending_round",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  open_from_round = "open_from_round",
  /** column name */
  open_from_time = "open_from_time",
  /** column name */
  questioner = "questioner",
  /** column name */
  text = "text",
  /** column name */
  title = "title",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  url = "url",
}

/** Boolean expression to filter rows from the table "result". All fields are combined with a logical 'AND'. */
export interface result_bool_exp {
  _and?: InputMaybe<Array<result_bool_exp>>;
  _not?: InputMaybe<result_bool_exp>;
  _or?: InputMaybe<Array<result_bool_exp>>;
  account?: InputMaybe<String_comparison_exp>;
  asa?: InputMaybe<bigint_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  encryption_key?: InputMaybe<String_comparison_exp>;
  env?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  json?: InputMaybe<String_comparison_exp>;
  questiontx?: InputMaybe<String_comparison_exp>;
  round?: InputMaybe<bigint_comparison_exp>;
  token?: InputMaybe<Int_comparison_exp>;
  tx?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
}

/** unique or primary key constraints on table "result" */
export enum result_constraint {
  /** unique or primary key constraint */
  result_pkey = "result_pkey",
  /** unique or primary key constraint */
  result_tx_key = "result_tx_key",
}

/** input type for incrementing numeric columns in table "result" */
export interface result_inc_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
}

/** input type for inserting data into table "result" */
export interface result_insert_input {
  account?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  encryption_key?: InputMaybe<Scalars["String"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  questiontx?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** on_conflict condition type for table "result" */
export interface result_on_conflict {
  constraint: result_constraint;
  update_columns?: Array<result_update_column>;
  where?: InputMaybe<result_bool_exp>;
}

/** Ordering options when selecting data from "result". */
export interface result_order_by {
  account?: InputMaybe<order_by>;
  asa?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  encryption_key?: InputMaybe<order_by>;
  env?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  json?: InputMaybe<order_by>;
  questiontx?: InputMaybe<order_by>;
  round?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  tx?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
}

/** primary key columns input for table: result */
export interface result_pk_columns_input {
  id: Scalars["Int"];
}

/** select columns of table "result" */
export enum result_select_column {
  /** column name */
  account = "account",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  encryption_key = "encryption_key",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  questiontx = "questiontx",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "result" */
export interface result_set_input {
  account?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  encryption_key?: InputMaybe<Scalars["String"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  questiontx?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** update columns of table "result" */
export enum result_update_column {
  /** column name */
  account = "account",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  encryption_key = "encryption_key",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  questiontx = "questiontx",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface timestamptz_comparison_exp {
  _eq?: InputMaybe<Scalars["timestamptz"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]>>;
}

/** Boolean expression to filter rows from the table "token". All fields are combined with a logical 'AND'. */
export interface token_bool_exp {
  _and?: InputMaybe<Array<token_bool_exp>>;
  _not?: InputMaybe<token_bool_exp>;
  _or?: InputMaybe<Array<token_bool_exp>>;
  asa?: InputMaybe<bigint_comparison_exp>;
  clawback?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  created_at_round?: InputMaybe<bigint_comparison_exp>;
  creator?: InputMaybe<String_comparison_exp>;
  decimals?: InputMaybe<Int_comparison_exp>;
  env?: InputMaybe<String_comparison_exp>;
  freeze?: InputMaybe<String_comparison_exp>;
  icon?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  manager?: InputMaybe<String_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  reserve?: InputMaybe<String_comparison_exp>;
  total?: InputMaybe<bigint_comparison_exp>;
  unit?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
}

/** unique or primary key constraints on table "token" */
export enum token_constraint {
  /** unique or primary key constraint */
  token_asa_env_key = "token_asa_env_key",
  /** unique or primary key constraint */
  token_pkey = "token_pkey",
}

/** input type for incrementing numeric columns in table "token" */
export interface token_inc_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at_round?: InputMaybe<Scalars["bigint"]>;
  decimals?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  total?: InputMaybe<Scalars["bigint"]>;
}

/** input type for inserting data into table "token" */
export interface token_insert_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  clawback?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_at_round?: InputMaybe<Scalars["bigint"]>;
  creator?: InputMaybe<Scalars["String"]>;
  decimals?: InputMaybe<Scalars["Int"]>;
  env?: InputMaybe<Scalars["String"]>;
  freeze?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  manager?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  reserve?: InputMaybe<Scalars["String"]>;
  total?: InputMaybe<Scalars["bigint"]>;
  unit?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  url?: InputMaybe<Scalars["String"]>;
}

/** on_conflict condition type for table "token" */
export interface token_on_conflict {
  constraint: token_constraint;
  update_columns?: Array<token_update_column>;
  where?: InputMaybe<token_bool_exp>;
}

/** Ordering options when selecting data from "token". */
export interface token_order_by {
  asa?: InputMaybe<order_by>;
  clawback?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_at_round?: InputMaybe<order_by>;
  creator?: InputMaybe<order_by>;
  decimals?: InputMaybe<order_by>;
  env?: InputMaybe<order_by>;
  freeze?: InputMaybe<order_by>;
  icon?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  manager?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  reserve?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
  unit?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
}

/** primary key columns input for table: token */
export interface token_pk_columns_input {
  id: Scalars["Int"];
}

/** select columns of table "token" */
export enum token_select_column {
  /** column name */
  asa = "asa",
  /** column name */
  clawback = "clawback",
  /** column name */
  created_at = "created_at",
  /** column name */
  created_at_round = "created_at_round",
  /** column name */
  creator = "creator",
  /** column name */
  decimals = "decimals",
  /** column name */
  env = "env",
  /** column name */
  freeze = "freeze",
  /** column name */
  icon = "icon",
  /** column name */
  id = "id",
  /** column name */
  manager = "manager",
  /** column name */
  name = "name",
  /** column name */
  reserve = "reserve",
  /** column name */
  total = "total",
  /** column name */
  unit = "unit",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  url = "url",
}

/** input type for updating data in table "token" */
export interface token_set_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  clawback?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_at_round?: InputMaybe<Scalars["bigint"]>;
  creator?: InputMaybe<Scalars["String"]>;
  decimals?: InputMaybe<Scalars["Int"]>;
  env?: InputMaybe<Scalars["String"]>;
  freeze?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  manager?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  reserve?: InputMaybe<Scalars["String"]>;
  total?: InputMaybe<Scalars["bigint"]>;
  unit?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  url?: InputMaybe<Scalars["String"]>;
}

/** update columns of table "token" */
export enum token_update_column {
  /** column name */
  asa = "asa",
  /** column name */
  clawback = "clawback",
  /** column name */
  created_at = "created_at",
  /** column name */
  created_at_round = "created_at_round",
  /** column name */
  creator = "creator",
  /** column name */
  decimals = "decimals",
  /** column name */
  env = "env",
  /** column name */
  freeze = "freeze",
  /** column name */
  icon = "icon",
  /** column name */
  id = "id",
  /** column name */
  manager = "manager",
  /** column name */
  name = "name",
  /** column name */
  reserve = "reserve",
  /** column name */
  total = "total",
  /** column name */
  unit = "unit",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  url = "url",
}

/** Boolean expression to filter rows from the table "trusted_list". All fields are combined with a logical 'AND'. */
export interface trusted_list_bool_exp {
  _and?: InputMaybe<Array<trusted_list_bool_exp>>;
  _not?: InputMaybe<trusted_list_bool_exp>;
  _or?: InputMaybe<Array<trusted_list_bool_exp>>;
  account?: InputMaybe<String_comparison_exp>;
  asa?: InputMaybe<bigint_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  env?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  json?: InputMaybe<String_comparison_exp>;
  round?: InputMaybe<bigint_comparison_exp>;
  token?: InputMaybe<Int_comparison_exp>;
  tx?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
}

/** unique or primary key constraints on table "trusted_list" */
export enum trusted_list_constraint {
  /** unique or primary key constraint */
  trusted_list_pkey = "trusted_list_pkey",
  /** unique or primary key constraint */
  trusted_list_tx_key = "trusted_list_tx_key",
}

/** input type for incrementing numeric columns in table "trusted_list" */
export interface trusted_list_inc_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
}

/** input type for inserting data into table "trusted_list" */
export interface trusted_list_insert_input {
  account?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** on_conflict condition type for table "trusted_list" */
export interface trusted_list_on_conflict {
  constraint: trusted_list_constraint;
  update_columns?: Array<trusted_list_update_column>;
  where?: InputMaybe<trusted_list_bool_exp>;
}

/** Ordering options when selecting data from "trusted_list". */
export interface trusted_list_order_by {
  account?: InputMaybe<order_by>;
  asa?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  env?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  json?: InputMaybe<order_by>;
  round?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  tx?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
}

/** primary key columns input for table: trusted_list */
export interface trusted_list_pk_columns_input {
  id: Scalars["Int"];
}

/** select columns of table "trusted_list" */
export enum trusted_list_select_column {
  /** column name */
  account = "account",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "trusted_list" */
export interface trusted_list_set_input {
  account?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** update columns of table "trusted_list" */
export enum trusted_list_update_column {
  /** column name */
  account = "account",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

/** Boolean expression to filter rows from the table "vote". All fields are combined with a logical 'AND'. */
export interface vote_bool_exp {
  _and?: InputMaybe<Array<vote_bool_exp>>;
  _not?: InputMaybe<vote_bool_exp>;
  _or?: InputMaybe<Array<vote_bool_exp>>;
  address?: InputMaybe<String_comparison_exp>;
  asa?: InputMaybe<bigint_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  env?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  json?: InputMaybe<String_comparison_exp>;
  questiontx?: InputMaybe<String_comparison_exp>;
  round?: InputMaybe<bigint_comparison_exp>;
  token?: InputMaybe<Int_comparison_exp>;
  tx?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
}

/** unique or primary key constraints on table "vote" */
export enum vote_constraint {
  /** unique or primary key constraint */
  vote_pkey = "vote_pkey",
  /** unique or primary key constraint */
  vote_tx_key = "vote_tx_key",
}

/** input type for incrementing numeric columns in table "vote" */
export interface vote_inc_input {
  asa?: InputMaybe<Scalars["bigint"]>;
  id?: InputMaybe<Scalars["Int"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
}

/** input type for inserting data into table "vote" */
export interface vote_insert_input {
  address?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  questiontx?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** on_conflict condition type for table "vote" */
export interface vote_on_conflict {
  constraint: vote_constraint;
  update_columns?: Array<vote_update_column>;
  where?: InputMaybe<vote_bool_exp>;
}

/** Ordering options when selecting data from "vote". */
export interface vote_order_by {
  address?: InputMaybe<order_by>;
  asa?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  env?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  json?: InputMaybe<order_by>;
  questiontx?: InputMaybe<order_by>;
  round?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  tx?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
}

/** primary key columns input for table: vote */
export interface vote_pk_columns_input {
  id: Scalars["Int"];
}

/** select columns of table "vote" */
export enum vote_select_column {
  /** column name */
  address = "address",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  questiontx = "questiontx",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "vote" */
export interface vote_set_input {
  address?: InputMaybe<Scalars["String"]>;
  asa?: InputMaybe<Scalars["bigint"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  env?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  json?: InputMaybe<Scalars["String"]>;
  questiontx?: InputMaybe<Scalars["String"]>;
  round?: InputMaybe<Scalars["bigint"]>;
  token?: InputMaybe<Scalars["Int"]>;
  tx?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
}

/** update columns of table "vote" */
export enum vote_update_column {
  /** column name */
  address = "address",
  /** column name */
  asa = "asa",
  /** column name */
  created_at = "created_at",
  /** column name */
  env = "env",
  /** column name */
  id = "id",
  /** column name */
  json = "json",
  /** column name */
  questiontx = "questiontx",
  /** column name */
  round = "round",
  /** column name */
  token = "token",
  /** column name */
  tx = "tx",
  /** column name */
  updated_at = "updated_at",
}

export const scalarsEnumsHash: import("gqty").ScalarsEnumsHash = {
  Boolean: true,
  Float: true,
  Int: true,
  String: true,
  bigint: true,
  delegation_constraint: true,
  delegation_select_column: true,
  delegation_update_column: true,
  etg_constraint: true,
  etg_select_column: true,
  etg_update_column: true,
  indexer_constraint: true,
  indexer_select_column: true,
  indexer_update_column: true,
  order_by: true,
  question_constraint: true,
  question_select_column: true,
  question_update_column: true,
  result_constraint: true,
  result_select_column: true,
  result_update_column: true,
  timestamptz: true,
  token_constraint: true,
  token_select_column: true,
  token_update_column: true,
  trusted_list_constraint: true,
  trusted_list_select_column: true,
  trusted_list_update_column: true,
  vote_constraint: true,
  vote_select_column: true,
  vote_update_column: true,
};
export const generatedSchema = {
  Int_comparison_exp: {
    _eq: { __type: "Int" },
    _gt: { __type: "Int" },
    _gte: { __type: "Int" },
    _in: { __type: "[Int!]" },
    _is_null: { __type: "Boolean" },
    _lt: { __type: "Int" },
    _lte: { __type: "Int" },
    _neq: { __type: "Int" },
    _nin: { __type: "[Int!]" },
  },
  String_comparison_exp: {
    _eq: { __type: "String" },
    _gt: { __type: "String" },
    _gte: { __type: "String" },
    _ilike: { __type: "String" },
    _in: { __type: "[String!]" },
    _iregex: { __type: "String" },
    _is_null: { __type: "Boolean" },
    _like: { __type: "String" },
    _lt: { __type: "String" },
    _lte: { __type: "String" },
    _neq: { __type: "String" },
    _nilike: { __type: "String" },
    _nin: { __type: "[String!]" },
    _niregex: { __type: "String" },
    _nlike: { __type: "String" },
    _nregex: { __type: "String" },
    _nsimilar: { __type: "String" },
    _regex: { __type: "String" },
    _similar: { __type: "String" },
  },
  bigint_comparison_exp: {
    _eq: { __type: "bigint" },
    _gt: { __type: "bigint" },
    _gte: { __type: "bigint" },
    _in: { __type: "[bigint!]" },
    _is_null: { __type: "Boolean" },
    _lt: { __type: "bigint" },
    _lte: { __type: "bigint" },
    _neq: { __type: "bigint" },
    _nin: { __type: "[bigint!]" },
  },
  delegation: {
    __typename: { __type: "String!" },
    account: { __type: "String!" },
    asa: { __type: "bigint!" },
    created_at: { __type: "timestamptz!" },
    env: { __type: "String!" },
    id: { __type: "Int!" },
    json: { __type: "String!" },
    round: { __type: "bigint!" },
    token: { __type: "Int!" },
    tx: { __type: "String!" },
    updated_at: { __type: "timestamptz!" },
  },
  delegation_aggregate: {
    __typename: { __type: "String!" },
    aggregate: { __type: "delegation_aggregate_fields" },
    nodes: { __type: "[delegation!]!" },
  },
  delegation_aggregate_fields: {
    __typename: { __type: "String!" },
    avg: { __type: "delegation_avg_fields" },
    count: {
      __type: "Int!",
      __args: { columns: "[delegation_select_column!]", distinct: "Boolean" },
    },
    max: { __type: "delegation_max_fields" },
    min: { __type: "delegation_min_fields" },
    stddev: { __type: "delegation_stddev_fields" },
    stddev_pop: { __type: "delegation_stddev_pop_fields" },
    stddev_samp: { __type: "delegation_stddev_samp_fields" },
    sum: { __type: "delegation_sum_fields" },
    var_pop: { __type: "delegation_var_pop_fields" },
    var_samp: { __type: "delegation_var_samp_fields" },
    variance: { __type: "delegation_variance_fields" },
  },
  delegation_avg_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  delegation_bool_exp: {
    _and: { __type: "[delegation_bool_exp!]" },
    _not: { __type: "delegation_bool_exp" },
    _or: { __type: "[delegation_bool_exp!]" },
    account: { __type: "String_comparison_exp" },
    asa: { __type: "bigint_comparison_exp" },
    created_at: { __type: "timestamptz_comparison_exp" },
    env: { __type: "String_comparison_exp" },
    id: { __type: "Int_comparison_exp" },
    json: { __type: "String_comparison_exp" },
    round: { __type: "bigint_comparison_exp" },
    token: { __type: "Int_comparison_exp" },
    tx: { __type: "String_comparison_exp" },
    updated_at: { __type: "timestamptz_comparison_exp" },
  },
  delegation_inc_input: {
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  delegation_insert_input: {
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  delegation_max_fields: {
    __typename: { __type: "String!" },
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  delegation_min_fields: {
    __typename: { __type: "String!" },
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  delegation_mutation_response: {
    __typename: { __type: "String!" },
    affected_rows: { __type: "Int!" },
    returning: { __type: "[delegation!]!" },
  },
  delegation_on_conflict: {
    constraint: { __type: "delegation_constraint!" },
    update_columns: { __type: "[delegation_update_column!]!" },
    where: { __type: "delegation_bool_exp" },
  },
  delegation_order_by: {
    account: { __type: "order_by" },
    asa: { __type: "order_by" },
    created_at: { __type: "order_by" },
    env: { __type: "order_by" },
    id: { __type: "order_by" },
    json: { __type: "order_by" },
    round: { __type: "order_by" },
    token: { __type: "order_by" },
    tx: { __type: "order_by" },
    updated_at: { __type: "order_by" },
  },
  delegation_pk_columns_input: { id: { __type: "Int!" } },
  delegation_set_input: {
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  delegation_stddev_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  delegation_stddev_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  delegation_stddev_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  delegation_sum_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  delegation_var_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  delegation_var_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  delegation_variance_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  etg: {
    __typename: { __type: "String!" },
    account: { __type: "String!" },
    asa: { __type: "bigint!" },
    created_at: { __type: "timestamptz!" },
    env: { __type: "String!" },
    id: { __type: "Int!" },
    json: { __type: "String!" },
    round: { __type: "bigint!" },
    token: { __type: "Int!" },
    tx: { __type: "String!" },
    updated_at: { __type: "timestamptz!" },
  },
  etg_aggregate: {
    __typename: { __type: "String!" },
    aggregate: { __type: "etg_aggregate_fields" },
    nodes: { __type: "[etg!]!" },
  },
  etg_aggregate_fields: {
    __typename: { __type: "String!" },
    avg: { __type: "etg_avg_fields" },
    count: {
      __type: "Int!",
      __args: { columns: "[etg_select_column!]", distinct: "Boolean" },
    },
    max: { __type: "etg_max_fields" },
    min: { __type: "etg_min_fields" },
    stddev: { __type: "etg_stddev_fields" },
    stddev_pop: { __type: "etg_stddev_pop_fields" },
    stddev_samp: { __type: "etg_stddev_samp_fields" },
    sum: { __type: "etg_sum_fields" },
    var_pop: { __type: "etg_var_pop_fields" },
    var_samp: { __type: "etg_var_samp_fields" },
    variance: { __type: "etg_variance_fields" },
  },
  etg_avg_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  etg_bool_exp: {
    _and: { __type: "[etg_bool_exp!]" },
    _not: { __type: "etg_bool_exp" },
    _or: { __type: "[etg_bool_exp!]" },
    account: { __type: "String_comparison_exp" },
    asa: { __type: "bigint_comparison_exp" },
    created_at: { __type: "timestamptz_comparison_exp" },
    env: { __type: "String_comparison_exp" },
    id: { __type: "Int_comparison_exp" },
    json: { __type: "String_comparison_exp" },
    round: { __type: "bigint_comparison_exp" },
    token: { __type: "Int_comparison_exp" },
    tx: { __type: "String_comparison_exp" },
    updated_at: { __type: "timestamptz_comparison_exp" },
  },
  etg_inc_input: {
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  etg_insert_input: {
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  etg_max_fields: {
    __typename: { __type: "String!" },
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  etg_min_fields: {
    __typename: { __type: "String!" },
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  etg_mutation_response: {
    __typename: { __type: "String!" },
    affected_rows: { __type: "Int!" },
    returning: { __type: "[etg!]!" },
  },
  etg_on_conflict: {
    constraint: { __type: "etg_constraint!" },
    update_columns: { __type: "[etg_update_column!]!" },
    where: { __type: "etg_bool_exp" },
  },
  etg_order_by: {
    account: { __type: "order_by" },
    asa: { __type: "order_by" },
    created_at: { __type: "order_by" },
    env: { __type: "order_by" },
    id: { __type: "order_by" },
    json: { __type: "order_by" },
    round: { __type: "order_by" },
    token: { __type: "order_by" },
    tx: { __type: "order_by" },
    updated_at: { __type: "order_by" },
  },
  etg_pk_columns_input: { id: { __type: "Int!" } },
  etg_set_input: {
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  etg_stddev_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  etg_stddev_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  etg_stddev_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  etg_sum_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  etg_var_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  etg_var_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  etg_variance_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  indexer: {
    __typename: { __type: "String!" },
    created_at: { __type: "timestamptz!" },
    id: { __type: "Int!" },
    instance: { __type: "String!" },
    round: { __type: "bigint!" },
    updated_at: { __type: "timestamptz!" },
  },
  indexer_aggregate: {
    __typename: { __type: "String!" },
    aggregate: { __type: "indexer_aggregate_fields" },
    nodes: { __type: "[indexer!]!" },
  },
  indexer_aggregate_fields: {
    __typename: { __type: "String!" },
    avg: { __type: "indexer_avg_fields" },
    count: {
      __type: "Int!",
      __args: { columns: "[indexer_select_column!]", distinct: "Boolean" },
    },
    max: { __type: "indexer_max_fields" },
    min: { __type: "indexer_min_fields" },
    stddev: { __type: "indexer_stddev_fields" },
    stddev_pop: { __type: "indexer_stddev_pop_fields" },
    stddev_samp: { __type: "indexer_stddev_samp_fields" },
    sum: { __type: "indexer_sum_fields" },
    var_pop: { __type: "indexer_var_pop_fields" },
    var_samp: { __type: "indexer_var_samp_fields" },
    variance: { __type: "indexer_variance_fields" },
  },
  indexer_avg_fields: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    round: { __type: "Float" },
  },
  indexer_bool_exp: {
    _and: { __type: "[indexer_bool_exp!]" },
    _not: { __type: "indexer_bool_exp" },
    _or: { __type: "[indexer_bool_exp!]" },
    created_at: { __type: "timestamptz_comparison_exp" },
    id: { __type: "Int_comparison_exp" },
    instance: { __type: "String_comparison_exp" },
    round: { __type: "bigint_comparison_exp" },
    updated_at: { __type: "timestamptz_comparison_exp" },
  },
  indexer_inc_input: { id: { __type: "Int" }, round: { __type: "bigint" } },
  indexer_insert_input: {
    created_at: { __type: "timestamptz" },
    id: { __type: "Int" },
    instance: { __type: "String" },
    round: { __type: "bigint" },
    updated_at: { __type: "timestamptz" },
  },
  indexer_max_fields: {
    __typename: { __type: "String!" },
    created_at: { __type: "timestamptz" },
    id: { __type: "Int" },
    instance: { __type: "String" },
    round: { __type: "bigint" },
    updated_at: { __type: "timestamptz" },
  },
  indexer_min_fields: {
    __typename: { __type: "String!" },
    created_at: { __type: "timestamptz" },
    id: { __type: "Int" },
    instance: { __type: "String" },
    round: { __type: "bigint" },
    updated_at: { __type: "timestamptz" },
  },
  indexer_mutation_response: {
    __typename: { __type: "String!" },
    affected_rows: { __type: "Int!" },
    returning: { __type: "[indexer!]!" },
  },
  indexer_on_conflict: {
    constraint: { __type: "indexer_constraint!" },
    update_columns: { __type: "[indexer_update_column!]!" },
    where: { __type: "indexer_bool_exp" },
  },
  indexer_order_by: {
    created_at: { __type: "order_by" },
    id: { __type: "order_by" },
    instance: { __type: "order_by" },
    round: { __type: "order_by" },
    updated_at: { __type: "order_by" },
  },
  indexer_pk_columns_input: { id: { __type: "Int!" } },
  indexer_set_input: {
    created_at: { __type: "timestamptz" },
    id: { __type: "Int" },
    instance: { __type: "String" },
    round: { __type: "bigint" },
    updated_at: { __type: "timestamptz" },
  },
  indexer_stddev_fields: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    round: { __type: "Float" },
  },
  indexer_stddev_pop_fields: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    round: { __type: "Float" },
  },
  indexer_stddev_samp_fields: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    round: { __type: "Float" },
  },
  indexer_sum_fields: {
    __typename: { __type: "String!" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
  },
  indexer_var_pop_fields: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    round: { __type: "Float" },
  },
  indexer_var_samp_fields: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    round: { __type: "Float" },
  },
  indexer_variance_fields: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    round: { __type: "Float" },
  },
  mutation: {
    __typename: { __type: "String!" },
    delete_delegation: {
      __type: "delegation_mutation_response",
      __args: { where: "delegation_bool_exp!" },
    },
    delete_delegation_by_pk: { __type: "delegation", __args: { id: "Int!" } },
    delete_etg: {
      __type: "etg_mutation_response",
      __args: { where: "etg_bool_exp!" },
    },
    delete_etg_by_pk: { __type: "etg", __args: { id: "Int!" } },
    delete_indexer: {
      __type: "indexer_mutation_response",
      __args: { where: "indexer_bool_exp!" },
    },
    delete_indexer_by_pk: { __type: "indexer", __args: { id: "Int!" } },
    delete_question: {
      __type: "question_mutation_response",
      __args: { where: "question_bool_exp!" },
    },
    delete_question_by_pk: { __type: "question", __args: { id: "Int!" } },
    delete_result: {
      __type: "result_mutation_response",
      __args: { where: "result_bool_exp!" },
    },
    delete_result_by_pk: { __type: "result", __args: { id: "Int!" } },
    delete_token: {
      __type: "token_mutation_response",
      __args: { where: "token_bool_exp!" },
    },
    delete_token_by_pk: { __type: "token", __args: { id: "Int!" } },
    delete_trusted_list: {
      __type: "trusted_list_mutation_response",
      __args: { where: "trusted_list_bool_exp!" },
    },
    delete_trusted_list_by_pk: {
      __type: "trusted_list",
      __args: { id: "Int!" },
    },
    delete_vote: {
      __type: "vote_mutation_response",
      __args: { where: "vote_bool_exp!" },
    },
    delete_vote_by_pk: { __type: "vote", __args: { id: "Int!" } },
    insert_delegation: {
      __type: "delegation_mutation_response",
      __args: {
        objects: "[delegation_insert_input!]!",
        on_conflict: "delegation_on_conflict",
      },
    },
    insert_delegation_one: {
      __type: "delegation",
      __args: {
        object: "delegation_insert_input!",
        on_conflict: "delegation_on_conflict",
      },
    },
    insert_etg: {
      __type: "etg_mutation_response",
      __args: {
        objects: "[etg_insert_input!]!",
        on_conflict: "etg_on_conflict",
      },
    },
    insert_etg_one: {
      __type: "etg",
      __args: { object: "etg_insert_input!", on_conflict: "etg_on_conflict" },
    },
    insert_indexer: {
      __type: "indexer_mutation_response",
      __args: {
        objects: "[indexer_insert_input!]!",
        on_conflict: "indexer_on_conflict",
      },
    },
    insert_indexer_one: {
      __type: "indexer",
      __args: {
        object: "indexer_insert_input!",
        on_conflict: "indexer_on_conflict",
      },
    },
    insert_question: {
      __type: "question_mutation_response",
      __args: {
        objects: "[question_insert_input!]!",
        on_conflict: "question_on_conflict",
      },
    },
    insert_question_one: {
      __type: "question",
      __args: {
        object: "question_insert_input!",
        on_conflict: "question_on_conflict",
      },
    },
    insert_result: {
      __type: "result_mutation_response",
      __args: {
        objects: "[result_insert_input!]!",
        on_conflict: "result_on_conflict",
      },
    },
    insert_result_one: {
      __type: "result",
      __args: {
        object: "result_insert_input!",
        on_conflict: "result_on_conflict",
      },
    },
    insert_token: {
      __type: "token_mutation_response",
      __args: {
        objects: "[token_insert_input!]!",
        on_conflict: "token_on_conflict",
      },
    },
    insert_token_one: {
      __type: "token",
      __args: {
        object: "token_insert_input!",
        on_conflict: "token_on_conflict",
      },
    },
    insert_trusted_list: {
      __type: "trusted_list_mutation_response",
      __args: {
        objects: "[trusted_list_insert_input!]!",
        on_conflict: "trusted_list_on_conflict",
      },
    },
    insert_trusted_list_one: {
      __type: "trusted_list",
      __args: {
        object: "trusted_list_insert_input!",
        on_conflict: "trusted_list_on_conflict",
      },
    },
    insert_vote: {
      __type: "vote_mutation_response",
      __args: {
        objects: "[vote_insert_input!]!",
        on_conflict: "vote_on_conflict",
      },
    },
    insert_vote_one: {
      __type: "vote",
      __args: { object: "vote_insert_input!", on_conflict: "vote_on_conflict" },
    },
    update_delegation: {
      __type: "delegation_mutation_response",
      __args: {
        _inc: "delegation_inc_input",
        _set: "delegation_set_input",
        where: "delegation_bool_exp!",
      },
    },
    update_delegation_by_pk: {
      __type: "delegation",
      __args: {
        _inc: "delegation_inc_input",
        _set: "delegation_set_input",
        pk_columns: "delegation_pk_columns_input!",
      },
    },
    update_etg: {
      __type: "etg_mutation_response",
      __args: {
        _inc: "etg_inc_input",
        _set: "etg_set_input",
        where: "etg_bool_exp!",
      },
    },
    update_etg_by_pk: {
      __type: "etg",
      __args: {
        _inc: "etg_inc_input",
        _set: "etg_set_input",
        pk_columns: "etg_pk_columns_input!",
      },
    },
    update_indexer: {
      __type: "indexer_mutation_response",
      __args: {
        _inc: "indexer_inc_input",
        _set: "indexer_set_input",
        where: "indexer_bool_exp!",
      },
    },
    update_indexer_by_pk: {
      __type: "indexer",
      __args: {
        _inc: "indexer_inc_input",
        _set: "indexer_set_input",
        pk_columns: "indexer_pk_columns_input!",
      },
    },
    update_question: {
      __type: "question_mutation_response",
      __args: {
        _inc: "question_inc_input",
        _set: "question_set_input",
        where: "question_bool_exp!",
      },
    },
    update_question_by_pk: {
      __type: "question",
      __args: {
        _inc: "question_inc_input",
        _set: "question_set_input",
        pk_columns: "question_pk_columns_input!",
      },
    },
    update_result: {
      __type: "result_mutation_response",
      __args: {
        _inc: "result_inc_input",
        _set: "result_set_input",
        where: "result_bool_exp!",
      },
    },
    update_result_by_pk: {
      __type: "result",
      __args: {
        _inc: "result_inc_input",
        _set: "result_set_input",
        pk_columns: "result_pk_columns_input!",
      },
    },
    update_token: {
      __type: "token_mutation_response",
      __args: {
        _inc: "token_inc_input",
        _set: "token_set_input",
        where: "token_bool_exp!",
      },
    },
    update_token_by_pk: {
      __type: "token",
      __args: {
        _inc: "token_inc_input",
        _set: "token_set_input",
        pk_columns: "token_pk_columns_input!",
      },
    },
    update_trusted_list: {
      __type: "trusted_list_mutation_response",
      __args: {
        _inc: "trusted_list_inc_input",
        _set: "trusted_list_set_input",
        where: "trusted_list_bool_exp!",
      },
    },
    update_trusted_list_by_pk: {
      __type: "trusted_list",
      __args: {
        _inc: "trusted_list_inc_input",
        _set: "trusted_list_set_input",
        pk_columns: "trusted_list_pk_columns_input!",
      },
    },
    update_vote: {
      __type: "vote_mutation_response",
      __args: {
        _inc: "vote_inc_input",
        _set: "vote_set_input",
        where: "vote_bool_exp!",
      },
    },
    update_vote_by_pk: {
      __type: "vote",
      __args: {
        _inc: "vote_inc_input",
        _set: "vote_set_input",
        pk_columns: "vote_pk_columns_input!",
      },
    },
  },
  query: {
    __typename: { __type: "String!" },
    delegation: {
      __type: "[delegation!]!",
      __args: {
        distinct_on: "[delegation_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[delegation_order_by!]",
        where: "delegation_bool_exp",
      },
    },
    delegation_aggregate: {
      __type: "delegation_aggregate!",
      __args: {
        distinct_on: "[delegation_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[delegation_order_by!]",
        where: "delegation_bool_exp",
      },
    },
    delegation_by_pk: { __type: "delegation", __args: { id: "Int!" } },
    etg: {
      __type: "[etg!]!",
      __args: {
        distinct_on: "[etg_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[etg_order_by!]",
        where: "etg_bool_exp",
      },
    },
    etg_aggregate: {
      __type: "etg_aggregate!",
      __args: {
        distinct_on: "[etg_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[etg_order_by!]",
        where: "etg_bool_exp",
      },
    },
    etg_by_pk: { __type: "etg", __args: { id: "Int!" } },
    indexer: {
      __type: "[indexer!]!",
      __args: {
        distinct_on: "[indexer_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[indexer_order_by!]",
        where: "indexer_bool_exp",
      },
    },
    indexer_aggregate: {
      __type: "indexer_aggregate!",
      __args: {
        distinct_on: "[indexer_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[indexer_order_by!]",
        where: "indexer_bool_exp",
      },
    },
    indexer_by_pk: { __type: "indexer", __args: { id: "Int!" } },
    question: {
      __type: "[question!]!",
      __args: {
        distinct_on: "[question_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[question_order_by!]",
        where: "question_bool_exp",
      },
    },
    question_aggregate: {
      __type: "question_aggregate!",
      __args: {
        distinct_on: "[question_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[question_order_by!]",
        where: "question_bool_exp",
      },
    },
    question_by_pk: { __type: "question", __args: { id: "Int!" } },
    result: {
      __type: "[result!]!",
      __args: {
        distinct_on: "[result_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[result_order_by!]",
        where: "result_bool_exp",
      },
    },
    result_aggregate: {
      __type: "result_aggregate!",
      __args: {
        distinct_on: "[result_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[result_order_by!]",
        where: "result_bool_exp",
      },
    },
    result_by_pk: { __type: "result", __args: { id: "Int!" } },
    token: {
      __type: "[token!]!",
      __args: {
        distinct_on: "[token_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[token_order_by!]",
        where: "token_bool_exp",
      },
    },
    token_aggregate: {
      __type: "token_aggregate!",
      __args: {
        distinct_on: "[token_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[token_order_by!]",
        where: "token_bool_exp",
      },
    },
    token_by_pk: { __type: "token", __args: { id: "Int!" } },
    trusted_list: {
      __type: "[trusted_list!]!",
      __args: {
        distinct_on: "[trusted_list_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[trusted_list_order_by!]",
        where: "trusted_list_bool_exp",
      },
    },
    trusted_list_aggregate: {
      __type: "trusted_list_aggregate!",
      __args: {
        distinct_on: "[trusted_list_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[trusted_list_order_by!]",
        where: "trusted_list_bool_exp",
      },
    },
    trusted_list_by_pk: { __type: "trusted_list", __args: { id: "Int!" } },
    vote: {
      __type: "[vote!]!",
      __args: {
        distinct_on: "[vote_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[vote_order_by!]",
        where: "vote_bool_exp",
      },
    },
    vote_aggregate: {
      __type: "vote_aggregate!",
      __args: {
        distinct_on: "[vote_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[vote_order_by!]",
        where: "vote_bool_exp",
      },
    },
    vote_by_pk: { __type: "vote", __args: { id: "Int!" } },
  },
  question: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint!" },
    category: { __type: "String!" },
    created_at: { __type: "timestamptz!" },
    encryption_address: { __type: "String!" },
    ending_round: { __type: "bigint!" },
    env: { __type: "String!" },
    id: { __type: "Int!" },
    json: { __type: "String!" },
    open_from_round: { __type: "bigint!" },
    open_from_time: { __type: "timestamptz!" },
    questioner: { __type: "String!" },
    text: { __type: "String!" },
    title: { __type: "String!" },
    token: { __type: "Int!" },
    tx: { __type: "String!" },
    updated_at: { __type: "timestamptz!" },
    url: { __type: "String!" },
  },
  question_aggregate: {
    __typename: { __type: "String!" },
    aggregate: { __type: "question_aggregate_fields" },
    nodes: { __type: "[question!]!" },
  },
  question_aggregate_fields: {
    __typename: { __type: "String!" },
    avg: { __type: "question_avg_fields" },
    count: {
      __type: "Int!",
      __args: { columns: "[question_select_column!]", distinct: "Boolean" },
    },
    max: { __type: "question_max_fields" },
    min: { __type: "question_min_fields" },
    stddev: { __type: "question_stddev_fields" },
    stddev_pop: { __type: "question_stddev_pop_fields" },
    stddev_samp: { __type: "question_stddev_samp_fields" },
    sum: { __type: "question_sum_fields" },
    var_pop: { __type: "question_var_pop_fields" },
    var_samp: { __type: "question_var_samp_fields" },
    variance: { __type: "question_variance_fields" },
  },
  question_avg_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    ending_round: { __type: "Float" },
    id: { __type: "Float" },
    open_from_round: { __type: "Float" },
    token: { __type: "Float" },
  },
  question_bool_exp: {
    _and: { __type: "[question_bool_exp!]" },
    _not: { __type: "question_bool_exp" },
    _or: { __type: "[question_bool_exp!]" },
    asa: { __type: "bigint_comparison_exp" },
    category: { __type: "String_comparison_exp" },
    created_at: { __type: "timestamptz_comparison_exp" },
    encryption_address: { __type: "String_comparison_exp" },
    ending_round: { __type: "bigint_comparison_exp" },
    env: { __type: "String_comparison_exp" },
    id: { __type: "Int_comparison_exp" },
    json: { __type: "String_comparison_exp" },
    open_from_round: { __type: "bigint_comparison_exp" },
    open_from_time: { __type: "timestamptz_comparison_exp" },
    questioner: { __type: "String_comparison_exp" },
    text: { __type: "String_comparison_exp" },
    title: { __type: "String_comparison_exp" },
    token: { __type: "Int_comparison_exp" },
    tx: { __type: "String_comparison_exp" },
    updated_at: { __type: "timestamptz_comparison_exp" },
    url: { __type: "String_comparison_exp" },
  },
  question_inc_input: {
    asa: { __type: "bigint" },
    ending_round: { __type: "bigint" },
    id: { __type: "Int" },
    open_from_round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  question_insert_input: {
    asa: { __type: "bigint" },
    category: { __type: "String" },
    created_at: { __type: "timestamptz" },
    encryption_address: { __type: "String" },
    ending_round: { __type: "bigint" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    open_from_round: { __type: "bigint" },
    open_from_time: { __type: "timestamptz" },
    questioner: { __type: "String" },
    text: { __type: "String" },
    title: { __type: "String" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
    url: { __type: "String" },
  },
  question_max_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    category: { __type: "String" },
    created_at: { __type: "timestamptz" },
    encryption_address: { __type: "String" },
    ending_round: { __type: "bigint" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    open_from_round: { __type: "bigint" },
    open_from_time: { __type: "timestamptz" },
    questioner: { __type: "String" },
    text: { __type: "String" },
    title: { __type: "String" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
    url: { __type: "String" },
  },
  question_min_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    category: { __type: "String" },
    created_at: { __type: "timestamptz" },
    encryption_address: { __type: "String" },
    ending_round: { __type: "bigint" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    open_from_round: { __type: "bigint" },
    open_from_time: { __type: "timestamptz" },
    questioner: { __type: "String" },
    text: { __type: "String" },
    title: { __type: "String" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
    url: { __type: "String" },
  },
  question_mutation_response: {
    __typename: { __type: "String!" },
    affected_rows: { __type: "Int!" },
    returning: { __type: "[question!]!" },
  },
  question_on_conflict: {
    constraint: { __type: "question_constraint!" },
    update_columns: { __type: "[question_update_column!]!" },
    where: { __type: "question_bool_exp" },
  },
  question_order_by: {
    asa: { __type: "order_by" },
    category: { __type: "order_by" },
    created_at: { __type: "order_by" },
    encryption_address: { __type: "order_by" },
    ending_round: { __type: "order_by" },
    env: { __type: "order_by" },
    id: { __type: "order_by" },
    json: { __type: "order_by" },
    open_from_round: { __type: "order_by" },
    open_from_time: { __type: "order_by" },
    questioner: { __type: "order_by" },
    text: { __type: "order_by" },
    title: { __type: "order_by" },
    token: { __type: "order_by" },
    tx: { __type: "order_by" },
    updated_at: { __type: "order_by" },
    url: { __type: "order_by" },
  },
  question_pk_columns_input: { id: { __type: "Int!" } },
  question_set_input: {
    asa: { __type: "bigint" },
    category: { __type: "String" },
    created_at: { __type: "timestamptz" },
    encryption_address: { __type: "String" },
    ending_round: { __type: "bigint" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    open_from_round: { __type: "bigint" },
    open_from_time: { __type: "timestamptz" },
    questioner: { __type: "String" },
    text: { __type: "String" },
    title: { __type: "String" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
    url: { __type: "String" },
  },
  question_stddev_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    ending_round: { __type: "Float" },
    id: { __type: "Float" },
    open_from_round: { __type: "Float" },
    token: { __type: "Float" },
  },
  question_stddev_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    ending_round: { __type: "Float" },
    id: { __type: "Float" },
    open_from_round: { __type: "Float" },
    token: { __type: "Float" },
  },
  question_stddev_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    ending_round: { __type: "Float" },
    id: { __type: "Float" },
    open_from_round: { __type: "Float" },
    token: { __type: "Float" },
  },
  question_sum_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    ending_round: { __type: "bigint" },
    id: { __type: "Int" },
    open_from_round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  question_var_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    ending_round: { __type: "Float" },
    id: { __type: "Float" },
    open_from_round: { __type: "Float" },
    token: { __type: "Float" },
  },
  question_var_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    ending_round: { __type: "Float" },
    id: { __type: "Float" },
    open_from_round: { __type: "Float" },
    token: { __type: "Float" },
  },
  question_variance_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    ending_round: { __type: "Float" },
    id: { __type: "Float" },
    open_from_round: { __type: "Float" },
    token: { __type: "Float" },
  },
  result: {
    __typename: { __type: "String!" },
    account: { __type: "String!" },
    asa: { __type: "bigint!" },
    created_at: { __type: "timestamptz!" },
    encryption_key: { __type: "String" },
    env: { __type: "String!" },
    id: { __type: "Int!" },
    json: { __type: "String!" },
    questiontx: { __type: "String!" },
    round: { __type: "bigint!" },
    token: { __type: "Int!" },
    tx: { __type: "String!" },
    updated_at: { __type: "timestamptz!" },
  },
  result_aggregate: {
    __typename: { __type: "String!" },
    aggregate: { __type: "result_aggregate_fields" },
    nodes: { __type: "[result!]!" },
  },
  result_aggregate_fields: {
    __typename: { __type: "String!" },
    avg: { __type: "result_avg_fields" },
    count: {
      __type: "Int!",
      __args: { columns: "[result_select_column!]", distinct: "Boolean" },
    },
    max: { __type: "result_max_fields" },
    min: { __type: "result_min_fields" },
    stddev: { __type: "result_stddev_fields" },
    stddev_pop: { __type: "result_stddev_pop_fields" },
    stddev_samp: { __type: "result_stddev_samp_fields" },
    sum: { __type: "result_sum_fields" },
    var_pop: { __type: "result_var_pop_fields" },
    var_samp: { __type: "result_var_samp_fields" },
    variance: { __type: "result_variance_fields" },
  },
  result_avg_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  result_bool_exp: {
    _and: { __type: "[result_bool_exp!]" },
    _not: { __type: "result_bool_exp" },
    _or: { __type: "[result_bool_exp!]" },
    account: { __type: "String_comparison_exp" },
    asa: { __type: "bigint_comparison_exp" },
    created_at: { __type: "timestamptz_comparison_exp" },
    encryption_key: { __type: "String_comparison_exp" },
    env: { __type: "String_comparison_exp" },
    id: { __type: "Int_comparison_exp" },
    json: { __type: "String_comparison_exp" },
    questiontx: { __type: "String_comparison_exp" },
    round: { __type: "bigint_comparison_exp" },
    token: { __type: "Int_comparison_exp" },
    tx: { __type: "String_comparison_exp" },
    updated_at: { __type: "timestamptz_comparison_exp" },
  },
  result_inc_input: {
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  result_insert_input: {
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    encryption_key: { __type: "String" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    questiontx: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  result_max_fields: {
    __typename: { __type: "String!" },
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    encryption_key: { __type: "String" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    questiontx: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  result_min_fields: {
    __typename: { __type: "String!" },
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    encryption_key: { __type: "String" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    questiontx: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  result_mutation_response: {
    __typename: { __type: "String!" },
    affected_rows: { __type: "Int!" },
    returning: { __type: "[result!]!" },
  },
  result_on_conflict: {
    constraint: { __type: "result_constraint!" },
    update_columns: { __type: "[result_update_column!]!" },
    where: { __type: "result_bool_exp" },
  },
  result_order_by: {
    account: { __type: "order_by" },
    asa: { __type: "order_by" },
    created_at: { __type: "order_by" },
    encryption_key: { __type: "order_by" },
    env: { __type: "order_by" },
    id: { __type: "order_by" },
    json: { __type: "order_by" },
    questiontx: { __type: "order_by" },
    round: { __type: "order_by" },
    token: { __type: "order_by" },
    tx: { __type: "order_by" },
    updated_at: { __type: "order_by" },
  },
  result_pk_columns_input: { id: { __type: "Int!" } },
  result_set_input: {
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    encryption_key: { __type: "String" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    questiontx: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  result_stddev_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  result_stddev_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  result_stddev_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  result_sum_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  result_var_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  result_var_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  result_variance_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  subscription: {
    __typename: { __type: "String!" },
    delegation: {
      __type: "[delegation!]!",
      __args: {
        distinct_on: "[delegation_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[delegation_order_by!]",
        where: "delegation_bool_exp",
      },
    },
    delegation_aggregate: {
      __type: "delegation_aggregate!",
      __args: {
        distinct_on: "[delegation_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[delegation_order_by!]",
        where: "delegation_bool_exp",
      },
    },
    delegation_by_pk: { __type: "delegation", __args: { id: "Int!" } },
    etg: {
      __type: "[etg!]!",
      __args: {
        distinct_on: "[etg_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[etg_order_by!]",
        where: "etg_bool_exp",
      },
    },
    etg_aggregate: {
      __type: "etg_aggregate!",
      __args: {
        distinct_on: "[etg_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[etg_order_by!]",
        where: "etg_bool_exp",
      },
    },
    etg_by_pk: { __type: "etg", __args: { id: "Int!" } },
    indexer: {
      __type: "[indexer!]!",
      __args: {
        distinct_on: "[indexer_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[indexer_order_by!]",
        where: "indexer_bool_exp",
      },
    },
    indexer_aggregate: {
      __type: "indexer_aggregate!",
      __args: {
        distinct_on: "[indexer_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[indexer_order_by!]",
        where: "indexer_bool_exp",
      },
    },
    indexer_by_pk: { __type: "indexer", __args: { id: "Int!" } },
    question: {
      __type: "[question!]!",
      __args: {
        distinct_on: "[question_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[question_order_by!]",
        where: "question_bool_exp",
      },
    },
    question_aggregate: {
      __type: "question_aggregate!",
      __args: {
        distinct_on: "[question_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[question_order_by!]",
        where: "question_bool_exp",
      },
    },
    question_by_pk: { __type: "question", __args: { id: "Int!" } },
    result: {
      __type: "[result!]!",
      __args: {
        distinct_on: "[result_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[result_order_by!]",
        where: "result_bool_exp",
      },
    },
    result_aggregate: {
      __type: "result_aggregate!",
      __args: {
        distinct_on: "[result_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[result_order_by!]",
        where: "result_bool_exp",
      },
    },
    result_by_pk: { __type: "result", __args: { id: "Int!" } },
    token: {
      __type: "[token!]!",
      __args: {
        distinct_on: "[token_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[token_order_by!]",
        where: "token_bool_exp",
      },
    },
    token_aggregate: {
      __type: "token_aggregate!",
      __args: {
        distinct_on: "[token_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[token_order_by!]",
        where: "token_bool_exp",
      },
    },
    token_by_pk: { __type: "token", __args: { id: "Int!" } },
    trusted_list: {
      __type: "[trusted_list!]!",
      __args: {
        distinct_on: "[trusted_list_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[trusted_list_order_by!]",
        where: "trusted_list_bool_exp",
      },
    },
    trusted_list_aggregate: {
      __type: "trusted_list_aggregate!",
      __args: {
        distinct_on: "[trusted_list_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[trusted_list_order_by!]",
        where: "trusted_list_bool_exp",
      },
    },
    trusted_list_by_pk: { __type: "trusted_list", __args: { id: "Int!" } },
    vote: {
      __type: "[vote!]!",
      __args: {
        distinct_on: "[vote_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[vote_order_by!]",
        where: "vote_bool_exp",
      },
    },
    vote_aggregate: {
      __type: "vote_aggregate!",
      __args: {
        distinct_on: "[vote_select_column!]",
        limit: "Int",
        offset: "Int",
        order_by: "[vote_order_by!]",
        where: "vote_bool_exp",
      },
    },
    vote_by_pk: { __type: "vote", __args: { id: "Int!" } },
  },
  timestamptz_comparison_exp: {
    _eq: { __type: "timestamptz" },
    _gt: { __type: "timestamptz" },
    _gte: { __type: "timestamptz" },
    _in: { __type: "[timestamptz!]" },
    _is_null: { __type: "Boolean" },
    _lt: { __type: "timestamptz" },
    _lte: { __type: "timestamptz" },
    _neq: { __type: "timestamptz" },
    _nin: { __type: "[timestamptz!]" },
  },
  token: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint!" },
    clawback: { __type: "String!" },
    created_at: { __type: "timestamptz!" },
    created_at_round: { __type: "bigint!" },
    creator: { __type: "String!" },
    decimals: { __type: "Int!" },
    env: { __type: "String!" },
    freeze: { __type: "String!" },
    icon: { __type: "String!" },
    id: { __type: "Int!" },
    manager: { __type: "String!" },
    name: { __type: "String!" },
    reserve: { __type: "String!" },
    total: { __type: "bigint!" },
    unit: { __type: "String!" },
    updated_at: { __type: "timestamptz!" },
    url: { __type: "String!" },
  },
  token_aggregate: {
    __typename: { __type: "String!" },
    aggregate: { __type: "token_aggregate_fields" },
    nodes: { __type: "[token!]!" },
  },
  token_aggregate_fields: {
    __typename: { __type: "String!" },
    avg: { __type: "token_avg_fields" },
    count: {
      __type: "Int!",
      __args: { columns: "[token_select_column!]", distinct: "Boolean" },
    },
    max: { __type: "token_max_fields" },
    min: { __type: "token_min_fields" },
    stddev: { __type: "token_stddev_fields" },
    stddev_pop: { __type: "token_stddev_pop_fields" },
    stddev_samp: { __type: "token_stddev_samp_fields" },
    sum: { __type: "token_sum_fields" },
    var_pop: { __type: "token_var_pop_fields" },
    var_samp: { __type: "token_var_samp_fields" },
    variance: { __type: "token_variance_fields" },
  },
  token_avg_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    created_at_round: { __type: "Float" },
    decimals: { __type: "Float" },
    id: { __type: "Float" },
    total: { __type: "Float" },
  },
  token_bool_exp: {
    _and: { __type: "[token_bool_exp!]" },
    _not: { __type: "token_bool_exp" },
    _or: { __type: "[token_bool_exp!]" },
    asa: { __type: "bigint_comparison_exp" },
    clawback: { __type: "String_comparison_exp" },
    created_at: { __type: "timestamptz_comparison_exp" },
    created_at_round: { __type: "bigint_comparison_exp" },
    creator: { __type: "String_comparison_exp" },
    decimals: { __type: "Int_comparison_exp" },
    env: { __type: "String_comparison_exp" },
    freeze: { __type: "String_comparison_exp" },
    icon: { __type: "String_comparison_exp" },
    id: { __type: "Int_comparison_exp" },
    manager: { __type: "String_comparison_exp" },
    name: { __type: "String_comparison_exp" },
    reserve: { __type: "String_comparison_exp" },
    total: { __type: "bigint_comparison_exp" },
    unit: { __type: "String_comparison_exp" },
    updated_at: { __type: "timestamptz_comparison_exp" },
    url: { __type: "String_comparison_exp" },
  },
  token_inc_input: {
    asa: { __type: "bigint" },
    created_at_round: { __type: "bigint" },
    decimals: { __type: "Int" },
    id: { __type: "Int" },
    total: { __type: "bigint" },
  },
  token_insert_input: {
    asa: { __type: "bigint" },
    clawback: { __type: "String" },
    created_at: { __type: "timestamptz" },
    created_at_round: { __type: "bigint" },
    creator: { __type: "String" },
    decimals: { __type: "Int" },
    env: { __type: "String" },
    freeze: { __type: "String" },
    icon: { __type: "String" },
    id: { __type: "Int" },
    manager: { __type: "String" },
    name: { __type: "String" },
    reserve: { __type: "String" },
    total: { __type: "bigint" },
    unit: { __type: "String" },
    updated_at: { __type: "timestamptz" },
    url: { __type: "String" },
  },
  token_max_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    clawback: { __type: "String" },
    created_at: { __type: "timestamptz" },
    created_at_round: { __type: "bigint" },
    creator: { __type: "String" },
    decimals: { __type: "Int" },
    env: { __type: "String" },
    freeze: { __type: "String" },
    icon: { __type: "String" },
    id: { __type: "Int" },
    manager: { __type: "String" },
    name: { __type: "String" },
    reserve: { __type: "String" },
    total: { __type: "bigint" },
    unit: { __type: "String" },
    updated_at: { __type: "timestamptz" },
    url: { __type: "String" },
  },
  token_min_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    clawback: { __type: "String" },
    created_at: { __type: "timestamptz" },
    created_at_round: { __type: "bigint" },
    creator: { __type: "String" },
    decimals: { __type: "Int" },
    env: { __type: "String" },
    freeze: { __type: "String" },
    icon: { __type: "String" },
    id: { __type: "Int" },
    manager: { __type: "String" },
    name: { __type: "String" },
    reserve: { __type: "String" },
    total: { __type: "bigint" },
    unit: { __type: "String" },
    updated_at: { __type: "timestamptz" },
    url: { __type: "String" },
  },
  token_mutation_response: {
    __typename: { __type: "String!" },
    affected_rows: { __type: "Int!" },
    returning: { __type: "[token!]!" },
  },
  token_on_conflict: {
    constraint: { __type: "token_constraint!" },
    update_columns: { __type: "[token_update_column!]!" },
    where: { __type: "token_bool_exp" },
  },
  token_order_by: {
    asa: { __type: "order_by" },
    clawback: { __type: "order_by" },
    created_at: { __type: "order_by" },
    created_at_round: { __type: "order_by" },
    creator: { __type: "order_by" },
    decimals: { __type: "order_by" },
    env: { __type: "order_by" },
    freeze: { __type: "order_by" },
    icon: { __type: "order_by" },
    id: { __type: "order_by" },
    manager: { __type: "order_by" },
    name: { __type: "order_by" },
    reserve: { __type: "order_by" },
    total: { __type: "order_by" },
    unit: { __type: "order_by" },
    updated_at: { __type: "order_by" },
    url: { __type: "order_by" },
  },
  token_pk_columns_input: { id: { __type: "Int!" } },
  token_set_input: {
    asa: { __type: "bigint" },
    clawback: { __type: "String" },
    created_at: { __type: "timestamptz" },
    created_at_round: { __type: "bigint" },
    creator: { __type: "String" },
    decimals: { __type: "Int" },
    env: { __type: "String" },
    freeze: { __type: "String" },
    icon: { __type: "String" },
    id: { __type: "Int" },
    manager: { __type: "String" },
    name: { __type: "String" },
    reserve: { __type: "String" },
    total: { __type: "bigint" },
    unit: { __type: "String" },
    updated_at: { __type: "timestamptz" },
    url: { __type: "String" },
  },
  token_stddev_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    created_at_round: { __type: "Float" },
    decimals: { __type: "Float" },
    id: { __type: "Float" },
    total: { __type: "Float" },
  },
  token_stddev_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    created_at_round: { __type: "Float" },
    decimals: { __type: "Float" },
    id: { __type: "Float" },
    total: { __type: "Float" },
  },
  token_stddev_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    created_at_round: { __type: "Float" },
    decimals: { __type: "Float" },
    id: { __type: "Float" },
    total: { __type: "Float" },
  },
  token_sum_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    created_at_round: { __type: "bigint" },
    decimals: { __type: "Int" },
    id: { __type: "Int" },
    total: { __type: "bigint" },
  },
  token_var_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    created_at_round: { __type: "Float" },
    decimals: { __type: "Float" },
    id: { __type: "Float" },
    total: { __type: "Float" },
  },
  token_var_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    created_at_round: { __type: "Float" },
    decimals: { __type: "Float" },
    id: { __type: "Float" },
    total: { __type: "Float" },
  },
  token_variance_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    created_at_round: { __type: "Float" },
    decimals: { __type: "Float" },
    id: { __type: "Float" },
    total: { __type: "Float" },
  },
  trusted_list: {
    __typename: { __type: "String!" },
    account: { __type: "String!" },
    asa: { __type: "bigint!" },
    created_at: { __type: "timestamptz!" },
    env: { __type: "String!" },
    id: { __type: "Int!" },
    json: { __type: "String!" },
    round: { __type: "bigint!" },
    token: { __type: "Int!" },
    tx: { __type: "String!" },
    updated_at: { __type: "timestamptz!" },
  },
  trusted_list_aggregate: {
    __typename: { __type: "String!" },
    aggregate: { __type: "trusted_list_aggregate_fields" },
    nodes: { __type: "[trusted_list!]!" },
  },
  trusted_list_aggregate_fields: {
    __typename: { __type: "String!" },
    avg: { __type: "trusted_list_avg_fields" },
    count: {
      __type: "Int!",
      __args: { columns: "[trusted_list_select_column!]", distinct: "Boolean" },
    },
    max: { __type: "trusted_list_max_fields" },
    min: { __type: "trusted_list_min_fields" },
    stddev: { __type: "trusted_list_stddev_fields" },
    stddev_pop: { __type: "trusted_list_stddev_pop_fields" },
    stddev_samp: { __type: "trusted_list_stddev_samp_fields" },
    sum: { __type: "trusted_list_sum_fields" },
    var_pop: { __type: "trusted_list_var_pop_fields" },
    var_samp: { __type: "trusted_list_var_samp_fields" },
    variance: { __type: "trusted_list_variance_fields" },
  },
  trusted_list_avg_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  trusted_list_bool_exp: {
    _and: { __type: "[trusted_list_bool_exp!]" },
    _not: { __type: "trusted_list_bool_exp" },
    _or: { __type: "[trusted_list_bool_exp!]" },
    account: { __type: "String_comparison_exp" },
    asa: { __type: "bigint_comparison_exp" },
    created_at: { __type: "timestamptz_comparison_exp" },
    env: { __type: "String_comparison_exp" },
    id: { __type: "Int_comparison_exp" },
    json: { __type: "String_comparison_exp" },
    round: { __type: "bigint_comparison_exp" },
    token: { __type: "Int_comparison_exp" },
    tx: { __type: "String_comparison_exp" },
    updated_at: { __type: "timestamptz_comparison_exp" },
  },
  trusted_list_inc_input: {
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  trusted_list_insert_input: {
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  trusted_list_max_fields: {
    __typename: { __type: "String!" },
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  trusted_list_min_fields: {
    __typename: { __type: "String!" },
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  trusted_list_mutation_response: {
    __typename: { __type: "String!" },
    affected_rows: { __type: "Int!" },
    returning: { __type: "[trusted_list!]!" },
  },
  trusted_list_on_conflict: {
    constraint: { __type: "trusted_list_constraint!" },
    update_columns: { __type: "[trusted_list_update_column!]!" },
    where: { __type: "trusted_list_bool_exp" },
  },
  trusted_list_order_by: {
    account: { __type: "order_by" },
    asa: { __type: "order_by" },
    created_at: { __type: "order_by" },
    env: { __type: "order_by" },
    id: { __type: "order_by" },
    json: { __type: "order_by" },
    round: { __type: "order_by" },
    token: { __type: "order_by" },
    tx: { __type: "order_by" },
    updated_at: { __type: "order_by" },
  },
  trusted_list_pk_columns_input: { id: { __type: "Int!" } },
  trusted_list_set_input: {
    account: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  trusted_list_stddev_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  trusted_list_stddev_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  trusted_list_stddev_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  trusted_list_sum_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  trusted_list_var_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  trusted_list_var_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  trusted_list_variance_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  vote: {
    __typename: { __type: "String!" },
    address: { __type: "String!" },
    asa: { __type: "bigint!" },
    created_at: { __type: "timestamptz!" },
    env: { __type: "String!" },
    id: { __type: "Int!" },
    json: { __type: "String!" },
    questiontx: { __type: "String!" },
    round: { __type: "bigint!" },
    token: { __type: "Int!" },
    tx: { __type: "String!" },
    updated_at: { __type: "timestamptz!" },
  },
  vote_aggregate: {
    __typename: { __type: "String!" },
    aggregate: { __type: "vote_aggregate_fields" },
    nodes: { __type: "[vote!]!" },
  },
  vote_aggregate_fields: {
    __typename: { __type: "String!" },
    avg: { __type: "vote_avg_fields" },
    count: {
      __type: "Int!",
      __args: { columns: "[vote_select_column!]", distinct: "Boolean" },
    },
    max: { __type: "vote_max_fields" },
    min: { __type: "vote_min_fields" },
    stddev: { __type: "vote_stddev_fields" },
    stddev_pop: { __type: "vote_stddev_pop_fields" },
    stddev_samp: { __type: "vote_stddev_samp_fields" },
    sum: { __type: "vote_sum_fields" },
    var_pop: { __type: "vote_var_pop_fields" },
    var_samp: { __type: "vote_var_samp_fields" },
    variance: { __type: "vote_variance_fields" },
  },
  vote_avg_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  vote_bool_exp: {
    _and: { __type: "[vote_bool_exp!]" },
    _not: { __type: "vote_bool_exp" },
    _or: { __type: "[vote_bool_exp!]" },
    address: { __type: "String_comparison_exp" },
    asa: { __type: "bigint_comparison_exp" },
    created_at: { __type: "timestamptz_comparison_exp" },
    env: { __type: "String_comparison_exp" },
    id: { __type: "Int_comparison_exp" },
    json: { __type: "String_comparison_exp" },
    questiontx: { __type: "String_comparison_exp" },
    round: { __type: "bigint_comparison_exp" },
    token: { __type: "Int_comparison_exp" },
    tx: { __type: "String_comparison_exp" },
    updated_at: { __type: "timestamptz_comparison_exp" },
  },
  vote_inc_input: {
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  vote_insert_input: {
    address: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    questiontx: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  vote_max_fields: {
    __typename: { __type: "String!" },
    address: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    questiontx: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  vote_min_fields: {
    __typename: { __type: "String!" },
    address: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    questiontx: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  vote_mutation_response: {
    __typename: { __type: "String!" },
    affected_rows: { __type: "Int!" },
    returning: { __type: "[vote!]!" },
  },
  vote_on_conflict: {
    constraint: { __type: "vote_constraint!" },
    update_columns: { __type: "[vote_update_column!]!" },
    where: { __type: "vote_bool_exp" },
  },
  vote_order_by: {
    address: { __type: "order_by" },
    asa: { __type: "order_by" },
    created_at: { __type: "order_by" },
    env: { __type: "order_by" },
    id: { __type: "order_by" },
    json: { __type: "order_by" },
    questiontx: { __type: "order_by" },
    round: { __type: "order_by" },
    token: { __type: "order_by" },
    tx: { __type: "order_by" },
    updated_at: { __type: "order_by" },
  },
  vote_pk_columns_input: { id: { __type: "Int!" } },
  vote_set_input: {
    address: { __type: "String" },
    asa: { __type: "bigint" },
    created_at: { __type: "timestamptz" },
    env: { __type: "String" },
    id: { __type: "Int" },
    json: { __type: "String" },
    questiontx: { __type: "String" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
    tx: { __type: "String" },
    updated_at: { __type: "timestamptz" },
  },
  vote_stddev_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  vote_stddev_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  vote_stddev_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  vote_sum_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "bigint" },
    id: { __type: "Int" },
    round: { __type: "bigint" },
    token: { __type: "Int" },
  },
  vote_var_pop_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  vote_var_samp_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
  vote_variance_fields: {
    __typename: { __type: "String!" },
    asa: { __type: "Float" },
    id: { __type: "Float" },
    round: { __type: "Float" },
    token: { __type: "Float" },
  },
} as const;

/**
 * columns and relationships of "delegation"
 */
export interface delegation {
  __typename?: "delegation";
  account: ScalarsEnums["String"];
  asa: ScalarsEnums["bigint"];
  created_at: ScalarsEnums["timestamptz"];
  env: ScalarsEnums["String"];
  id: ScalarsEnums["Int"];
  json: ScalarsEnums["String"];
  round: ScalarsEnums["bigint"];
  token: ScalarsEnums["Int"];
  tx: ScalarsEnums["String"];
  updated_at: ScalarsEnums["timestamptz"];
}

/**
 * aggregated selection of "delegation"
 */
export interface delegation_aggregate {
  __typename?: "delegation_aggregate";
  aggregate?: Maybe<delegation_aggregate_fields>;
  nodes: Array<delegation>;
}

/**
 * aggregate fields of "delegation"
 */
export interface delegation_aggregate_fields {
  __typename?: "delegation_aggregate_fields";
  avg?: Maybe<delegation_avg_fields>;
  count: (args?: {
    columns?: Maybe<Array<delegation_select_column>>;
    distinct?: Maybe<Scalars["Boolean"]>;
  }) => ScalarsEnums["Int"];
  max?: Maybe<delegation_max_fields>;
  min?: Maybe<delegation_min_fields>;
  stddev?: Maybe<delegation_stddev_fields>;
  stddev_pop?: Maybe<delegation_stddev_pop_fields>;
  stddev_samp?: Maybe<delegation_stddev_samp_fields>;
  sum?: Maybe<delegation_sum_fields>;
  var_pop?: Maybe<delegation_var_pop_fields>;
  var_samp?: Maybe<delegation_var_samp_fields>;
  variance?: Maybe<delegation_variance_fields>;
}

/**
 * aggregate avg on columns
 */
export interface delegation_avg_fields {
  __typename?: "delegation_avg_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate max on columns
 */
export interface delegation_max_fields {
  __typename?: "delegation_max_fields";
  account?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * aggregate min on columns
 */
export interface delegation_min_fields {
  __typename?: "delegation_min_fields";
  account?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * response of any mutation on the table "delegation"
 */
export interface delegation_mutation_response {
  __typename?: "delegation_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: ScalarsEnums["Int"];
  /**
   * data from the rows affected by the mutation
   */
  returning: Array<delegation>;
}

/**
 * aggregate stddev on columns
 */
export interface delegation_stddev_fields {
  __typename?: "delegation_stddev_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_pop on columns
 */
export interface delegation_stddev_pop_fields {
  __typename?: "delegation_stddev_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_samp on columns
 */
export interface delegation_stddev_samp_fields {
  __typename?: "delegation_stddev_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate sum on columns
 */
export interface delegation_sum_fields {
  __typename?: "delegation_sum_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * aggregate var_pop on columns
 */
export interface delegation_var_pop_fields {
  __typename?: "delegation_var_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate var_samp on columns
 */
export interface delegation_var_samp_fields {
  __typename?: "delegation_var_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate variance on columns
 */
export interface delegation_variance_fields {
  __typename?: "delegation_variance_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * columns and relationships of "etg"
 */
export interface etg {
  __typename?: "etg";
  account: ScalarsEnums["String"];
  asa: ScalarsEnums["bigint"];
  created_at: ScalarsEnums["timestamptz"];
  env: ScalarsEnums["String"];
  id: ScalarsEnums["Int"];
  json: ScalarsEnums["String"];
  round: ScalarsEnums["bigint"];
  token: ScalarsEnums["Int"];
  tx: ScalarsEnums["String"];
  updated_at: ScalarsEnums["timestamptz"];
}

/**
 * aggregated selection of "etg"
 */
export interface etg_aggregate {
  __typename?: "etg_aggregate";
  aggregate?: Maybe<etg_aggregate_fields>;
  nodes: Array<etg>;
}

/**
 * aggregate fields of "etg"
 */
export interface etg_aggregate_fields {
  __typename?: "etg_aggregate_fields";
  avg?: Maybe<etg_avg_fields>;
  count: (args?: {
    columns?: Maybe<Array<etg_select_column>>;
    distinct?: Maybe<Scalars["Boolean"]>;
  }) => ScalarsEnums["Int"];
  max?: Maybe<etg_max_fields>;
  min?: Maybe<etg_min_fields>;
  stddev?: Maybe<etg_stddev_fields>;
  stddev_pop?: Maybe<etg_stddev_pop_fields>;
  stddev_samp?: Maybe<etg_stddev_samp_fields>;
  sum?: Maybe<etg_sum_fields>;
  var_pop?: Maybe<etg_var_pop_fields>;
  var_samp?: Maybe<etg_var_samp_fields>;
  variance?: Maybe<etg_variance_fields>;
}

/**
 * aggregate avg on columns
 */
export interface etg_avg_fields {
  __typename?: "etg_avg_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate max on columns
 */
export interface etg_max_fields {
  __typename?: "etg_max_fields";
  account?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * aggregate min on columns
 */
export interface etg_min_fields {
  __typename?: "etg_min_fields";
  account?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * response of any mutation on the table "etg"
 */
export interface etg_mutation_response {
  __typename?: "etg_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: ScalarsEnums["Int"];
  /**
   * data from the rows affected by the mutation
   */
  returning: Array<etg>;
}

/**
 * aggregate stddev on columns
 */
export interface etg_stddev_fields {
  __typename?: "etg_stddev_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_pop on columns
 */
export interface etg_stddev_pop_fields {
  __typename?: "etg_stddev_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_samp on columns
 */
export interface etg_stddev_samp_fields {
  __typename?: "etg_stddev_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate sum on columns
 */
export interface etg_sum_fields {
  __typename?: "etg_sum_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * aggregate var_pop on columns
 */
export interface etg_var_pop_fields {
  __typename?: "etg_var_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate var_samp on columns
 */
export interface etg_var_samp_fields {
  __typename?: "etg_var_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate variance on columns
 */
export interface etg_variance_fields {
  __typename?: "etg_variance_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * columns and relationships of "indexer"
 */
export interface indexer {
  __typename?: "indexer";
  created_at: ScalarsEnums["timestamptz"];
  id: ScalarsEnums["Int"];
  instance: ScalarsEnums["String"];
  round: ScalarsEnums["bigint"];
  updated_at: ScalarsEnums["timestamptz"];
}

/**
 * aggregated selection of "indexer"
 */
export interface indexer_aggregate {
  __typename?: "indexer_aggregate";
  aggregate?: Maybe<indexer_aggregate_fields>;
  nodes: Array<indexer>;
}

/**
 * aggregate fields of "indexer"
 */
export interface indexer_aggregate_fields {
  __typename?: "indexer_aggregate_fields";
  avg?: Maybe<indexer_avg_fields>;
  count: (args?: {
    columns?: Maybe<Array<indexer_select_column>>;
    distinct?: Maybe<Scalars["Boolean"]>;
  }) => ScalarsEnums["Int"];
  max?: Maybe<indexer_max_fields>;
  min?: Maybe<indexer_min_fields>;
  stddev?: Maybe<indexer_stddev_fields>;
  stddev_pop?: Maybe<indexer_stddev_pop_fields>;
  stddev_samp?: Maybe<indexer_stddev_samp_fields>;
  sum?: Maybe<indexer_sum_fields>;
  var_pop?: Maybe<indexer_var_pop_fields>;
  var_samp?: Maybe<indexer_var_samp_fields>;
  variance?: Maybe<indexer_variance_fields>;
}

/**
 * aggregate avg on columns
 */
export interface indexer_avg_fields {
  __typename?: "indexer_avg_fields";
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate max on columns
 */
export interface indexer_max_fields {
  __typename?: "indexer_max_fields";
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  instance?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * aggregate min on columns
 */
export interface indexer_min_fields {
  __typename?: "indexer_min_fields";
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  instance?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * response of any mutation on the table "indexer"
 */
export interface indexer_mutation_response {
  __typename?: "indexer_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: ScalarsEnums["Int"];
  /**
   * data from the rows affected by the mutation
   */
  returning: Array<indexer>;
}

/**
 * aggregate stddev on columns
 */
export interface indexer_stddev_fields {
  __typename?: "indexer_stddev_fields";
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_pop on columns
 */
export interface indexer_stddev_pop_fields {
  __typename?: "indexer_stddev_pop_fields";
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_samp on columns
 */
export interface indexer_stddev_samp_fields {
  __typename?: "indexer_stddev_samp_fields";
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate sum on columns
 */
export interface indexer_sum_fields {
  __typename?: "indexer_sum_fields";
  id?: Maybe<ScalarsEnums["Int"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
}

/**
 * aggregate var_pop on columns
 */
export interface indexer_var_pop_fields {
  __typename?: "indexer_var_pop_fields";
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate var_samp on columns
 */
export interface indexer_var_samp_fields {
  __typename?: "indexer_var_samp_fields";
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate variance on columns
 */
export interface indexer_variance_fields {
  __typename?: "indexer_variance_fields";
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
}

export interface Mutation {
  __typename?: "Mutation";
  delete_delegation: (args: {
    where: delegation_bool_exp;
  }) => Maybe<delegation_mutation_response>;
  delete_delegation_by_pk: (args: { id: Scalars["Int"] }) => Maybe<delegation>;
  delete_etg: (args: { where: etg_bool_exp }) => Maybe<etg_mutation_response>;
  delete_etg_by_pk: (args: { id: Scalars["Int"] }) => Maybe<etg>;
  delete_indexer: (args: {
    where: indexer_bool_exp;
  }) => Maybe<indexer_mutation_response>;
  delete_indexer_by_pk: (args: { id: Scalars["Int"] }) => Maybe<indexer>;
  delete_question: (args: {
    where: question_bool_exp;
  }) => Maybe<question_mutation_response>;
  delete_question_by_pk: (args: { id: Scalars["Int"] }) => Maybe<question>;
  delete_result: (args: {
    where: result_bool_exp;
  }) => Maybe<result_mutation_response>;
  delete_result_by_pk: (args: { id: Scalars["Int"] }) => Maybe<result>;
  delete_token: (args: {
    where: token_bool_exp;
  }) => Maybe<token_mutation_response>;
  delete_token_by_pk: (args: { id: Scalars["Int"] }) => Maybe<token>;
  delete_trusted_list: (args: {
    where: trusted_list_bool_exp;
  }) => Maybe<trusted_list_mutation_response>;
  delete_trusted_list_by_pk: (args: {
    id: Scalars["Int"];
  }) => Maybe<trusted_list>;
  delete_vote: (args: {
    where: vote_bool_exp;
  }) => Maybe<vote_mutation_response>;
  delete_vote_by_pk: (args: { id: Scalars["Int"] }) => Maybe<vote>;
  insert_delegation: (args: {
    objects: Array<delegation_insert_input>;
    on_conflict?: Maybe<delegation_on_conflict>;
  }) => Maybe<delegation_mutation_response>;
  insert_delegation_one: (args: {
    object: delegation_insert_input;
    on_conflict?: Maybe<delegation_on_conflict>;
  }) => Maybe<delegation>;
  insert_etg: (args: {
    objects: Array<etg_insert_input>;
    on_conflict?: Maybe<etg_on_conflict>;
  }) => Maybe<etg_mutation_response>;
  insert_etg_one: (args: {
    object: etg_insert_input;
    on_conflict?: Maybe<etg_on_conflict>;
  }) => Maybe<etg>;
  insert_indexer: (args: {
    objects: Array<indexer_insert_input>;
    on_conflict?: Maybe<indexer_on_conflict>;
  }) => Maybe<indexer_mutation_response>;
  insert_indexer_one: (args: {
    object: indexer_insert_input;
    on_conflict?: Maybe<indexer_on_conflict>;
  }) => Maybe<indexer>;
  insert_question: (args: {
    objects: Array<question_insert_input>;
    on_conflict?: Maybe<question_on_conflict>;
  }) => Maybe<question_mutation_response>;
  insert_question_one: (args: {
    object: question_insert_input;
    on_conflict?: Maybe<question_on_conflict>;
  }) => Maybe<question>;
  insert_result: (args: {
    objects: Array<result_insert_input>;
    on_conflict?: Maybe<result_on_conflict>;
  }) => Maybe<result_mutation_response>;
  insert_result_one: (args: {
    object: result_insert_input;
    on_conflict?: Maybe<result_on_conflict>;
  }) => Maybe<result>;
  insert_token: (args: {
    objects: Array<token_insert_input>;
    on_conflict?: Maybe<token_on_conflict>;
  }) => Maybe<token_mutation_response>;
  insert_token_one: (args: {
    object: token_insert_input;
    on_conflict?: Maybe<token_on_conflict>;
  }) => Maybe<token>;
  insert_trusted_list: (args: {
    objects: Array<trusted_list_insert_input>;
    on_conflict?: Maybe<trusted_list_on_conflict>;
  }) => Maybe<trusted_list_mutation_response>;
  insert_trusted_list_one: (args: {
    object: trusted_list_insert_input;
    on_conflict?: Maybe<trusted_list_on_conflict>;
  }) => Maybe<trusted_list>;
  insert_vote: (args: {
    objects: Array<vote_insert_input>;
    on_conflict?: Maybe<vote_on_conflict>;
  }) => Maybe<vote_mutation_response>;
  insert_vote_one: (args: {
    object: vote_insert_input;
    on_conflict?: Maybe<vote_on_conflict>;
  }) => Maybe<vote>;
  update_delegation: (args: {
    _inc?: Maybe<delegation_inc_input>;
    _set?: Maybe<delegation_set_input>;
    where: delegation_bool_exp;
  }) => Maybe<delegation_mutation_response>;
  update_delegation_by_pk: (args: {
    _inc?: Maybe<delegation_inc_input>;
    _set?: Maybe<delegation_set_input>;
    pk_columns: delegation_pk_columns_input;
  }) => Maybe<delegation>;
  update_etg: (args: {
    _inc?: Maybe<etg_inc_input>;
    _set?: Maybe<etg_set_input>;
    where: etg_bool_exp;
  }) => Maybe<etg_mutation_response>;
  update_etg_by_pk: (args: {
    _inc?: Maybe<etg_inc_input>;
    _set?: Maybe<etg_set_input>;
    pk_columns: etg_pk_columns_input;
  }) => Maybe<etg>;
  update_indexer: (args: {
    _inc?: Maybe<indexer_inc_input>;
    _set?: Maybe<indexer_set_input>;
    where: indexer_bool_exp;
  }) => Maybe<indexer_mutation_response>;
  update_indexer_by_pk: (args: {
    _inc?: Maybe<indexer_inc_input>;
    _set?: Maybe<indexer_set_input>;
    pk_columns: indexer_pk_columns_input;
  }) => Maybe<indexer>;
  update_question: (args: {
    _inc?: Maybe<question_inc_input>;
    _set?: Maybe<question_set_input>;
    where: question_bool_exp;
  }) => Maybe<question_mutation_response>;
  update_question_by_pk: (args: {
    _inc?: Maybe<question_inc_input>;
    _set?: Maybe<question_set_input>;
    pk_columns: question_pk_columns_input;
  }) => Maybe<question>;
  update_result: (args: {
    _inc?: Maybe<result_inc_input>;
    _set?: Maybe<result_set_input>;
    where: result_bool_exp;
  }) => Maybe<result_mutation_response>;
  update_result_by_pk: (args: {
    _inc?: Maybe<result_inc_input>;
    _set?: Maybe<result_set_input>;
    pk_columns: result_pk_columns_input;
  }) => Maybe<result>;
  update_token: (args: {
    _inc?: Maybe<token_inc_input>;
    _set?: Maybe<token_set_input>;
    where: token_bool_exp;
  }) => Maybe<token_mutation_response>;
  update_token_by_pk: (args: {
    _inc?: Maybe<token_inc_input>;
    _set?: Maybe<token_set_input>;
    pk_columns: token_pk_columns_input;
  }) => Maybe<token>;
  update_trusted_list: (args: {
    _inc?: Maybe<trusted_list_inc_input>;
    _set?: Maybe<trusted_list_set_input>;
    where: trusted_list_bool_exp;
  }) => Maybe<trusted_list_mutation_response>;
  update_trusted_list_by_pk: (args: {
    _inc?: Maybe<trusted_list_inc_input>;
    _set?: Maybe<trusted_list_set_input>;
    pk_columns: trusted_list_pk_columns_input;
  }) => Maybe<trusted_list>;
  update_vote: (args: {
    _inc?: Maybe<vote_inc_input>;
    _set?: Maybe<vote_set_input>;
    where: vote_bool_exp;
  }) => Maybe<vote_mutation_response>;
  update_vote_by_pk: (args: {
    _inc?: Maybe<vote_inc_input>;
    _set?: Maybe<vote_set_input>;
    pk_columns: vote_pk_columns_input;
  }) => Maybe<vote>;
}

export interface Query {
  __typename?: "Query";
  delegation: (args?: {
    distinct_on?: Maybe<Array<delegation_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<delegation_order_by>>;
    where?: Maybe<delegation_bool_exp>;
  }) => Array<delegation>;
  delegation_aggregate: (args?: {
    distinct_on?: Maybe<Array<delegation_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<delegation_order_by>>;
    where?: Maybe<delegation_bool_exp>;
  }) => delegation_aggregate;
  delegation_by_pk: (args: { id: Scalars["Int"] }) => Maybe<delegation>;
  etg: (args?: {
    distinct_on?: Maybe<Array<etg_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<etg_order_by>>;
    where?: Maybe<etg_bool_exp>;
  }) => Array<etg>;
  etg_aggregate: (args?: {
    distinct_on?: Maybe<Array<etg_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<etg_order_by>>;
    where?: Maybe<etg_bool_exp>;
  }) => etg_aggregate;
  etg_by_pk: (args: { id: Scalars["Int"] }) => Maybe<etg>;
  indexer: (args?: {
    distinct_on?: Maybe<Array<indexer_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<indexer_order_by>>;
    where?: Maybe<indexer_bool_exp>;
  }) => Array<indexer>;
  indexer_aggregate: (args?: {
    distinct_on?: Maybe<Array<indexer_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<indexer_order_by>>;
    where?: Maybe<indexer_bool_exp>;
  }) => indexer_aggregate;
  indexer_by_pk: (args: { id: Scalars["Int"] }) => Maybe<indexer>;
  question: (args?: {
    distinct_on?: Maybe<Array<question_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<question_order_by>>;
    where?: Maybe<question_bool_exp>;
  }) => Array<question>;
  question_aggregate: (args?: {
    distinct_on?: Maybe<Array<question_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<question_order_by>>;
    where?: Maybe<question_bool_exp>;
  }) => question_aggregate;
  question_by_pk: (args: { id: Scalars["Int"] }) => Maybe<question>;
  result: (args?: {
    distinct_on?: Maybe<Array<result_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<result_order_by>>;
    where?: Maybe<result_bool_exp>;
  }) => Array<result>;
  result_aggregate: (args?: {
    distinct_on?: Maybe<Array<result_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<result_order_by>>;
    where?: Maybe<result_bool_exp>;
  }) => result_aggregate;
  result_by_pk: (args: { id: Scalars["Int"] }) => Maybe<result>;
  token: (args?: {
    distinct_on?: Maybe<Array<token_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<token_order_by>>;
    where?: Maybe<token_bool_exp>;
  }) => Array<token>;
  token_aggregate: (args?: {
    distinct_on?: Maybe<Array<token_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<token_order_by>>;
    where?: Maybe<token_bool_exp>;
  }) => token_aggregate;
  token_by_pk: (args: { id: Scalars["Int"] }) => Maybe<token>;
  trusted_list: (args?: {
    distinct_on?: Maybe<Array<trusted_list_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<trusted_list_order_by>>;
    where?: Maybe<trusted_list_bool_exp>;
  }) => Array<trusted_list>;
  trusted_list_aggregate: (args?: {
    distinct_on?: Maybe<Array<trusted_list_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<trusted_list_order_by>>;
    where?: Maybe<trusted_list_bool_exp>;
  }) => trusted_list_aggregate;
  trusted_list_by_pk: (args: { id: Scalars["Int"] }) => Maybe<trusted_list>;
  vote: (args?: {
    distinct_on?: Maybe<Array<vote_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<vote_order_by>>;
    where?: Maybe<vote_bool_exp>;
  }) => Array<vote>;
  vote_aggregate: (args?: {
    distinct_on?: Maybe<Array<vote_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<vote_order_by>>;
    where?: Maybe<vote_bool_exp>;
  }) => vote_aggregate;
  vote_by_pk: (args: { id: Scalars["Int"] }) => Maybe<vote>;
}

/**
 * columns and relationships of "question"
 */
export interface question {
  __typename?: "question";
  asa: ScalarsEnums["bigint"];
  category: ScalarsEnums["String"];
  created_at: ScalarsEnums["timestamptz"];
  encryption_address: ScalarsEnums["String"];
  ending_round: ScalarsEnums["bigint"];
  env: ScalarsEnums["String"];
  id: ScalarsEnums["Int"];
  json: ScalarsEnums["String"];
  open_from_round: ScalarsEnums["bigint"];
  open_from_time: ScalarsEnums["timestamptz"];
  questioner: ScalarsEnums["String"];
  text: ScalarsEnums["String"];
  title: ScalarsEnums["String"];
  token: ScalarsEnums["Int"];
  tx: ScalarsEnums["String"];
  updated_at: ScalarsEnums["timestamptz"];
  url: ScalarsEnums["String"];
}

/**
 * aggregated selection of "question"
 */
export interface question_aggregate {
  __typename?: "question_aggregate";
  aggregate?: Maybe<question_aggregate_fields>;
  nodes: Array<question>;
}

/**
 * aggregate fields of "question"
 */
export interface question_aggregate_fields {
  __typename?: "question_aggregate_fields";
  avg?: Maybe<question_avg_fields>;
  count: (args?: {
    columns?: Maybe<Array<question_select_column>>;
    distinct?: Maybe<Scalars["Boolean"]>;
  }) => ScalarsEnums["Int"];
  max?: Maybe<question_max_fields>;
  min?: Maybe<question_min_fields>;
  stddev?: Maybe<question_stddev_fields>;
  stddev_pop?: Maybe<question_stddev_pop_fields>;
  stddev_samp?: Maybe<question_stddev_samp_fields>;
  sum?: Maybe<question_sum_fields>;
  var_pop?: Maybe<question_var_pop_fields>;
  var_samp?: Maybe<question_var_samp_fields>;
  variance?: Maybe<question_variance_fields>;
}

/**
 * aggregate avg on columns
 */
export interface question_avg_fields {
  __typename?: "question_avg_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  ending_round?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  open_from_round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate max on columns
 */
export interface question_max_fields {
  __typename?: "question_max_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  category?: Maybe<ScalarsEnums["String"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  encryption_address?: Maybe<ScalarsEnums["String"]>;
  ending_round?: Maybe<ScalarsEnums["bigint"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  open_from_round?: Maybe<ScalarsEnums["bigint"]>;
  open_from_time?: Maybe<ScalarsEnums["timestamptz"]>;
  questioner?: Maybe<ScalarsEnums["String"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * aggregate min on columns
 */
export interface question_min_fields {
  __typename?: "question_min_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  category?: Maybe<ScalarsEnums["String"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  encryption_address?: Maybe<ScalarsEnums["String"]>;
  ending_round?: Maybe<ScalarsEnums["bigint"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  open_from_round?: Maybe<ScalarsEnums["bigint"]>;
  open_from_time?: Maybe<ScalarsEnums["timestamptz"]>;
  questioner?: Maybe<ScalarsEnums["String"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * response of any mutation on the table "question"
 */
export interface question_mutation_response {
  __typename?: "question_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: ScalarsEnums["Int"];
  /**
   * data from the rows affected by the mutation
   */
  returning: Array<question>;
}

/**
 * aggregate stddev on columns
 */
export interface question_stddev_fields {
  __typename?: "question_stddev_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  ending_round?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  open_from_round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_pop on columns
 */
export interface question_stddev_pop_fields {
  __typename?: "question_stddev_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  ending_round?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  open_from_round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_samp on columns
 */
export interface question_stddev_samp_fields {
  __typename?: "question_stddev_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  ending_round?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  open_from_round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate sum on columns
 */
export interface question_sum_fields {
  __typename?: "question_sum_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  ending_round?: Maybe<ScalarsEnums["bigint"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  open_from_round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * aggregate var_pop on columns
 */
export interface question_var_pop_fields {
  __typename?: "question_var_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  ending_round?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  open_from_round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate var_samp on columns
 */
export interface question_var_samp_fields {
  __typename?: "question_var_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  ending_round?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  open_from_round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate variance on columns
 */
export interface question_variance_fields {
  __typename?: "question_variance_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  ending_round?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  open_from_round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * columns and relationships of "result"
 */
export interface result {
  __typename?: "result";
  account: ScalarsEnums["String"];
  asa: ScalarsEnums["bigint"];
  created_at: ScalarsEnums["timestamptz"];
  encryption_key?: Maybe<ScalarsEnums["String"]>;
  env: ScalarsEnums["String"];
  id: ScalarsEnums["Int"];
  json: ScalarsEnums["String"];
  questiontx: ScalarsEnums["String"];
  round: ScalarsEnums["bigint"];
  token: ScalarsEnums["Int"];
  tx: ScalarsEnums["String"];
  updated_at: ScalarsEnums["timestamptz"];
}

/**
 * aggregated selection of "result"
 */
export interface result_aggregate {
  __typename?: "result_aggregate";
  aggregate?: Maybe<result_aggregate_fields>;
  nodes: Array<result>;
}

/**
 * aggregate fields of "result"
 */
export interface result_aggregate_fields {
  __typename?: "result_aggregate_fields";
  avg?: Maybe<result_avg_fields>;
  count: (args?: {
    columns?: Maybe<Array<result_select_column>>;
    distinct?: Maybe<Scalars["Boolean"]>;
  }) => ScalarsEnums["Int"];
  max?: Maybe<result_max_fields>;
  min?: Maybe<result_min_fields>;
  stddev?: Maybe<result_stddev_fields>;
  stddev_pop?: Maybe<result_stddev_pop_fields>;
  stddev_samp?: Maybe<result_stddev_samp_fields>;
  sum?: Maybe<result_sum_fields>;
  var_pop?: Maybe<result_var_pop_fields>;
  var_samp?: Maybe<result_var_samp_fields>;
  variance?: Maybe<result_variance_fields>;
}

/**
 * aggregate avg on columns
 */
export interface result_avg_fields {
  __typename?: "result_avg_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate max on columns
 */
export interface result_max_fields {
  __typename?: "result_max_fields";
  account?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  encryption_key?: Maybe<ScalarsEnums["String"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  questiontx?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * aggregate min on columns
 */
export interface result_min_fields {
  __typename?: "result_min_fields";
  account?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  encryption_key?: Maybe<ScalarsEnums["String"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  questiontx?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * response of any mutation on the table "result"
 */
export interface result_mutation_response {
  __typename?: "result_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: ScalarsEnums["Int"];
  /**
   * data from the rows affected by the mutation
   */
  returning: Array<result>;
}

/**
 * aggregate stddev on columns
 */
export interface result_stddev_fields {
  __typename?: "result_stddev_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_pop on columns
 */
export interface result_stddev_pop_fields {
  __typename?: "result_stddev_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_samp on columns
 */
export interface result_stddev_samp_fields {
  __typename?: "result_stddev_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate sum on columns
 */
export interface result_sum_fields {
  __typename?: "result_sum_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * aggregate var_pop on columns
 */
export interface result_var_pop_fields {
  __typename?: "result_var_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate var_samp on columns
 */
export interface result_var_samp_fields {
  __typename?: "result_var_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate variance on columns
 */
export interface result_variance_fields {
  __typename?: "result_variance_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

export interface Subscription {
  __typename?: "Subscription";
  delegation: (args?: {
    distinct_on?: Maybe<Array<delegation_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<delegation_order_by>>;
    where?: Maybe<delegation_bool_exp>;
  }) => Array<delegation>;
  delegation_aggregate: (args?: {
    distinct_on?: Maybe<Array<delegation_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<delegation_order_by>>;
    where?: Maybe<delegation_bool_exp>;
  }) => delegation_aggregate;
  delegation_by_pk: (args: { id: Scalars["Int"] }) => Maybe<delegation>;
  etg: (args?: {
    distinct_on?: Maybe<Array<etg_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<etg_order_by>>;
    where?: Maybe<etg_bool_exp>;
  }) => Array<etg>;
  etg_aggregate: (args?: {
    distinct_on?: Maybe<Array<etg_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<etg_order_by>>;
    where?: Maybe<etg_bool_exp>;
  }) => etg_aggregate;
  etg_by_pk: (args: { id: Scalars["Int"] }) => Maybe<etg>;
  indexer: (args?: {
    distinct_on?: Maybe<Array<indexer_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<indexer_order_by>>;
    where?: Maybe<indexer_bool_exp>;
  }) => Array<indexer>;
  indexer_aggregate: (args?: {
    distinct_on?: Maybe<Array<indexer_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<indexer_order_by>>;
    where?: Maybe<indexer_bool_exp>;
  }) => indexer_aggregate;
  indexer_by_pk: (args: { id: Scalars["Int"] }) => Maybe<indexer>;
  question: (args?: {
    distinct_on?: Maybe<Array<question_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<question_order_by>>;
    where?: Maybe<question_bool_exp>;
  }) => Array<question>;
  question_aggregate: (args?: {
    distinct_on?: Maybe<Array<question_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<question_order_by>>;
    where?: Maybe<question_bool_exp>;
  }) => question_aggregate;
  question_by_pk: (args: { id: Scalars["Int"] }) => Maybe<question>;
  result: (args?: {
    distinct_on?: Maybe<Array<result_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<result_order_by>>;
    where?: Maybe<result_bool_exp>;
  }) => Array<result>;
  result_aggregate: (args?: {
    distinct_on?: Maybe<Array<result_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<result_order_by>>;
    where?: Maybe<result_bool_exp>;
  }) => result_aggregate;
  result_by_pk: (args: { id: Scalars["Int"] }) => Maybe<result>;
  token: (args?: {
    distinct_on?: Maybe<Array<token_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<token_order_by>>;
    where?: Maybe<token_bool_exp>;
  }) => Array<token>;
  token_aggregate: (args?: {
    distinct_on?: Maybe<Array<token_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<token_order_by>>;
    where?: Maybe<token_bool_exp>;
  }) => token_aggregate;
  token_by_pk: (args: { id: Scalars["Int"] }) => Maybe<token>;
  trusted_list: (args?: {
    distinct_on?: Maybe<Array<trusted_list_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<trusted_list_order_by>>;
    where?: Maybe<trusted_list_bool_exp>;
  }) => Array<trusted_list>;
  trusted_list_aggregate: (args?: {
    distinct_on?: Maybe<Array<trusted_list_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<trusted_list_order_by>>;
    where?: Maybe<trusted_list_bool_exp>;
  }) => trusted_list_aggregate;
  trusted_list_by_pk: (args: { id: Scalars["Int"] }) => Maybe<trusted_list>;
  vote: (args?: {
    distinct_on?: Maybe<Array<vote_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<vote_order_by>>;
    where?: Maybe<vote_bool_exp>;
  }) => Array<vote>;
  vote_aggregate: (args?: {
    distinct_on?: Maybe<Array<vote_select_column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<vote_order_by>>;
    where?: Maybe<vote_bool_exp>;
  }) => vote_aggregate;
  vote_by_pk: (args: { id: Scalars["Int"] }) => Maybe<vote>;
}

/**
 * columns and relationships of "token"
 */
export interface token {
  __typename?: "token";
  asa: ScalarsEnums["bigint"];
  clawback: ScalarsEnums["String"];
  created_at: ScalarsEnums["timestamptz"];
  created_at_round: ScalarsEnums["bigint"];
  creator: ScalarsEnums["String"];
  decimals: ScalarsEnums["Int"];
  env: ScalarsEnums["String"];
  freeze: ScalarsEnums["String"];
  icon: ScalarsEnums["String"];
  id: ScalarsEnums["Int"];
  manager: ScalarsEnums["String"];
  name: ScalarsEnums["String"];
  reserve: ScalarsEnums["String"];
  total: ScalarsEnums["bigint"];
  unit: ScalarsEnums["String"];
  updated_at: ScalarsEnums["timestamptz"];
  url: ScalarsEnums["String"];
}

/**
 * aggregated selection of "token"
 */
export interface token_aggregate {
  __typename?: "token_aggregate";
  aggregate?: Maybe<token_aggregate_fields>;
  nodes: Array<token>;
}

/**
 * aggregate fields of "token"
 */
export interface token_aggregate_fields {
  __typename?: "token_aggregate_fields";
  avg?: Maybe<token_avg_fields>;
  count: (args?: {
    columns?: Maybe<Array<token_select_column>>;
    distinct?: Maybe<Scalars["Boolean"]>;
  }) => ScalarsEnums["Int"];
  max?: Maybe<token_max_fields>;
  min?: Maybe<token_min_fields>;
  stddev?: Maybe<token_stddev_fields>;
  stddev_pop?: Maybe<token_stddev_pop_fields>;
  stddev_samp?: Maybe<token_stddev_samp_fields>;
  sum?: Maybe<token_sum_fields>;
  var_pop?: Maybe<token_var_pop_fields>;
  var_samp?: Maybe<token_var_samp_fields>;
  variance?: Maybe<token_variance_fields>;
}

/**
 * aggregate avg on columns
 */
export interface token_avg_fields {
  __typename?: "token_avg_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  created_at_round?: Maybe<ScalarsEnums["Float"]>;
  decimals?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  total?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate max on columns
 */
export interface token_max_fields {
  __typename?: "token_max_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  clawback?: Maybe<ScalarsEnums["String"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  created_at_round?: Maybe<ScalarsEnums["bigint"]>;
  creator?: Maybe<ScalarsEnums["String"]>;
  decimals?: Maybe<ScalarsEnums["Int"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  freeze?: Maybe<ScalarsEnums["String"]>;
  icon?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  manager?: Maybe<ScalarsEnums["String"]>;
  name?: Maybe<ScalarsEnums["String"]>;
  reserve?: Maybe<ScalarsEnums["String"]>;
  total?: Maybe<ScalarsEnums["bigint"]>;
  unit?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * aggregate min on columns
 */
export interface token_min_fields {
  __typename?: "token_min_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  clawback?: Maybe<ScalarsEnums["String"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  created_at_round?: Maybe<ScalarsEnums["bigint"]>;
  creator?: Maybe<ScalarsEnums["String"]>;
  decimals?: Maybe<ScalarsEnums["Int"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  freeze?: Maybe<ScalarsEnums["String"]>;
  icon?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  manager?: Maybe<ScalarsEnums["String"]>;
  name?: Maybe<ScalarsEnums["String"]>;
  reserve?: Maybe<ScalarsEnums["String"]>;
  total?: Maybe<ScalarsEnums["bigint"]>;
  unit?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * response of any mutation on the table "token"
 */
export interface token_mutation_response {
  __typename?: "token_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: ScalarsEnums["Int"];
  /**
   * data from the rows affected by the mutation
   */
  returning: Array<token>;
}

/**
 * aggregate stddev on columns
 */
export interface token_stddev_fields {
  __typename?: "token_stddev_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  created_at_round?: Maybe<ScalarsEnums["Float"]>;
  decimals?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  total?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_pop on columns
 */
export interface token_stddev_pop_fields {
  __typename?: "token_stddev_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  created_at_round?: Maybe<ScalarsEnums["Float"]>;
  decimals?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  total?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_samp on columns
 */
export interface token_stddev_samp_fields {
  __typename?: "token_stddev_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  created_at_round?: Maybe<ScalarsEnums["Float"]>;
  decimals?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  total?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate sum on columns
 */
export interface token_sum_fields {
  __typename?: "token_sum_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at_round?: Maybe<ScalarsEnums["bigint"]>;
  decimals?: Maybe<ScalarsEnums["Int"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  total?: Maybe<ScalarsEnums["bigint"]>;
}

/**
 * aggregate var_pop on columns
 */
export interface token_var_pop_fields {
  __typename?: "token_var_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  created_at_round?: Maybe<ScalarsEnums["Float"]>;
  decimals?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  total?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate var_samp on columns
 */
export interface token_var_samp_fields {
  __typename?: "token_var_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  created_at_round?: Maybe<ScalarsEnums["Float"]>;
  decimals?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  total?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate variance on columns
 */
export interface token_variance_fields {
  __typename?: "token_variance_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  created_at_round?: Maybe<ScalarsEnums["Float"]>;
  decimals?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  total?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * columns and relationships of "trusted_list"
 */
export interface trusted_list {
  __typename?: "trusted_list";
  account: ScalarsEnums["String"];
  asa: ScalarsEnums["bigint"];
  created_at: ScalarsEnums["timestamptz"];
  env: ScalarsEnums["String"];
  id: ScalarsEnums["Int"];
  json: ScalarsEnums["String"];
  round: ScalarsEnums["bigint"];
  token: ScalarsEnums["Int"];
  tx: ScalarsEnums["String"];
  updated_at: ScalarsEnums["timestamptz"];
}

/**
 * aggregated selection of "trusted_list"
 */
export interface trusted_list_aggregate {
  __typename?: "trusted_list_aggregate";
  aggregate?: Maybe<trusted_list_aggregate_fields>;
  nodes: Array<trusted_list>;
}

/**
 * aggregate fields of "trusted_list"
 */
export interface trusted_list_aggregate_fields {
  __typename?: "trusted_list_aggregate_fields";
  avg?: Maybe<trusted_list_avg_fields>;
  count: (args?: {
    columns?: Maybe<Array<trusted_list_select_column>>;
    distinct?: Maybe<Scalars["Boolean"]>;
  }) => ScalarsEnums["Int"];
  max?: Maybe<trusted_list_max_fields>;
  min?: Maybe<trusted_list_min_fields>;
  stddev?: Maybe<trusted_list_stddev_fields>;
  stddev_pop?: Maybe<trusted_list_stddev_pop_fields>;
  stddev_samp?: Maybe<trusted_list_stddev_samp_fields>;
  sum?: Maybe<trusted_list_sum_fields>;
  var_pop?: Maybe<trusted_list_var_pop_fields>;
  var_samp?: Maybe<trusted_list_var_samp_fields>;
  variance?: Maybe<trusted_list_variance_fields>;
}

/**
 * aggregate avg on columns
 */
export interface trusted_list_avg_fields {
  __typename?: "trusted_list_avg_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate max on columns
 */
export interface trusted_list_max_fields {
  __typename?: "trusted_list_max_fields";
  account?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * aggregate min on columns
 */
export interface trusted_list_min_fields {
  __typename?: "trusted_list_min_fields";
  account?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * response of any mutation on the table "trusted_list"
 */
export interface trusted_list_mutation_response {
  __typename?: "trusted_list_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: ScalarsEnums["Int"];
  /**
   * data from the rows affected by the mutation
   */
  returning: Array<trusted_list>;
}

/**
 * aggregate stddev on columns
 */
export interface trusted_list_stddev_fields {
  __typename?: "trusted_list_stddev_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_pop on columns
 */
export interface trusted_list_stddev_pop_fields {
  __typename?: "trusted_list_stddev_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_samp on columns
 */
export interface trusted_list_stddev_samp_fields {
  __typename?: "trusted_list_stddev_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate sum on columns
 */
export interface trusted_list_sum_fields {
  __typename?: "trusted_list_sum_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * aggregate var_pop on columns
 */
export interface trusted_list_var_pop_fields {
  __typename?: "trusted_list_var_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate var_samp on columns
 */
export interface trusted_list_var_samp_fields {
  __typename?: "trusted_list_var_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate variance on columns
 */
export interface trusted_list_variance_fields {
  __typename?: "trusted_list_variance_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * columns and relationships of "vote"
 */
export interface vote {
  __typename?: "vote";
  address: ScalarsEnums["String"];
  asa: ScalarsEnums["bigint"];
  created_at: ScalarsEnums["timestamptz"];
  env: ScalarsEnums["String"];
  id: ScalarsEnums["Int"];
  json: ScalarsEnums["String"];
  questiontx: ScalarsEnums["String"];
  round: ScalarsEnums["bigint"];
  token: ScalarsEnums["Int"];
  tx: ScalarsEnums["String"];
  updated_at: ScalarsEnums["timestamptz"];
}

/**
 * aggregated selection of "vote"
 */
export interface vote_aggregate {
  __typename?: "vote_aggregate";
  aggregate?: Maybe<vote_aggregate_fields>;
  nodes: Array<vote>;
}

/**
 * aggregate fields of "vote"
 */
export interface vote_aggregate_fields {
  __typename?: "vote_aggregate_fields";
  avg?: Maybe<vote_avg_fields>;
  count: (args?: {
    columns?: Maybe<Array<vote_select_column>>;
    distinct?: Maybe<Scalars["Boolean"]>;
  }) => ScalarsEnums["Int"];
  max?: Maybe<vote_max_fields>;
  min?: Maybe<vote_min_fields>;
  stddev?: Maybe<vote_stddev_fields>;
  stddev_pop?: Maybe<vote_stddev_pop_fields>;
  stddev_samp?: Maybe<vote_stddev_samp_fields>;
  sum?: Maybe<vote_sum_fields>;
  var_pop?: Maybe<vote_var_pop_fields>;
  var_samp?: Maybe<vote_var_samp_fields>;
  variance?: Maybe<vote_variance_fields>;
}

/**
 * aggregate avg on columns
 */
export interface vote_avg_fields {
  __typename?: "vote_avg_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate max on columns
 */
export interface vote_max_fields {
  __typename?: "vote_max_fields";
  address?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  questiontx?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * aggregate min on columns
 */
export interface vote_min_fields {
  __typename?: "vote_min_fields";
  address?: Maybe<ScalarsEnums["String"]>;
  asa?: Maybe<ScalarsEnums["bigint"]>;
  created_at?: Maybe<ScalarsEnums["timestamptz"]>;
  env?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  json?: Maybe<ScalarsEnums["String"]>;
  questiontx?: Maybe<ScalarsEnums["String"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
  tx?: Maybe<ScalarsEnums["String"]>;
  updated_at?: Maybe<ScalarsEnums["timestamptz"]>;
}

/**
 * response of any mutation on the table "vote"
 */
export interface vote_mutation_response {
  __typename?: "vote_mutation_response";
  /**
   * number of rows affected by the mutation
   */
  affected_rows: ScalarsEnums["Int"];
  /**
   * data from the rows affected by the mutation
   */
  returning: Array<vote>;
}

/**
 * aggregate stddev on columns
 */
export interface vote_stddev_fields {
  __typename?: "vote_stddev_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_pop on columns
 */
export interface vote_stddev_pop_fields {
  __typename?: "vote_stddev_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate stddev_samp on columns
 */
export interface vote_stddev_samp_fields {
  __typename?: "vote_stddev_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate sum on columns
 */
export interface vote_sum_fields {
  __typename?: "vote_sum_fields";
  asa?: Maybe<ScalarsEnums["bigint"]>;
  id?: Maybe<ScalarsEnums["Int"]>;
  round?: Maybe<ScalarsEnums["bigint"]>;
  token?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * aggregate var_pop on columns
 */
export interface vote_var_pop_fields {
  __typename?: "vote_var_pop_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate var_samp on columns
 */
export interface vote_var_samp_fields {
  __typename?: "vote_var_samp_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * aggregate variance on columns
 */
export interface vote_variance_fields {
  __typename?: "vote_variance_fields";
  asa?: Maybe<ScalarsEnums["Float"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  round?: Maybe<ScalarsEnums["Float"]>;
  token?: Maybe<ScalarsEnums["Float"]>;
}

export interface SchemaObjectTypes {
  Mutation: Mutation;
  Query: Query;
  Subscription: Subscription;
  delegation: delegation;
  delegation_aggregate: delegation_aggregate;
  delegation_aggregate_fields: delegation_aggregate_fields;
  delegation_avg_fields: delegation_avg_fields;
  delegation_max_fields: delegation_max_fields;
  delegation_min_fields: delegation_min_fields;
  delegation_mutation_response: delegation_mutation_response;
  delegation_stddev_fields: delegation_stddev_fields;
  delegation_stddev_pop_fields: delegation_stddev_pop_fields;
  delegation_stddev_samp_fields: delegation_stddev_samp_fields;
  delegation_sum_fields: delegation_sum_fields;
  delegation_var_pop_fields: delegation_var_pop_fields;
  delegation_var_samp_fields: delegation_var_samp_fields;
  delegation_variance_fields: delegation_variance_fields;
  etg: etg;
  etg_aggregate: etg_aggregate;
  etg_aggregate_fields: etg_aggregate_fields;
  etg_avg_fields: etg_avg_fields;
  etg_max_fields: etg_max_fields;
  etg_min_fields: etg_min_fields;
  etg_mutation_response: etg_mutation_response;
  etg_stddev_fields: etg_stddev_fields;
  etg_stddev_pop_fields: etg_stddev_pop_fields;
  etg_stddev_samp_fields: etg_stddev_samp_fields;
  etg_sum_fields: etg_sum_fields;
  etg_var_pop_fields: etg_var_pop_fields;
  etg_var_samp_fields: etg_var_samp_fields;
  etg_variance_fields: etg_variance_fields;
  indexer: indexer;
  indexer_aggregate: indexer_aggregate;
  indexer_aggregate_fields: indexer_aggregate_fields;
  indexer_avg_fields: indexer_avg_fields;
  indexer_max_fields: indexer_max_fields;
  indexer_min_fields: indexer_min_fields;
  indexer_mutation_response: indexer_mutation_response;
  indexer_stddev_fields: indexer_stddev_fields;
  indexer_stddev_pop_fields: indexer_stddev_pop_fields;
  indexer_stddev_samp_fields: indexer_stddev_samp_fields;
  indexer_sum_fields: indexer_sum_fields;
  indexer_var_pop_fields: indexer_var_pop_fields;
  indexer_var_samp_fields: indexer_var_samp_fields;
  indexer_variance_fields: indexer_variance_fields;
  question: question;
  question_aggregate: question_aggregate;
  question_aggregate_fields: question_aggregate_fields;
  question_avg_fields: question_avg_fields;
  question_max_fields: question_max_fields;
  question_min_fields: question_min_fields;
  question_mutation_response: question_mutation_response;
  question_stddev_fields: question_stddev_fields;
  question_stddev_pop_fields: question_stddev_pop_fields;
  question_stddev_samp_fields: question_stddev_samp_fields;
  question_sum_fields: question_sum_fields;
  question_var_pop_fields: question_var_pop_fields;
  question_var_samp_fields: question_var_samp_fields;
  question_variance_fields: question_variance_fields;
  result: result;
  result_aggregate: result_aggregate;
  result_aggregate_fields: result_aggregate_fields;
  result_avg_fields: result_avg_fields;
  result_max_fields: result_max_fields;
  result_min_fields: result_min_fields;
  result_mutation_response: result_mutation_response;
  result_stddev_fields: result_stddev_fields;
  result_stddev_pop_fields: result_stddev_pop_fields;
  result_stddev_samp_fields: result_stddev_samp_fields;
  result_sum_fields: result_sum_fields;
  result_var_pop_fields: result_var_pop_fields;
  result_var_samp_fields: result_var_samp_fields;
  result_variance_fields: result_variance_fields;
  token: token;
  token_aggregate: token_aggregate;
  token_aggregate_fields: token_aggregate_fields;
  token_avg_fields: token_avg_fields;
  token_max_fields: token_max_fields;
  token_min_fields: token_min_fields;
  token_mutation_response: token_mutation_response;
  token_stddev_fields: token_stddev_fields;
  token_stddev_pop_fields: token_stddev_pop_fields;
  token_stddev_samp_fields: token_stddev_samp_fields;
  token_sum_fields: token_sum_fields;
  token_var_pop_fields: token_var_pop_fields;
  token_var_samp_fields: token_var_samp_fields;
  token_variance_fields: token_variance_fields;
  trusted_list: trusted_list;
  trusted_list_aggregate: trusted_list_aggregate;
  trusted_list_aggregate_fields: trusted_list_aggregate_fields;
  trusted_list_avg_fields: trusted_list_avg_fields;
  trusted_list_max_fields: trusted_list_max_fields;
  trusted_list_min_fields: trusted_list_min_fields;
  trusted_list_mutation_response: trusted_list_mutation_response;
  trusted_list_stddev_fields: trusted_list_stddev_fields;
  trusted_list_stddev_pop_fields: trusted_list_stddev_pop_fields;
  trusted_list_stddev_samp_fields: trusted_list_stddev_samp_fields;
  trusted_list_sum_fields: trusted_list_sum_fields;
  trusted_list_var_pop_fields: trusted_list_var_pop_fields;
  trusted_list_var_samp_fields: trusted_list_var_samp_fields;
  trusted_list_variance_fields: trusted_list_variance_fields;
  vote: vote;
  vote_aggregate: vote_aggregate;
  vote_aggregate_fields: vote_aggregate_fields;
  vote_avg_fields: vote_avg_fields;
  vote_max_fields: vote_max_fields;
  vote_min_fields: vote_min_fields;
  vote_mutation_response: vote_mutation_response;
  vote_stddev_fields: vote_stddev_fields;
  vote_stddev_pop_fields: vote_stddev_pop_fields;
  vote_stddev_samp_fields: vote_stddev_samp_fields;
  vote_sum_fields: vote_sum_fields;
  vote_var_pop_fields: vote_var_pop_fields;
  vote_var_samp_fields: vote_var_samp_fields;
  vote_variance_fields: vote_variance_fields;
}
export type SchemaObjectTypesNames =
  | "Mutation"
  | "Query"
  | "Subscription"
  | "delegation"
  | "delegation_aggregate"
  | "delegation_aggregate_fields"
  | "delegation_avg_fields"
  | "delegation_max_fields"
  | "delegation_min_fields"
  | "delegation_mutation_response"
  | "delegation_stddev_fields"
  | "delegation_stddev_pop_fields"
  | "delegation_stddev_samp_fields"
  | "delegation_sum_fields"
  | "delegation_var_pop_fields"
  | "delegation_var_samp_fields"
  | "delegation_variance_fields"
  | "etg"
  | "etg_aggregate"
  | "etg_aggregate_fields"
  | "etg_avg_fields"
  | "etg_max_fields"
  | "etg_min_fields"
  | "etg_mutation_response"
  | "etg_stddev_fields"
  | "etg_stddev_pop_fields"
  | "etg_stddev_samp_fields"
  | "etg_sum_fields"
  | "etg_var_pop_fields"
  | "etg_var_samp_fields"
  | "etg_variance_fields"
  | "indexer"
  | "indexer_aggregate"
  | "indexer_aggregate_fields"
  | "indexer_avg_fields"
  | "indexer_max_fields"
  | "indexer_min_fields"
  | "indexer_mutation_response"
  | "indexer_stddev_fields"
  | "indexer_stddev_pop_fields"
  | "indexer_stddev_samp_fields"
  | "indexer_sum_fields"
  | "indexer_var_pop_fields"
  | "indexer_var_samp_fields"
  | "indexer_variance_fields"
  | "question"
  | "question_aggregate"
  | "question_aggregate_fields"
  | "question_avg_fields"
  | "question_max_fields"
  | "question_min_fields"
  | "question_mutation_response"
  | "question_stddev_fields"
  | "question_stddev_pop_fields"
  | "question_stddev_samp_fields"
  | "question_sum_fields"
  | "question_var_pop_fields"
  | "question_var_samp_fields"
  | "question_variance_fields"
  | "result"
  | "result_aggregate"
  | "result_aggregate_fields"
  | "result_avg_fields"
  | "result_max_fields"
  | "result_min_fields"
  | "result_mutation_response"
  | "result_stddev_fields"
  | "result_stddev_pop_fields"
  | "result_stddev_samp_fields"
  | "result_sum_fields"
  | "result_var_pop_fields"
  | "result_var_samp_fields"
  | "result_variance_fields"
  | "token"
  | "token_aggregate"
  | "token_aggregate_fields"
  | "token_avg_fields"
  | "token_max_fields"
  | "token_min_fields"
  | "token_mutation_response"
  | "token_stddev_fields"
  | "token_stddev_pop_fields"
  | "token_stddev_samp_fields"
  | "token_sum_fields"
  | "token_var_pop_fields"
  | "token_var_samp_fields"
  | "token_variance_fields"
  | "trusted_list"
  | "trusted_list_aggregate"
  | "trusted_list_aggregate_fields"
  | "trusted_list_avg_fields"
  | "trusted_list_max_fields"
  | "trusted_list_min_fields"
  | "trusted_list_mutation_response"
  | "trusted_list_stddev_fields"
  | "trusted_list_stddev_pop_fields"
  | "trusted_list_stddev_samp_fields"
  | "trusted_list_sum_fields"
  | "trusted_list_var_pop_fields"
  | "trusted_list_var_samp_fields"
  | "trusted_list_variance_fields"
  | "vote"
  | "vote_aggregate"
  | "vote_aggregate_fields"
  | "vote_avg_fields"
  | "vote_max_fields"
  | "vote_min_fields"
  | "vote_mutation_response"
  | "vote_stddev_fields"
  | "vote_stddev_pop_fields"
  | "vote_stddev_samp_fields"
  | "vote_sum_fields"
  | "vote_var_pop_fields"
  | "vote_var_samp_fields"
  | "vote_variance_fields";

export interface GeneratedSchema {
  query: Query;
  mutation: Mutation;
  subscription: Subscription;
}

export type MakeNullable<T> = {
  [K in keyof T]: T[K] | undefined;
};

export interface ScalarsEnums extends MakeNullable<Scalars> {
  delegation_constraint: delegation_constraint | undefined;
  delegation_select_column: delegation_select_column | undefined;
  delegation_update_column: delegation_update_column | undefined;
  etg_constraint: etg_constraint | undefined;
  etg_select_column: etg_select_column | undefined;
  etg_update_column: etg_update_column | undefined;
  indexer_constraint: indexer_constraint | undefined;
  indexer_select_column: indexer_select_column | undefined;
  indexer_update_column: indexer_update_column | undefined;
  order_by: order_by | undefined;
  question_constraint: question_constraint | undefined;
  question_select_column: question_select_column | undefined;
  question_update_column: question_update_column | undefined;
  result_constraint: result_constraint | undefined;
  result_select_column: result_select_column | undefined;
  result_update_column: result_update_column | undefined;
  token_constraint: token_constraint | undefined;
  token_select_column: token_select_column | undefined;
  token_update_column: token_update_column | undefined;
  trusted_list_constraint: trusted_list_constraint | undefined;
  trusted_list_select_column: trusted_list_select_column | undefined;
  trusted_list_update_column: trusted_list_update_column | undefined;
  vote_constraint: vote_constraint | undefined;
  vote_select_column: vote_select_column | undefined;
  vote_update_column: vote_update_column | undefined;
}
