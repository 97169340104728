import { Token } from '../../generated/graphql';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import minifyAddr from '../minifyAddr';
import goToExternal from '../goToExternal';
import { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import TokenBaseInfo from './TokenBaseInfo';
import { Card } from 'primereact/card';
import TokenQuestions from './TokenQuestions';
import TokenEvents from './TokenEvents';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import TokenDelegations from './TokenDelegations';
interface TokenProps {
  token: Token;
}

export default function TokenComponent(props: TokenProps) {
  const appData = useContext(AppContext);
  let navigate = useNavigate();

  return (
    <div className="border-round-md border-secondary border-1 my-2 p-1 w-17rem clickable" onClick={() => navigate(`/asset/${props.token.asa}`)}>
      <div className=" flex flex-column justify-content-center">
        <img src={`https://api.vote-coin.com/Space/${appData.env.replace('-v1.0', '')}/${props.token.asa}/Icon.svg`} height="100px" className="mt-1" />
        <h3 className="flex-align-center text-center">{props.token.name}</h3>
        <div className="flex flex-row card-container flex-wrap justify-content-between">
          <TokenEvents token={props.token.id} className="border-round border-1 p-1 m-1 flex flex-grow-1 justify-content-center" showText={false} />
          <TokenQuestions token={props.token.id} className="border-round border-1 p-1 m-1 flex flex-grow-1 justify-content-center" showText={false} />
          <TokenDelegations token={props.token.id} className="border-round border-1 p-1 m-1 flex flex-grow-1 justify-content-center" showText={false} />
        </div>
        <Button className="m-1 text-center justify-content-center" onClick={() => navigate(`/asset/${props.token.asa}`)}>
          Discover
        </Button>
      </div>
    </div>
  );
}
