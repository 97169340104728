import { Token } from '../../generated/graphql';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import minifyAddr from '../minifyAddr';
import goToExternal from '../goToExternal';
import { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import TokenBaseInfo from './TokenBaseInfo';
import { Card } from 'primereact/card';
import { gql, useSubscription } from '@apollo/client';
interface TokenEventsProps {
  token: number;
  className: string;
  showText: boolean;
}

export default function TokenEvents(props: TokenEventsProps) {
  const appData = useContext(AppContext);
  const queryD = gql`
    subscription delegation_aggregate($token: Int!) {
      delegation_aggregate(where: { token: { _eq: $token } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  `;
  const {
    data: dataD,
    loading: loadingD,
    error: errorD,
  } = useSubscription(queryD, {
    variables: {
      token: props.token,
    },
  });
  if (loadingD) {
    return <div>...</div>;
  }
  if (errorD || !dataD) {
    return <div>Error... {errorD}</div>;
  }

  const sum = dataD.delegation_aggregate ? dataD.delegation_aggregate.aggregate.count : 0;
  return (
    <span className={props.className} title="Delegations">
      <i className="pi pi-wifi m-1"></i>{' '}
      <span className="m-1">
        {sum} {props.showText && 'Delegations'}
      </span>
    </span>
  );
}
