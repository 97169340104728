import React, { Suspense, useState, useEffect } from 'react';
import buff from './buffer';
import 'react-grid-layout/css/styles.css';
import 'primereact/styleclass/';
import { Routes, Route } from 'react-router-dom';

import { AppContext, appData as defaultAppData, IState } from './contexts/app-context';

import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons

import 'primeflex/primeflex.css';

import Home from './pages/home';
import Token from './pages/token';
import About from './pages/about';
import Ask from './pages/ask';
import NewDao from './pages/NewDao';
import CatchAll from './pages/Page404';
import Theme from './components/Theme';
import i18n from './i18n';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client';

import { Buffer } from 'buffer';
import getApolloClient from './components/getApolloClient';
import Question from './pages/question';
import { AuthContext, IAuthState, defaultAuthContext } from './contexts/AuthContext';
declare const window: any;
//import 'primereact/resources/themes/nova/theme.css'; //theme
//import 'primereact/resources/themes/rhea/theme.css'; //theme
//import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
export default function App() {
  const [appData, setAppData] = useState<IState>(defaultAppData);
  const [authContext, setAuthContext] = useState<IAuthState>(defaultAuthContext);

  useEffect(() => {
    buff();
    let language = localStorage.getItem('language');
    if (!language) language = 'en';
    if (language !== appData.language) {
      console.log('App.componentDidMount', language);
      i18n.changeLanguage(language);
      setAppData({ ...appData, language: language });
    }
  }, [appData]);

  return (
    <div className="App flex flex-column">
      <Suspense fallback="loading">
        <AppContext.Provider value={{ ...appData, setAppData: v => setAppData(v) }}>
          <AuthContext.Provider value={{ ...authContext, setAuthContext: v => setAuthContext(v) }}>
            <ApolloProvider client={getApolloClient(authContext)}>
              <Theme />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/new-dao" element={<NewDao />} />
                <Route path="/asset/:id" element={<Token />} />
                <Route path="/question/:questionId" element={<Question />} />
                <Route path="/ask/:id" element={<Ask />} />
                <Route path="about" element={<About />} />
                <Route path="*" element={<CatchAll />} />
              </Routes>
            </ApolloProvider>
          </AuthContext.Provider>
        </AppContext.Provider>
      </Suspense>
    </div>
  );
}
