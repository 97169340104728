import Header from '../components/Header';
import BoundedLayout from '../components/GridLayout';
import GlobalFilter from '../components/configurable/GlobalFilter';
import QuestionList from '../components/configurable/QuestionList';
import TokenList from '../components/configurable/TokenList';
import EditDaoForm from '../components/basic/EditDaoForm';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Panel from '../components/extended/Panel';
import Authenticate from '../components/Authenticate';

export default function NewDaoPage() {
  const { questionId } = useParams();
  const authContext = useContext(AuthContext);

  return (
    <>
      <div className="flex flex flex-row">
        <div className=" flex-column flex min-h-screen p-3" style={{ width: '200px' }}>
          <Header />
        </div>
        <div className="flex flex-column flex-grow-1">
          <GlobalFilter></GlobalFilter>
          {authContext.authAddress ? (
            <EditDaoForm></EditDaoForm>
          ) : (
            <Panel className="my-2 mr-2">
              <div className="container">
                <div className="col-12">
                  <h1>Edit onchain community</h1>
                  <p>To create or manage onchain community, please authenticate.</p>
                  <Authenticate realm="Vote Coin App"></Authenticate>
                </div>
              </div>
            </Panel>
          )}
        </div>
      </div>
    </>
  );
}
