import { Token } from '../../generated/graphql';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import minifyAddr from '../minifyAddr';
import goToExternal from '../goToExternal';
import { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import TokenBaseInfo from './TokenBaseInfo';
import { Card } from 'primereact/card';
import { gql, useSubscription } from '@apollo/client';
interface TokenEventsProps {
  token: number;
  className: string;
  showText: boolean;
}

export default function TokenEvents(props: TokenEventsProps) {
  const subscriptionD = gql`
    subscription delegation_aggregate($token: Int!) {
      delegation_aggregate(where: { token: { _eq: $token } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  `;
  const subscriptionQ = gql`
    subscription question_aggregate($token: Int!) {
      question_aggregate(where: { token: { _eq: $token } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  `;
  const subscriptionR = gql`
    subscription result_aggregate($token: Int!) {
      result_aggregate(where: { token: { _eq: $token } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  `;
  const subscriptionTL = gql`
    subscription trusted_list_aggregate($token: Int!) {
      trusted_list_aggregate(where: { token: { _eq: $token } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  `;
  const subscriptionV = gql`
    subscription vote_aggregate($token: Int!) {
      vote_aggregate(where: { token: { _eq: $token } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  `;
  const subscriptionE = gql`
    subscription etg_aggregate($token: Int!) {
      etg_aggregate(where: { token: { _eq: $token } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  `;
  const {
    data: dataD,
    loading: loadingD,
    error: errorD,
  } = useSubscription(subscriptionD, {
    variables: {
      token: props.token,
    },
  });
  const {
    data: dataQ,
    loading: loadingQ,
    error: errorQ,
  } = useSubscription(subscriptionQ, {
    variables: {
      token: props.token,
    },
  });

  const {
    data: dataR,
    loading: loadingR,
    error: errorR,
  } = useSubscription(subscriptionR, {
    variables: {
      token: props.token,
    },
  });

  const {
    data: dataTL,
    loading: loadingTL,
    error: errorTL,
  } = useSubscription(subscriptionTL, {
    variables: {
      token: props.token,
    },
  });
  const {
    data: dataV,
    loading: loadingV,
    error: errorV,
  } = useSubscription(subscriptionV, {
    variables: {
      token: props.token,
    },
  });
  const {
    data: dataE,
    loading: loadingE,
    error: errorE,
  } = useSubscription(subscriptionE, {
    variables: {
      token: props.token,
    },
  });
  if (loadingD || loadingQ || loadingR || loadingTL || loadingV || loadingE) {
    return <div>...</div>;
  }
  if (errorD || !dataD) {
    return <div>Error... {errorD}</div>;
  }

  if (errorQ || !dataQ) {
    return <div>Error... {errorQ}</div>;
  }

  if (errorR || !dataR) {
    return <div>Error... {errorR}</div>;
  }
  if (errorTL || !dataTL) {
    return <div>Error... {errorTL}</div>;
  }

  if (errorV || !dataV) {
    return <div>Error... {errorV}</div>;
  }

  if (errorE || !dataE) {
    return <div>Error... {errorE}</div>;
  }

  const sum =
    (dataD.delegation_aggregate ? dataD.delegation_aggregate.aggregate.count : 0) +
    (dataQ.question_aggregate ? dataQ.question_aggregate.aggregate.count : 0) +
    (dataR.result_aggregate ? dataR.result_aggregate.aggregate.count : 0) +
    (dataTL.trusted_list_aggregate ? dataTL.trusted_list_aggregate.aggregate.count : 0) +
    (dataV.vote_aggregate ? dataV.vote_aggregate.aggregate.count : 0) +
    (dataE.etg_aggregate ? dataE.etg_aggregate.aggregate.count : 0);
  return (
    <span className={props.className} title="All events">
      <i className="pi pi-bolt m-1"></i>{' '}
      <span className="m-1">
        {sum} {props.showText && 'Events'}
      </span>
    </span>
  );
}
