import Header from '../components/Header';
import BoundedLayout from '../components/GridLayout';
import GlobalFilter from '../components/configurable/GlobalFilter';
import QuestionList from '../components/configurable/QuestionList';
import TokenList from '../components/configurable/TokenList';
import QuestionDetail from '../components/basic/QuestionDetail';
import { useParams } from 'react-router-dom';

export default function QuestionPage() {
  const { questionId } = useParams();
  return (
    <>
      <div className="flex flex flex-row">
        <div className=" flex-column flex min-h-screen p-3" style={{ width: '200px' }}>
          <Header />
        </div>
        <div className="flex flex-column flex-grow-1">
          <GlobalFilter></GlobalFilter>
          <QuestionDetail questionId={questionId} showToken={true}></QuestionDetail>
        </div>
      </div>
    </>
  );
}
