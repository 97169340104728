import { createContext } from 'react';
import { SignedTransaction } from 'algosdk';

export interface IState {
  editingLayout: boolean;
  editingComponents: boolean;
  editingExpertMode: boolean;
  isDark: boolean;
  resetLayout: boolean;
  language: string;
  layoutAddNew?: string;
  globalFilter: string;
  env: string;
  round?: number;
  setAppData?: (data: IState) => void;
}

export const appData: IState = {
  editingLayout: false,
  editingComponents: false,
  editingExpertMode: false,
  isDark: false,
  resetLayout: false,
  language: 'en',
  layoutAddNew: '',
  globalFilter: '',
  env: 'mainnet-v1.0',
  round: null,
};

export const AppContext = createContext<IState>(appData);
