import { Token } from '../../generated/graphql';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import minifyAddr from '../minifyAddr';
import goToExternal from '../goToExternal';
import { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import TokenBaseInfo from './TokenBaseInfo';
import { Card } from 'primereact/card';
import { gql, useQuery } from '@apollo/client';
interface TokenVotesProps {
  token: number;
  className: string;
  showText: boolean;
}

export default function TokenVotes(props: TokenVotesProps) {
  const appData = useContext(AppContext);
  const query = gql`
    query vote_aggregate($token: Int!) {
      vote_aggregate(where: { token: { _eq: $token } }) {
        aggregate {
          count(columns: id)
        }
      }
    }
  `;
  const { data, loading, error } = useQuery(query, {
    variables: {
      token: props.token,
    },
  });
  if (loading) {
    return <div>...</div>;
  }
  if (error || !data) {
    return <div>Error... {error}</div>;
  }
  return (
    <span className={props.className} title="Decisions / Votes">
      <i className="pi pi-thumbs-up m-1"></i>{' '}
      <span className="m-1">
        {JSON.stringify(data.vote_aggregate.aggregate.count)} {props.showText && 'Decisions'}
      </span>
    </span>
  );
}
