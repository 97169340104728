import i18n from 'i18next';
import { InputText } from 'primereact/inputtext';
import { AppContext, IState } from '../../contexts/app-context';
import BaseConfig from '../BaseConfig';

interface GlobalFilterConfig {
  quote: string;
}
interface GlobalFilterProps {
  editingLayout?: boolean;
  editingComponents?: boolean;
  config?: GlobalFilterConfig;
  children?: React.ReactNode;
  className?: string;
  onContentUpdate?: (data: any) => void;
}
export default function GlobalFilter(props: GlobalFilterProps) {
  return (
    <AppContext.Consumer>
      {appData => (
        <>
          {appData.editingComponents ? (
            <BaseConfig componentName="GlobalFilter" />
          ) : (
            <div className="field my-2 flex mr-2">
              <span className="p-float-label flex flex-column flex-grow-1">
                <InputText
                  autoComplete="off"
                  className="flex flex-column flex-grow-1"
                  id="globalFilter"
                  name="globalFilter"
                  value={appData.globalFilter}
                  onChange={event => appData.setAppData({ ...appData, globalFilter: event.target.value })}
                />{' '}
                <label htmlFor="globalFilter">Search for organizations or decisions</label>
              </span>
            </div>
          )}
        </>
      )}
    </AppContext.Consumer>
  );
}
